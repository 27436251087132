import useLang from "../../../hooks/useLang";
import useOrganisations from "./useOrganisations";
import useModifierModal from "../../forms/hooks/useModifierModal";
import { usePostOrganisationMutation } from "../organisationsSlice";
import useOrganisationBlock from "./useOrganisationBlock";

const useOrganisationsList = () => {
  const lang = useLang();
  const organisations = useOrganisations();
  const { formAttribs, formActions } = useModifierModal({
    mutationHook: usePostOrganisationMutation,
    formConfigHook: useOrganisationBlock,
  });

  const page = {
    hasAccess: organisations?.hasAccess,
    header: {
      title: lang("organisations.title"),
      buttons: {
        caption: lang("common.create"),
        icon: "plus",
        title: lang("organisations.create"),
        type: "modifier-modal",
        mutation: "organisations.POST",
        formAttribs,
        ...formActions,
      },
    },
    items: {
      id: "organisationsTable",
      type: "table",
      data: organisations?.data,
      config: [
        {
          id: "name",
          width: 72,
          required: true,
        },
        {
          id: "primary_email",
          width: 72,
          required: true,
        },
        {
          id: "given_name",
          width: 36,
          required: true,
        },
        {
          id: "family_name",
          width: 36,
          required: true,
        },
      ],
    },
  };

  return page;
};

export default useOrganisationsList;
