import { PageHeader } from "../../components/Header";
import { PageFooter } from "../../components/Footer";
import { DialogHeader } from "../../components/Header";
import LateralityButton from "./LateralityButton";
import useLaterality from "./hooks/useLaterality";

const Laterality = (props) => {
  const {
    bButtonAttribs,
    rButtonAttribs,
    mButtonAttribs,
    lButtonAttribs,
    fButtonAttribs,
  } = useLaterality(props);

  return (
    <>
      <PageHeader {...props?.header} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogHeader title={`${props?.frequency} Hz`} centered />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LateralityButton {...bButtonAttribs} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LateralityButton {...rButtonAttribs} />
          <LateralityButton {...mButtonAttribs} />
          <LateralityButton {...lButtonAttribs} />
        </div>
        <LateralityButton {...fButtonAttribs} />
      </div>
      <PageFooter {...props?.footer} />
    </>
  );
};

export default Laterality;
