import classNames from "classnames";
import styles from "./form.module.css";

const Form = ({ children, modal, ...rest }) => {
  return (
    <form
      className={classNames(styles.form, { [styles.modal]: modal })}
      {...rest}
    >
      {children}
    </form>
  );
};

export default Form;
