import useLang from "./useLang";

const useSimpleDate = () => {
  const lang = useLang();

  const getUTCDateFromString = (timeString) => {
    const [dateFromString, timeFromString] = timeString.split(" ");
    const [year, month, day] = dateFromString.split("-");
    const [hour, minute, second] = timeFromString.split(":");
    return new Date(
      Date.UTC(year, parseInt(month) - 1, day, hour, minute, second)
    );
  };

  const converToString = (number, decimalPlaces) => {
    return String(number).padStart(decimalPlaces, "0");
  };

  const getSecondDiff = (currentDate, pastDate) =>
    Math.max(
      Math.floor(currentDate.getTime() / 1000) -
        Math.floor(pastDate.getTime() / 1000),
      1
    );
  const getDayDiff = (currentDate, pastDate) =>
    Math.floor(getSecondDiff(currentDate, pastDate) / (60 * 60 * 24));

  const getSimpleDate = (timeString) => {
    if (timeString == null) return "";
    const [date, time] = timeString.split(" ");
    const timeArray = time?.split(":");
    let i;
    for (i = 0; i < 3; i++) {
      if (timeArray[i] == null) timeArray.push("00");
    }
    const pastDate = getUTCDateFromString(date + " " + timeArray.join(":"));
    const currentDate = new Date();
    const diffInDays = getDayDiff(currentDate, pastDate);

    if (diffInDays < 2) {
      if (pastDate.getDate() === currentDate.getDate()) {
        return `${lang("common.today")}, ${converToString(
          pastDate.getHours(),
          2
        )}:${converToString(pastDate.getMinutes(), 2)}`;
      } else if (currentDate.getDate() - pastDate.getDate() !== 2) {
        return `${lang("common.yesterday")}, ${converToString(
          pastDate.getHours(),
          2
        )}:${converToString(pastDate.getMinutes(), 2)}`;
      }
    }
    return `${pastDate.getFullYear()}-${converToString(
      pastDate.getMonth() + 1,
      2
    )}-${converToString(pastDate.getDate(), 2)}`;
  };

  const getTime = (timeString) => {
    const pastDate = getUTCDateFromString(timeString);
    return `${converToString(pastDate.getHours(), 2)}:${converToString(
      pastDate.getMinutes(),
      2
    )}`;
  };

  const getDate = (timeString) => {
    const pastDate = getUTCDateFromString(timeString);
    return `${pastDate.getFullYear()}-${converToString(
      pastDate.getMonth() + 1,
      2
    )}-${converToString(pastDate.getDate(), 2)}`;
  };

  return { getSimpleDate, getTime, getDate };
};

export default useSimpleDate;
