import { P2 } from "../../components/Paragraph";
import classNames from "classnames";
import styles from "./levelIndicators.module.css";

const LevelIndicators = ({ levelIndicators }) => {
  return (
    <div className={styles.levelIndicators}>
      {levelIndicators.map((level, idx) => (
        <div
          key={level.key}
          className={classNames(styles.indicatorContainer, {
            [styles.hidden]: level.top < 0,
          })}
          style={{
            top: `${level.top}px`,
          }}
        >
          {parseInt(level.sliderValue) === idx && (
            <h4
              style={{
                color:
                  level.type === "ac"
                    ? "var(--clr-blue-500)"
                    : level.type === "bc"
                    ? "var(--clr-red-500)"
                    : "initial",
              }}
            >
              {level.caption}
            </h4>
          )}
          {idx % 2 === 0 &&
            (parseInt(level.sliderValue) < idx - 1 ||
              parseInt(level.sliderValue) > idx + 1) && (
              <P2>{level.caption}</P2>
            )}
        </div>
      ))}
    </div>
  );
};

export default LevelIndicators;
