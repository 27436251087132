import { useParams } from "react-router-dom";
import useAssessment from "./useAssessment";
import useLang from "../../../hooks/useLang";

const useAssessmentPage = () => {
  const lang = useLang();
  const { assessmentId } = useParams();
  const assessment = useAssessment({ id: assessmentId });

  const notesKeys =
    assessment?.data?.notes != null ? Object.keys(assessment?.data?.notes) : [];
  const notesList = notesKeys
    ?.filter(
      (item) => item !== "selectivity_left" && item !== "selectivity_right"
    )
    ?.map((noteKey) => assessment?.data?.notes[noteKey]);

  let selectivityError;
  if (assessment?.data) {
    if (
      Array.isArray(assessment?.data?.spectrum?.right?.blue_slashes) &&
      assessment?.data?.spectrum?.right?.blue_slashes.length === 0 &&
      Array.isArray(assessment?.data?.spectrum?.left?.blue_slashes) &&
      assessment?.data?.spectrum?.left?.blue_slashes.length === 0
    )
      selectivityError = false;
  }
  if (
    assessment?.data?.notes["selectivity_left"] === "NT" ||
    assessment?.data?.notes["selectivity_right"] === "NT"
  ) {
    selectivityError = true;
  }

  const spectrumArticle = {
    items: {
      id: "spectrum",
      type: "spectrum",
      data: { ...assessment?.data?.spectrum, selectivityError },
    },
  };

  const page = {
    hasAccess: assessment?.hasAccess,
    header: {
      title: assessment?.isLoading
        ? "..."
        : `${assessment?.data?.participant?.iid} | ${lang(
            "captions.year_born"
          )}: ${assessment?.data?.participant?.year_born},  ${lang(
            "captions." + assessment?.data?.participant?.gender
          )}`,
      carriedOutBy: {
        ...assessment?.data?.user,
        date: assessment?.data?.date_created,
      },
    },
    articles:
      Array.isArray(notesList) && notesList.length > 0
        ? [
            { ...spectrumArticle },
            {
              header: {
                title: lang("captions.notes"),
              },
              items: {
                id: "notes",
                data: notesList,
                type: "list",
              },
            },
          ]
        : [{ ...spectrumArticle }],
  };

  return page;
};

export default useAssessmentPage;
