import { useRef } from "react";

const useDrawMode = ({
  getClosestGridPoint,
  chartSeriesHandlers,
  selectedSeriesIdx,
  deleteMode,
}) => {
  const successRef = useRef();
  const handleDragStart = (event) => {
    const newPoint = getClosestGridPoint(event, true);
    if (newPoint != null) {
      if (deleteMode === true) {
        successRef.current =
          chartSeriesHandlers[
            selectedSeriesIdx > 0 ? selectedSeriesIdx : 0
          ]?.removePoint(newPoint);
      } else {
        successRef.current =
          chartSeriesHandlers[
            selectedSeriesIdx > 0 ? selectedSeriesIdx : 0
          ]?.addPoint(newPoint);
      }
    }
    return successRef.current;
  };

  const handleDragFinish = () => {
    return successRef.current;
  };

  const handleDrag = (event) => {
    if (successRef.current) {
      const newPoint = getClosestGridPoint(event, true);
      if (newPoint != null) {
        chartSeriesHandlers[
          selectedSeriesIdx > 0 ? selectedSeriesIdx : 0
        ]?.addPoint(newPoint);
      }
    }
    return successRef.current;
  };

  return { handleDragStart, handleDragFinish, handleDrag };
};

export default useDrawMode;
