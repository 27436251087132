import sectionConfig from "../../navigation/sectionConfig";
import { useLocation } from "react-router-dom";

const useOrganisationId = () => {
  const location = useLocation();
  const routes = sectionConfig.flatMap((section) => [...section.routes]);
  const firstPathnameParam = location?.pathname
    ?.split("/")
    .filter((item) => item)[0];
  const idx = routes.indexOf(firstPathnameParam);
  if (idx < 0) return firstPathnameParam;
  return undefined;
};

export default useOrganisationId;
