import useSearchParams from "./useSearchParams";

const ASC = "asc";
const DESC = "desc";

const useSortParam = () => {
  const { getParam, addParams, removeParam } = useSearchParams();
  const sortParam = getParam("sort_by");
  const ascending =
    typeof sortParam === "string" ? sortParam.split(ASC).length === 2 : false;
  const descending =
    typeof sortParam === "string" ? sortParam.split(DESC).length === 2 : false;
  let sortBy;
  const sortOrder = ascending ? ASC : descending ? DESC : null;
  if (sortOrder) {
    sortBy = sortParam.split(sortOrder)[1].slice(1, -1);
  }

  const onSort = (newSortBy) => {
    let newSortOrder = DESC;
    if (newSortBy === sortBy) {
      newSortOrder = sortOrder === DESC ? ASC : null;
    }
    if (newSortOrder == null) removeParam("sort_by");
    else addParams({ sort_by: `${newSortOrder}(${newSortBy})` });
  };

  const getSortOrder = (key) => {
    if (key === sortBy && (sortOrder === ASC || sortOrder === DESC))
      return sortOrder;
    return null;
  };

  const getSortParam = () => {
    if (sortBy && sortOrder && (sortOrder === ASC || sortOrder === DESC))
      return `sort_by=${sortOrder}(${sortBy})`;
    return null;
  };

  const getSortValue = () => {
    if (sortBy && sortOrder && (sortOrder === ASC || sortOrder === DESC))
      return `${sortOrder}(${sortBy})`;
    return null;
  };

  return {
    onSort,
    getSortOrder,
    sortParam: getSortParam(),
    sortValue: getSortValue(),
  };
};

export default useSortParam;
