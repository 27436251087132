import Section from "./Section";
import { ArticleHeader } from "./Header";
import styles from "./article.module.css";

const Article = ({ header, sections, items, ...rest }) => {
  return (
    <article className={styles.article} {...rest}>
      {header ? <ArticleHeader {...header} /> : null}
      {items && <Section items={items} />}
      {sections ? (
        Array.isArray(sections) ? (
          sections?.map((section, idx) => (
            <Section key={section?.id || idx} {...section} />
          ))
        ) : (
          <Section key={sections?.id} {...sections} />
        )
      ) : null}
    </article>
  );
};

export default Article;
