import Page from "../../components/Page";
import useFeaturesList from "./hooks/useFeaturesList";

const FeaturesList = () => {
  const page = useFeaturesList();
  return (
    <>
      <Page {...page} />
    </>
  );
};

export default FeaturesList;
