const useTagBlock = () => {
  const items = [
    {
      id: "name",
      width: 72,
      required: true,
      link: (rowData) => `/tags/${rowData.id}`,
    },
    {
      id: "description",
      width: 72,
      type: "textarea",
      rows: "double",
      required: true,
    },
  ];

  return items;
};

export default useTagBlock;
