const useAcOrBc = (data, type) => {
  const getDataOfTheSameType = (data, type) => {
    const result = { ac: {}, bc: {} };
    result.ac.points = type === "ac" ? data.ac.points : [];
    result.blue_crosses = type === "ac" ? data.blue_crosses : [];
    result.blue_slashes = type === "ac" ? data.blue_slashes : [];
    result.bc.points = type === "bc" ? data.bc.points : [];
    result.red_slashes = type === "bc" ? data.red_slashes : [];
    result.red_dbl_slashes = type === "bc" ? data.red_dbl_slashes : [];
    return result;
  };
  
  return getDataOfTheSameType(data, type);
}

export default useAcOrBc