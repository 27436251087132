
import { useParams } from "react-router-dom";
import useConclusion from "./useConclusion";
import useLinkedFeatures from "./useLinkedFeatures";

const useLinkedFeaturesBlock = () => {
  const { conclusionId } = useParams();
  const conclusion = useConclusion({ id: conclusionId });
  const { options } = useLinkedFeatures();

  const items = [
    {
      id: "name",
      data: conclusion?.data?.features,
      value:
        Array.isArray(conclusion?.data?.features) &&
        conclusion?.data?.features.map((feature) => ({
          label: feature.name,
          id: feature.id,
        })),
      options,
      type: "list",
      idAsValue: true,
      link: (rowData) => `/features/${rowData.id}`,
    },
  ];

  return items;
};

export default useLinkedFeaturesBlock;
