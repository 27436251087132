import Button from "./Button";
import Popup from "./Popup";

const TableHeaderButton = ({
  onClick,
  icon,
  active,
  sort,
  filter,
  options,
  ...rest
}) => {
  if (sort)
    return (
      <Button inline deemphasized={!active} icon={icon} onClick={onClick} />
    );
  if (filter)
    return (
      <Popup
        dropdown
        expandBottomRight
        fitContent
        renderOpen={(onClick) => (
          <Button inline deemphasized={!active} icon={icon} onClick={onClick} />
        )}
      >
        {Array.isArray(options) &&
          options.map((option, idx) => (
            <Button
              key={option}
              inline
              onClick={() => {
                onClick(idx !== 0 ? option : null);
              }}
            >
              {option}
            </Button>
          ))}
      </Popup>
    );
  return <Button inline deemphasized={!active} icon={icon} onClick={onClick} />;
};

export default TableHeaderButton;
