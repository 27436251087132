import { useGetUsersQuery } from "../usersSlice";
import useHasAccess from "../../../hooks/useHasAccess";

const useUsers = () => {
  const hasAccess = useHasAccess({ apiCall: "users.GET" });
  const users = useGetUsersQuery(null, { skip: !hasAccess });

  return { ...users, hasAccess };
};

export default useUsers;
