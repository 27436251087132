import GridCard from "./GridCard";
import styles from "./gridLayout.module.css";

const GridLayout = ({ data, config }) => {
  if (!Array.isArray(data)) return null;
  if (!Array.isArray(config)) return null;

  return (
    <div className={styles.gridContainer}>
      {data.map((cardData, idx) => {
        return (
          <GridCard key={cardData?.id || idx} data={cardData} config={config} />
        );
      })}
    </div>
  );
};

export default GridLayout;
