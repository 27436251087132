import classNames from "classnames";
import styles from "./errorPoints.module.css";

const ErrorPointsPlot = ({ errorPoints }) => {
  return (
    <>
      {errorPoints?.map((point, idx) => {
        if (point.errorType === "AC") {
          return (
            <g key={`${point.relativeX}-${point.relativeY}-${idx}-AC`}>
              <line
                x1={point.x - 5}
                x2={point.x + 5}
                y1={point.y - 5}
                y2={point.y + 5}
                className={classNames(
                  styles.errorPoint,
                  styles[point.errorType]
                )}
              />
              <line
                x1={point.x - 5}
                x2={point.x + 5}
                y1={point.y + 5}
                y2={point.y - 5}
                className={classNames(
                  styles.errorPoint,
                  styles[point.errorType]
                )}
              />
            </g>
          );
        }
        if (point.errorType === "BC") {
          return (
            <g key={`${point.relativeX}-${point.relativeY}-${idx}-BC`}>
              <line
                x1={point.x - 6}
                x2={point.x + 6}
                y1={point.y + 6}
                y2={point.y - 6}
                className={classNames(
                  styles.errorPoint,
                  styles[point.errorType]
                )}
              />
            </g>
          );
        }
        if (point.errorType === "BCx2") {
          return (
            <g key={`${point.relativeX}-${point.relativeY}-${idx}-BCx2`}>
              <line
                x1={point.x - 6}
                x2={point.x + 6}
                y1={point.y + 9}
                y2={point.y - 3}
                className={classNames(
                  styles.errorPoint,
                  styles[point.errorType]
                )}
              />
              <line
                x1={point.x - 6}
                x2={point.x + 6}
                y1={point.y + 3}
                y2={point.y - 9}
                className={classNames(
                  styles.errorPoint,
                  styles[point.errorType]
                )}
              />
            </g>
          );
        }
        if (point.errorType === "Selectivity") {
          return (
            <g key={`${point.relativeX}-${point.relativeY}-${idx}-Selectivity`}>
              <line
                x1={point.x - 6}
                x2={point.x + 6}
                y1={point.y + 6}
                y2={point.y - 6}
                className={classNames(
                  styles.errorPoint,
                  styles[point.errorType]
                )}
              />
            </g>
          );
        }
        return null;
      })}
    </>
  );
};

export default ErrorPointsPlot;
