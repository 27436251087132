import Label from "./Label";
import Button from "./Button";
import DropdownSelector from "../features/forms/DropdownSelector";
import ModifierModal from "../features/forms/ModifierModal";
import ConfirmationModal from "../features/forms/ConfirmationModal";
import classNames from "classnames";
import styles from "./headerButtons.module.css";

const HeaderButton = ({
  children,
  type,
  important,
  inline,
  isLoading,
  icon,
  mutation,
  deemphasize,
  hide,
  organisation,
  data,
  unpadded,
  options,
  onChange,
  value,
  ...rest
}) => {
  if (typeof hide === "function") {
    const hideButton = hide(data);
    if (hideButton) return null;
  }
  if (type == null)
    return (
      <Button
        important={important && !deemphasize}
        inline={inline}
        unpadded={unpadded}
        icon={icon}
        mutation={mutation}
        organisation={organisation}
        {...rest}
      >
        {children}
      </Button>
    );
  if (type === "dropdown")
    return (
      <DropdownSelector
        options={options}
        onChange={onChange}
        inputWidth={"half"}
        value={value}
      />
    );
  if (type === "modifier-modal")
    return (
      <ModifierModal
        {...rest}
        data={data}
        renderOpen={(onClick) => {
          return (
            <Button
              important={important && !deemphasize}
              inline={inline}
              unpadded={unpadded}
              loader={isLoading}
              icon={icon}
              onClick={onClick}
              mutation={mutation}
              organisation={organisation}
            >
              {children}
            </Button>
          );
        }}
      />
    );
  if (type === "confirmation-modal")
    return (
      <ConfirmationModal
        {...rest}
        data={data}
        renderOpen={(onClick) => (
          <Button
            important={important && !deemphasize}
            inline={inline}
            unpadded={unpadded}
            loader={isLoading}
            icon={icon}
            onClick={onClick}
            mutation={mutation}
            organisation={organisation}
          >
            {children}
          </Button>
        )}
      />
    );
  return null;
};

const HeaderButtons = ({ buttons, renderButton, alignLeft, ...rest }) => {
  return (
    <div
      className={classNames(styles.headerButtons, {
        [styles.alignLeft]: alignLeft,
      })}
      {...rest}
    >
      {buttons
        ? Array.isArray(buttons)
          ? buttons.map((button, idx) =>
              renderButton({
                key: `${button.caption}-${idx}`,
                important: idx === buttons.length - 1 && !button.deemphasize,
                caption: button.caption,
                ...button,
              })
            )
          : renderButton({
              important: true,
              caption: buttons.caption,
              ...buttons,
            })
        : null}
    </div>
  );
};

export const CellButtons = (props) => {
  return (
    <HeaderButtons
      {...props}
      alignLeft
      renderButton={({ caption, ...rest }) => (
        <HeaderButton deemphasize inline unpadded {...rest}>
          {caption}
        </HeaderButton>
      )}
    />
  );
};

export const SectionButtons = ({ withLabel, ...props }) => {
  return (
    <>
      {withLabel && <Label />}
      <HeaderButtons
        {...props}
        renderButton={({ caption, ...rest }) => (
          <HeaderButton {...rest}>{caption}</HeaderButton>
        )}
      />
    </>
  );
};

export const PageHeaderButtons = (props) => {
  return (
    <HeaderButtons
      {...props}
      renderButton={({ caption, ...rest }) => (
        <HeaderButton {...rest}>{caption}</HeaderButton>
      )}
    />
  );
};

export const ArticleHeaderButtons = (props) => {
  return (
    <HeaderButtons
      {...props}
      renderButton={({ caption, ...rest }) => (
        <HeaderButton inline {...rest}>
          {caption}
        </HeaderButton>
      )}
    />
  );
};
