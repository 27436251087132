import FormInput from "../features/forms/FormInput";
import classNames from "classnames";
import styles from "./textFilter.module.css";

const TextFilter = ({ unpadded, ...textFilterAttributes }) => {
  return (
    <div
      className={classNames(styles.textFilter, { [styles.unpadded]: unpadded })}
    >
      <FormInput {...textFilterAttributes} />
    </div>
  );
};

export default TextFilter;
