import useGrid from "./hooks/useGrid";
import styles from "./grid.module.css";

const Grid = (props) => {
  const {
    borderLine,
    gridLine,
    gridRef,
    top,
    left,
    xAxisScaledToWidth,
    yAxisScaledToHeight,
    gridWidth,
    gridHeight,
  } = useGrid({
    ...props,
  });

  return (
    <>
      <rect
        ref={gridRef}
        x={left}
        y={top}
        width={gridWidth}
        height={gridHeight}
        className={styles.gridOutline}
        style={{ strokeWidth: borderLine }}
      />
      {xAxisScaledToWidth.map((x, idx) => (
        <line
          key={x}
          x1={x}
          x2={x}
          y1={top}
          y2={gridHeight + top}
          className={styles.xGridLine}
          style={{
            strokeWidth: idx === 4 || idx === 7 ? borderLine : gridLine,
          }}
        />
      ))}
      {yAxisScaledToHeight.map((y, idx) => {
        if (idx % 2 === 1)
          return (
            <line
              key={y}
              x1={left}
              x2={gridWidth + left}
              y1={y}
              y2={y}
              className={styles.yGridLine}
              style={{ strokeWidth: idx === 5 ? borderLine : gridLine }}
            />
          );
        return null;
      })}
    </>
  );
};

export default Grid;
