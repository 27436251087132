import { PageHeader } from "../../components/Header";
import { PageFooter } from "../../components/Footer";
import PageContent from "../../components/PageContent";
import useTestLanding from "./hooks/useTestLanding";

const TestLanding = (props) => {
  const { articles } = useTestLanding(props);
  return (
    <>
      <PageHeader {...props?.header} />
      <PageContent articles={articles} />
      <PageFooter {...props?.footer} />
    </>
  );
};

export default TestLanding;
