import { PageHeader } from "../../components/Header";
import { PageFooter } from "../../components/Footer";

const Instructions = (props) => {
  return (
    <>
      <PageHeader {...props?.header} />
      <PageFooter {...props?.footer} />
    </>
  );
};

export default Instructions;
