import { useDispatch } from "react-redux";
import { apiSlice } from "../../api/apiSlice";
import { useParams } from "react-router-dom";
import {
  usePostConclusionForFeatureMutation,
  useDeleteConclusionForFeatureMutation,
} from "../featuresSlice";
import useLinkedConclusions from "./useLinkedConclusions";

const usePatchLinkedConclusions = () => {
  const dispatch = useDispatch();
  const { featureId } = useParams();
  const { getItemsToAdd, getItemsToRemove, conclusions } =
    useLinkedConclusions();
  const [postMutation] = usePostConclusionForFeatureMutation();
  const [deleteMutation] = useDeleteConclusionForFeatureMutation();

  const mutation = async (data) => {
    const newList = data?.body?.name || [];
    const itemsToAdd = getItemsToAdd(newList);
    const itemsToRemove = getItemsToRemove(newList);
    let i;
    const patchResult = dispatch(
      apiSlice.util.updateQueryData(
        "getFeature",
        { id: featureId },
        (draft) => {
          draft.conclusions = data?.body?.name.map((conclusionId) =>
            conclusions.find((conclusion) => conclusion.id === conclusionId)
          );
        }
      )
    );
    for (i = 0; i < itemsToAdd.length; i++) {
      try {
        await postMutation({
          params: { featureId },
          body: { conclusion_id: itemsToAdd[i] },
        });
      } catch (err) {
        patchResult.undo();
        console.error(err);
      }
    }
    for (i = 0; i < itemsToRemove.length; i++) {
      try {
        await deleteMutation({
          params: { featureId, conclusionId: itemsToRemove[i] },
        });
      } catch (err) {
        patchResult.undo();
        console.error(err);
      }
    }
    dispatch(
      apiSlice.util.invalidateTags([
        { type: "Feature" },
        { type: "Feature", id: featureId },
      ])
    );
  };

  return [mutation];
};

export default usePatchLinkedConclusions;
