import { useDispatch } from "react-redux";
import { apiSlice } from "../../api/apiSlice";
import { useParams } from "react-router-dom";
import {
  usePostFeatureForConclusionMutation,
  useDeleteFeatureForConclusionMutation,
} from "../conclusionsSlice";
import useLinkedFeatures from "./useLinkedFeatures";

const usePatchLinkedFeatures = () => {
  const dispatch = useDispatch();
  const { conclusionId } = useParams();
  const { getItemsToAdd, getItemsToRemove, features } = useLinkedFeatures();
  const [postMutation] = usePostFeatureForConclusionMutation();
  const [deleteMutation] = useDeleteFeatureForConclusionMutation();

  const mutation = async (data) => {
    const newList = data?.body?.name || [];
    const itemsToAdd = getItemsToAdd(newList);
    const itemsToRemove = getItemsToRemove(newList);
    const patchResult = dispatch(
      apiSlice.util.updateQueryData(
        "getConclusion",
        { id: conclusionId },
        (draft) => {
          draft.features = data?.body?.name.map((featureId) =>
            features.find((feature) => feature.id === featureId)
          );
        }
      )
    );
    let i;
    for (i = 0; i < itemsToAdd.length; i++) {
      try {
        await postMutation({
          params: { conclusionId },
          body: { feature_id: itemsToAdd[i] },
        });
      } catch (err) {
        patchResult.undo();
        console.error(err);
      }
    }
    for (i = 0; i < itemsToRemove.length; i++) {
      try {
        await deleteMutation({
          params: { conclusionId, featureId: itemsToRemove[i] },
        });
      } catch (err) {
        patchResult.undo();
        console.error(err);
      }
    }
    dispatch(
      apiSlice.util.invalidateTags([
        { type: "Conclusion" },
        { type: "Conclusion", id: conclusionId },
      ])
    );
  };

  return [mutation];
};

export default usePatchLinkedFeatures;
