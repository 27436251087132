import { useState } from "react";
import useTestWizzardConfig from "./useTestWizzardConfig";
import useLang from "../../../hooks/useLang";
// import usePostSpectrum from "../../features/hooks/usePostSpectrum";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { usePostAssessmentMutation } from "../../assesments/assessmentsSlice";
import { useHasAccessFunctions } from "../../../hooks/useHasAccess";
import useOrganisationId from "../../organisations/hooks/useOrganisationId";
import { setError } from "../../alerts/alertsSlice";
import { useDispatch } from "react-redux";

const getInitialSpectrum = () => ({
  left: {
    ac: {
      points: [],
    },
    bc: {
      points: [],
    },
    blue_crosses: [],
    blue_slashes: [],
    red_slashes: [],
    red_dbl_slashes: [],
  },
  right: {
    ac: {
      points: [],
    },
    bc: {
      points: [],
    },
    blue_crosses: [],
    blue_slashes: [],
    red_slashes: [],
    red_dbl_slashes: [],
  },
  laterality: [],
});

const useTestPage = () => {
  const lang = useLang();
  // const { pathname } = useLocation();
  const testWizzardConfig = useTestWizzardConfig();
  const [marker, setMarker] = useState(0);
  const [spectrum, setSpectrum] = useState(getInitialSpectrum());
  // eslint-disable-next-line
  const [otherData, setOtherData] = useState({});
  // const [postSpectrum] = usePostSpectrum();
  const [postAssessment] = usePostAssessmentMutation();
  const [loading, setLoading] = useState(false);
  const organisationId = useOrganisationId();
  const navigate = useNavigate();
  const { hasAccessToLink } = useHasAccessFunctions();
  const dispatch = useDispatch();

  const flattenSteps = (steps, parentId = "", parentIdx = -1) => {
    return steps.reduce((acc, step, stepIdx) => {
      const currentId = parentId ? `${parentId}.${stepIdx}` : `${stepIdx}`;
      if (step.steps) {
        acc = acc.concat(flattenSteps(step.steps, currentId, stepIdx));
      } else {
        acc.push({
          ...step,
          flatIdx: currentId,
          topLevel:
            parentId === "" ||
            ((parentIdx === 0 || parentId.split(".").length === 1) &&
              stepIdx === 0),
        });
      }
      return acc;
    }, []);
  };

  const flattenedSteps = flattenSteps(testWizzardConfig.steps);

  const handleQuit = () => {
    setSpectrum(getInitialSpectrum());
    setMarker(0);
    setOtherData({});
  };

  const handleSkip = () => {
    if (flattenedSteps[marker]?.type === "selectivity") {
      setSpectrum((state) => {
        const newState = { ...state };
        newState.left.blue_slashes = null;
        newState.right.blue_slashes = null;
        return newState;
      });
      setMarker((state) => {
        let i;
        for (i = state + 1; i < flattenedSteps.length; i++) {
          if (
            flattenedSteps[i].topLevel &&
            flattenedSteps[i].type !== "selectivity"
          )
            return i;
        }
        return 0;
      });
    } else {
      setMarker((state) => {
        let i;
        for (i = state + 1; i < flattenedSteps.length; i++) {
          if (flattenedSteps[i].topLevel) return i;
        }
        return 0;
      });
    }
  };

  const handleNext = () => {
    setMarker((state) => (state < flattenedSteps.length - 1 ? state + 1 : 0));
  };

  const handleValueSubmit = ({ type, side, f, l, value, mistake }) => {
    let repeat = mistake && type === "ac" ? true : false;
    setSpectrum((state) => {
      const newState = { ...state };
      if (mistake) {
        if (type === "ac") {
          newState[side][mistake].push({ f, l });
        }
        if (type === "bc") {
          newState[side][mistake].push(f);
          newState[side][type].points.push({ f, l });
        }
        if (type === "selectivity") {
          newState[side][mistake].push(f);
        }
      } else {
        if (type === "laterality") {
          newState[type].push({ f, v: value });
        } else {
          newState[side][type].points.push({ f, l });
        }
      }
      return newState;
    });
    if (!repeat) handleNext();
  };

  const handleChange = (data) => {
    setOtherData((state) => ({
      ...state,
      [`${marker}_${data.id}`]: data.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await postAssessment({
        params: { id: organisationId },
        body: {
          participant_id: otherData["0_code"],
          spectrum: spectrum,
          notes: {
            general:
              otherData["0_comment"] || otherData["70_comment"]
                ? `${otherData["0_comment"] ? otherData["0_comment"] : ""}${
                    otherData["0_comment"] && otherData["70_comment"]
                      ? "\n"
                      : ""
                  }${otherData["70_comment"] ? otherData["70_comment"] : ""}`
                : undefined,
            ac_left: otherData["12_comment"],
            ac_right: otherData["24_comment"],
            bc_left: otherData["33_comment"],
            bc_right: otherData["42_comment"],
            selectivity_left: otherData["selectivity_left"],
            selectivity_right: otherData["selectivity_right"],
          },
        },
      });
      setLoading(false);
      if (response?.error) {
        dispatch(setError(response?.error?.data));
        navigate(`/${organisationId}/assessments/`);
      } else {
        navigate(
          `/${organisationId}/assessments/${
            response?.data?.assessment_id ? response?.data?.assessment_id : ""
          }`
        );
      }
    } catch (err) {
      dispatch(setError(err));
      setLoading(false);
    }
  };

  const page = {
    hasAccess: hasAccessToLink(`/${organisationId}/test`),
    header: {
      title: flattenedSteps[marker || 0]?.title
        ? flattenedSteps[marker || 0]?.title
        : lang(`test.${flattenedSteps[marker || 0]?.id}`),
      centered: true,
      left_buttons:
        flattenedSteps[marker || 0]?.type === "ac" ||
        flattenedSteps[marker || 0]?.type === "bc" ||
        flattenedSteps[marker || 0]?.type === "laterality" ||
        flattenedSteps[marker || 0]?.type === "selectivity" ||
        flattenedSteps[marker || 0]?.type === "instructions"
          ? [
              {
                caption: lang("test.skip"),
                deemphasize: true,
                onClick: handleSkip,
                inline: true,
              },
            ]
          : undefined,
      buttons:
        flattenedSteps[marker || 0]?.type !== "test_landing"
          ? [
              {
                caption: lang("test.quit"),
                deemphasize: true,
                onClick: handleQuit,
                inline: true,
              },
            ]
          : undefined,
    },
    footer:
      flattenedSteps[marker || 0]?.type !== "ac" &&
      flattenedSteps[marker || 0]?.type !== "bc" &&
      flattenedSteps[marker || 0]?.type !== "laterality" &&
      flattenedSteps[marker || 0]?.type !== "selectivity"
        ? {
            buttons: [
              {
                caption:
                  marker === flattenedSteps.length - 1
                    ? lang("test.submit")
                    : marker === 0 ||
                      flattenedSteps[marker || 0]?.type === "instructions"
                    ? lang("test.start")
                    : flattenedSteps[marker || 0]?.lastTest
                    ? lang("test.next")
                    : lang("test.next_test"),
                important: true,
                onClick:
                  marker === flattenedSteps.length - 1
                    ? handleSubmit
                    : handleNext,
                disabled:
                  marker === 0
                    ? otherData["0_code"] == null ||
                      otherData["0_code"] === "undefined"
                    : false,
              },
            ],
            centered: true,
          }
        : undefined,
    ...flattenedSteps[marker || 0],
    handleValueSubmit: (data) => {
      handleValueSubmit({ ...flattenedSteps[marker || 0], ...data });
    },
    handleNext,
    handleSubmit,
    handleChange,
    lastStep: marker === flattenedSteps.length - 1,
    data: flattenedSteps[marker || 0].side
      ? spectrum[flattenedSteps[marker || 0].side]
      : spectrum,
    loading,
  };

  return page;
};

export default useTestPage;
