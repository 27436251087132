import { useEffect } from "react";
import useLang from "../../../hooks/useLang";
import { useParams } from "react-router-dom";
import useFeature from "./useFeature";
import useFeatureBlock from "./useFeatureBlock";
import useSpectrumBlock from "./useSpectrumBlock";
import useLinkedConclusionsBlock from "./useLinkedConclusionsBlock";
import usePatchLinkedConclusions from "./usePatchLinkedConclusions";
import usePostSpectrum from "./usePostSpectrum";
import useModifierModal from "../../forms/hooks/useModifierModal";
import useConfirmationModal from "../../forms/hooks/useConfirmationModal";
import {
  usePatchFeatureMutation,
  useDeleteFeatureMutation,
} from "../featuresSlice";
import useHasAccess from "../../../hooks/useHasAccess";

const useFeaturePage = () => {
  const hasAccess = useHasAccess({ apiCall: "features.id.GET" });
  const lang = useLang();
  const { featureId } = useParams();
  const feature = useFeature({ id: featureId });
  const featureBlockItems = useFeatureBlock();
  const spectrumBlockItems = useSpectrumBlock();
  const conclusionBlockItems = useLinkedConclusionsBlock();
  const { formAttribs: editFormAttribs, formActions: editFormActions } =
    useModifierModal({
      mutationHook: usePatchFeatureMutation,
      formConfigHook: useFeatureBlock,
      params: { featureId },
    });
  const { formActions: deleteFormActions } = useConfirmationModal({
    mutationHook: useDeleteFeatureMutation,
    params: { id: featureId },
    navigateOnSuccess: () => "/features/",
  });
  const { formAttribs: conclusionsFormAttribs, formActions: editConclusions } =
    useModifierModal({
      mutationHook: usePatchLinkedConclusions,
      formConfigHook: useLinkedConclusionsBlock,
    });
  const { formAttribs: spectraFormAttribs, formActions: addSpectrum } =
    useModifierModal({
      mutationHook: usePostSpectrum,
      formConfigHook: useSpectrumBlock,
      params: { feature_id: featureId },
    });

  useEffect(() => {
    feature?.refetch();
    // eslint-disable-next-line
  }, []);

  const page = {
    hasAccess,
    header: {
      title: feature?.data?.name || lang("features.feature_loading"),
      lastEdit: feature?.data?.last_edit,
      buttons: [
        {
          caption: lang("common.delete"),
          type: "confirmation-modal",
          mutation: "features.id.DELETE",
          deemphasize: true,
          confirmationText: `${lang("common.delete")} ${
            feature?.data?.name
              ? '"' + feature?.data?.name + '"'
              : lang("feature.title")
          }?`,
          ...deleteFormActions,
        },
        {
          caption: lang("common.edit"),
          title: lang("features.edit_feature"),
          type: "modifier-modal",
          mutation: "features.id.PATCH",
          formAttribs: editFormAttribs,
          ...editFormActions,
        },
      ],
    },
    articles: [
      {
        items: featureBlockItems,
      },
      {
        header: {
          title: lang("spectra.examples"),
          buttons: {
            caption: lang("common.add"),
            icon: "plus",
            type: "modifier-modal",
            formAttribs: spectraFormAttribs,
            ...addSpectrum,
          },
        },
        items: spectrumBlockItems,
      },
      {
        header: {
          title: lang("conclusions.title"),
          buttons: {
            caption: lang("common.edit"),
            icon: "pencil",
            title: lang("features.edit_conclusions_list"),
            type: "modifier-modal",
            mutation: "features.id.conclusions.POST",
            formAttribs: conclusionsFormAttribs,
            ...editConclusions,
          },
        },
        items: conclusionBlockItems,
      },
    ],
  };

  return page;
};

export default useFeaturePage;
