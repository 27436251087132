const useOwnerBlock = () => {
  const items = [
    {
      id: "roles",
      options: ["TESTER"],
      value: (data) => data?.organisation_roles,
      type: "chip-multiselector",
      formOnly: true,
      width: 72,
    },
  ];

  return items;
};

export default useOwnerBlock;
