const getItemsToAdd = (currentList, newList) => {
  if (!Array.isArray(newList)) return [];
  if (!Array.isArray(currentList)) return newList;
  return newList.filter((newItem) => {
    if (newItem.id) {
      const idx = currentList.findIndex(
        (currentItem) => currentItem.id === newItem.id
      );
      return idx < 0;
    }
    return currentList.indexOf(newItem) < 0;
  });
};

export default getItemsToAdd;
