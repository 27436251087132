import { useGetAssessmentsQuery } from "../assessmentsSlice";
import useOrganisationId from "../../organisations/hooks/useOrganisationId";
import useHasAccess from "../../../hooks/useHasAccess";

const useAssessments = () => {
  const organisationId = useOrganisationId();
  const hasAccess = useHasAccess({
    apiCall: "organisations.id.assessments.GET",
    organisation: organisationId,
  });

  const assessments = useGetAssessmentsQuery(
    { id: organisationId },
    { skip: organisationId == null || !hasAccess }
  );

  return { ...assessments, hasAccess };
};

export default useAssessments;
