import { apiSlice } from "../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getToken: builder.mutation({
      query: (params) => ({
        url: "oauth2/token",
        method: "POST",
        params,
      }),
      invalidatesTags: [{ type: "Token" }],
    }),
  }),
});

export const { useGetTokenMutation } = authApiSlice;
