import useLang from "../../../hooks/useLang";
import useUsers from "./useUsers";
import useModifierModal from "../../forms/hooks/useModifierModal";
import { usePostUserMutation } from "../usersSlice";
import useUserBlock from "./useUserBlock";

const useUsersList = () => {
  const lang = useLang();
  const users = useUsers();
  const { formAttribs, formActions } = useModifierModal({
    mutationHook: usePostUserMutation,
    formConfigHook: useUserBlock,
  });

  const data = users?.data?.map((user) => ({
    ...user,
    full_name: `${user?.given_name} ${user?.family_name}`,
  }));

  const config = useUserBlock();

  const page = {
    hasAccess: users?.hasAccess,
    header: {
      title: users.isLoading
        ? lang("users.users_loading")
        : lang("users.title"),
      buttons: {
        caption: lang("common.create"),
        icon: "plus",
        title: lang("users.create"),
        type: "modifier-modal",
        mutation: "users.POST",
        formAttribs,
        ...formActions,
      },
    },
    items: {
      id: "usersTable",
      type: "table",
      data,
      config,
    },
  };

  return page;
};

export default useUsersList;
