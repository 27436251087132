const paddingTop = 50;
const paddingBottom = 10;
const paddingLeft = 15;
const paddingRight = 15;
export const top = paddingTop;
export const smallTop = paddingBottom;
export const bottom = paddingBottom;
export const left = paddingLeft;
export const right = paddingRight;
export const topLabelHeight = 30;
export const bottomLabelHeight = 30;
export const leftLabelWidth = 40;
export const rightLabelWidth = 40;
export const containerWidth = 500;
export const containerHeight = 400;
export const scale = 1;
export const gridHeight = containerHeight - paddingTop - paddingBottom;
export const gridWidth = containerWidth - paddingLeft - paddingRight;
export const xAxis = [6, 21, 36, 44, 52, 60, 68, 76, 82, 88, 94];
export const yAxisValues = [
  -25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65,
  70, 75, 80, 85, 90,
];
export const yAxisValuesSecondary = [
  5, 4, 3, 2, 1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
  18,
];

const numberOfYAxis = 24;
let _yAxis = [];
for (let i = 0; i < numberOfYAxis; i++) {
  const gridSpace = 100 / numberOfYAxis;
  _yAxis.push((i + 1) * gridSpace);
}
export const yAxis = [..._yAxis];
export const xAxisScaledToWidth = xAxis.map(
  (xPercentage) => (xPercentage / 100) * gridWidth + paddingLeft
);
export const yAxisScaledToHeight = yAxis.map(
  (yPercentage) => (yPercentage / 100) * gridHeight + paddingTop
);
export const xAxisValues = [
  125, 250, 500, 750, 1000, 1500, 2000, 3000, 4000, 6000, 8000,
];
export const acFrequencies = xAxisValues;
export const bcFrequencies = xAxisValues.slice(1, -2);
export const lateralityFrequencies = [
  xAxisValues[1],
  xAxisValues[2],
  xAxisValues[4],
  xAxisValues[6],
  xAxisValues[8],
];

export const getTop = ({ labelsOnTop, size }) => {
  const result =
    size === "small" ? smallTop : top + (labelsOnTop ? topLabelHeight : 0);
  return result;
};

export const getBottom = ({ labelsOnBottom, size }) => {
  const result =
    size === "small"
      ? paddingBottom
      : paddingBottom + (labelsOnBottom ? bottomLabelHeight : 0);
  return result;
};

export const getLeft = ({ labelsOnLeft, size }) => {
  const result = left + (labelsOnLeft && size !== "small" ? leftLabelWidth : 0);
  return result;
};

export const getRight = ({ labelsOnRight, size }) => {
  const result =
    right + (labelsOnRight && size !== "small" ? rightLabelWidth : 0);
  return result;
};

export const getScale = ({ size }) => {
  let result = scale;
  if (size === "small") result = 0.25;
  return result;
};

export const getGridHeight = ({ labelsOnTop, labelsOnBottom, size }) => {
  const result =
    containerHeight -
    getTop({ labelsOnTop, size }) -
    getBottom({ labelsOnBottom, size });
  return result;
};

export const getGridWidth = ({ labelsOnLeft, labelsOnRight, size }) => {
  const result =
    containerWidth -
    getLeft({ labelsOnLeft, size }) -
    getRight({ labelsOnRight, size });
  return result;
};

export const getXAxisScaledToWidth = ({
  labelsOnLeft,
  labelsOnRight,
  includeZero,
  size,
}) => {
  let axis = [...xAxis];
  if (includeZero === true) axis = [0, ...axis];
  return axis.map(
    (xPercentage) =>
      (xPercentage / 100) * getGridWidth({ labelsOnLeft, labelsOnRight }) +
      getLeft({ labelsOnLeft })
  );
};

export const getYAxisScaledToHeight = ({
  labelsOnTop,
  labelsOnBottom,
  includeZero,
  size,
}) => {
  let axis = [...yAxis];
  if (includeZero === true) axis = [0, ...yAxis];
  return axis.map(
    (yPercentage) =>
      (yPercentage / 100) *
        getGridHeight({ labelsOnTop, labelsOnBottom, size }) +
      getTop({ labelsOnTop, size })
  );
};
