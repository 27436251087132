import useNavigation from "./hooks/useNavigation";
import classNames from "classnames";
import styles from "./topBar.module.css";
import DropdownSelector from "../forms/DropdownSelector";
import UserMenu from "./UserMenu";
// import Breadcrumbs from "./Breadcrumbs";

const TopBar = ({ sideNavExpanded }) => {
  const { organisationSelector } = useNavigation();
  return (
    <div
      className={classNames(styles.topBar, {
        [styles.sideNavExpanded]: sideNavExpanded,
      })}
    >
      {/* <Breadcrumbs /> */}
      <div style={{ flexGrow: "1" }} />
      {organisationSelector?.show && (
        <DropdownSelector
          noEmpty
          topBar
          {...organisationSelector}
          inputWidth={"half"}
        />
      )}

      <UserMenu />
    </div>
  );
};

export default TopBar;
