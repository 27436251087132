import { useState, useRef, useEffect } from "react";
import { yAxisValues as _yAxisValues } from "../../spectrum/chartConfig";

const useLevelSlider = ({ frequency, type, handleValueSubmit }) => {
  const [top, setTop] = useState();
  const [level, setLevel] = useState();
  const [sliderValue, setSliderValue] = useState(0);
  const sliderRef = useRef(null);
  const yAxisValues = _yAxisValues.slice(3, -1);

  const updateBoxPosition = (index) => {
    const slider = sliderRef.current;
    const sliderHeight = slider?.offsetHeight;
    const lastIndex = yAxisValues.length - 1;
    const newPosition = (index / lastIndex) * (sliderHeight - 40);
    setLevel(yAxisValues[index]);
    setTop(newPosition);
  };

  const updateSliderValue = (value) => {
    setSliderValue(value);
    updateBoxPosition(value);
  };

  const handleChange = (event) => {
    updateSliderValue(event.target.value);
  };

  const levelIndicators = yAxisValues.map((level, idx) => {
    const slider = sliderRef.current;
    const sliderHeight = slider?.offsetHeight || 0;
    return {
      key: `${idx}-${level}`,
      caption: `${level} dB`,
      top: (idx / (yAxisValues.length - 1)) * (sliderHeight - 40),
      type,
      sliderValue,
    };
  });

  useEffect(() => {
    updateSliderValue(0);
    // eslint-disable-next-line
  }, [frequency]);

  return {
    levelIndicators,
    sliderAttribs: {
      options: yAxisValues,
      ref: sliderRef,
      value: sliderValue,
      onChange: handleChange,
      color: type === "ac" ? "blue" : "red",
    },
    top,
    level,
    unHeardButtonAttribs: {
      icon: "unheard",
      onClick: () => {
        handleValueSubmit({
          f: frequency,
          l: _yAxisValues[_yAxisValues.length - 1],
          mistake: false,
        });
      },
    },
  };
};

export default useLevelSlider;
