import { P2 } from "./Paragraph";
import useLang from "../hooks/useLang";
import useSimpleDate from "../hooks/useSimpleDate";
import UserBadge from "./UserBadge";
import styles from "./lastEdit.module.css";

const LastEdit = ({
  carriedOutBy,
  date,
  user_id,
  family_name,
  given_name,
  picture,
  id,
  ...rest
}) => {
  const lang = useLang();
  const { getSimpleDate } = useSimpleDate();
  return (
    <div className={styles.lastEdit} {...rest}>
      <P2>
        {carriedOutBy
          ? `${lang("common.carried_out_by")}:`
          : `${lang("common.last_edit")}:`}
      </P2>
      <UserBadge
        userId={user_id || id}
        family_name={family_name}
        given_name={given_name}
        picture={picture}
      />
      <P2>{getSimpleDate(date)}</P2>
    </div>
  );
};

export default LastEdit;
