import useTestPage from "./hooks/useTestPage";
import TestLanding from "./TestLanding";
import Instructions from "./Instructions";
import ACBC from "./ACBC";
import Selectivity from "./Selectivity";
import Laterality from "./Laterality";
import PartialResults from "./PartialResults";
import TestResults from "./TestResults";
import Loader from "../../components/Loader";
import useLang from "../../hooks/useLang";

import styles from "../../components/page.module.css";

const TestPage = () => {
  const lang = useLang();
  const page = useTestPage();
  if (page?.hasAccess == null) {
    return <Loader small />;
  }
  if (page?.hasAccess === false) {
    return <div>{lang("common.no_access")}</div>;
  }
  let content;
  if (page?.type === "test_landing") content = <TestLanding {...page} />;
  if (page?.type === "instructions") content = <Instructions {...page} />;
  if (page?.type === "ac" || page?.type === "bc") content = <ACBC {...page} />;
  if (page?.type === "selectivity") content = <Selectivity {...page} />;
  if (page?.type === "laterality") content = <Laterality {...page} />;
  if (page?.type === "partial_overview") content = <PartialResults {...page} />;
  if (page?.type === "overview") content = <TestResults {...page} />;

  return <div className={styles.page}>{content}</div>;
};

export default TestPage;
