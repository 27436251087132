import useLang from "../hooks/useLang";
import { PageHeader } from "./Header";
import Paging from "./Paging";
import PageContent from "./PageContent";
import Loader from "./Loader";
import styles from "./page.module.css";

const Page = ({ header, articles, items, paging, hasAccess, ...rest }) => {
  const lang = useLang();
  if (hasAccess == null) {
    return (
      <div className={styles.page} {...rest}>
        <Loader small />
      </div>
    );
  }
  if (hasAccess === false) {
    return (
      <div className={styles.page} {...rest}>
        {lang("common.no_access")}
      </div>
    );
  }
  return (
    <div className={styles.page} {...rest}>
      {header ? <PageHeader {...header} /> : null}
      {paging && <Paging {...paging} />}
      <PageContent articles={articles} items={items} />
    </div>
  );
};

export default Page;
