import { useState, useEffect } from "react";

const useRadioSelector = (props = {}) => {
  const { defaultValue, options } = props;
  const initialValue = defaultValue;
  // const initialValue = defaultValue != null ? defaultValue : options ? options[0] : null;

  const [availableOptions, setAvailableOptions] = useState(options);
  const [selected, _setSelected] = useState(initialValue);
  const setSelected = (value) => {
    if (value != null) {
      if (typeof value !== "object") {
        _setSelected({ id: String(value) });
      } else {
        _setSelected((state) => (value === state ? state : value));
      }
    }
  };

  const reset = (options, value) => {
    if (options != null) setAvailableOptions(options);
    let selectedValue;
    if (value) {
      selectedValue = options?.find((option) => option.id === value);
    }
    const valueToSet = selectedValue != null ? selectedValue : initialValue;
    setSelected(valueToSet);
  };

  useEffect(() => {
    reset(options, defaultValue);
    // eslint-disable-next-line
  }, [JSON.stringify(defaultValue)]);

  return {
    value: selected,
    setValue: setSelected,
    initialValue,
    options: availableOptions,
    onChange: (newValue) => {
      setSelected(newValue);
    },
    reset,
  };
};

export default useRadioSelector;
