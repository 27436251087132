import useSearchParams from "./useSearchParams";
import paramConfig from "../config/paramConfig";

const usePaging = ({ totalPages }) => {
  const { getParam, getLinkWithParam } =
    useSearchParams();

  let pageNumbers = [];
  let pageLinks = {};
  if (totalPages != null) {
    let i;
    for (i = 0; i < totalPages; i++) {
      pageNumbers.push(i + 1);
      pageLinks[i + 1] = getLinkWithParam(paramConfig.PAGE_NUMBER, i + 1);
    }
  }

  const activePage = 
    getParam(paramConfig.PAGE_NUMBER)
      ? getParam(paramConfig.PAGE_NUMBER)
      : paramConfig.DEFAULT_PAGE_NUMBER

  return {
    pageNumbers,
    pageLinks,
    activePage,
  };
};

export default usePaging;
