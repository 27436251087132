import Popup from "../../components/Popup";
import TextFilter from "../../components/TextFilter";
import Icon from "../../components/Icon";
import classNames from "classnames";
import Label from "../../components/Label";
import styles from "./dropdownSelector.module.css";
import useTextFilter from "../../hooks/useTextFilter";
import Button from "../../components/Button";

const DropdownSelector = ({
  options,
  value,
  defaultValue,
  onChange,
  label,
  noLabel,
  noEmpty,
  withFilter,
  inputWidth,
  topBar,
}) => {
  const { filteredData: filteredOptions, ...filterAttribs } = useTextFilter({
    data: options,
    id: "label",
  });

  const safeDefault = {};
  safeDefault.id = defaultValue?.id || null;
  safeDefault.label = defaultValue?.label || "-";
  let selectedValue = value || safeDefault;
  if (Array.isArray(options) && options.length > 0) {
    const selectedIndex = options.findIndex(
      (option) => option.id === selectedValue.id
    );
    if (selectedIndex > -1) selectedValue = options[selectedIndex];
  }
  const selectorOptions = !Array.isArray(filteredOptions)
    ? [safeDefault]
    : noEmpty
    ? filteredOptions
    : [safeDefault, ...filteredOptions];
  return (
    <div
      className={classNames(styles.selectorContainer, styles[inputWidth], {
        [styles.topBar]: topBar,
      })}
    >
      {label && !noLabel ? <Label inputLabel>{label}</Label> : null}
      <Popup
        keepOpen={withFilter}
        dropdown
        expandBottomRight
        topBar={topBar}
        renderOpen={(onClick) => (
          <div
            onClick={onClick}
            className={classNames(styles.dropdownSelector, {
              [styles.topBar]: topBar,
            })}
            style={{
              width: "0",
              boxSizing: "border-box",
            }}
          >
            <p
              style={{
                whiteSpace: "noWrap",
                overflow: "hidden",
                width: "calc(100% - 20px)",
                textOverflow: "ellipsis",
              }}
              className={classNames({
                [styles.topBar]: topBar,
              })}
            >
              {selectedValue?.label || "-"}
            </p>
            <Icon chevronDown />
          </div>
        )}
      >
        {withFilter ? (
          <div
            onClick={(event) => {
              event.preventDefault();
            }}
            className={classNames(styles.dropdownFilter, {
              [styles.topBar]: topBar,
            })}
          >
            <TextFilter {...filterAttribs} />
          </div>
        ) : null}
        <div
          className={classNames(styles.dropdownContainer, {
            [styles.topBar]: topBar,
          })}
        >
          {selectorOptions.map((option) => (
            <div key={option?.id}>
              <Button
                dropdownSelector
                pressed={option?.id === selectedValue?.id}
                onClick={() => {
                  if (typeof onChange === "function") onChange(option);
                }}
              >
                {option?.label}
              </Button>
            </div>
          ))}
        </div>
      </Popup>
    </div>
  );
};

export default DropdownSelector;
