const getOptions = (data, labelKey) => {
  if (data == null) return [];
  if (!Array.isArray(data)) return [];
  return data.map((item, idx) => ({
    label: item[labelKey],
    id: item.id || idx,
  }));
};

export default getOptions;
