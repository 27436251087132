import { apiSlice } from "../api/apiSlice";

export const organisationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisations: builder.query({
      query: () => `/organisations`,
      providesTags: [{ type: "Organisation" }],
    }),
    postOrganisation: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/organisations`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        dispatch(
          apiSlice.util.updateQueryData(
            "getOrganisations",
            undefined,
            (draft) => {
              draft.push({ ...body, id: `tmp.${Date.now()}` });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (err) {
          apiSlice.util.invalidateTags([{ type: "Organisation" }]);
        }
      },
      invalidatesTags: [{ type: "Organisation" }],
    }),
  }),
});

export const { useGetOrganisationsQuery, usePostOrganisationMutation } =
  organisationsSlice;
