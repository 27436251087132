import { useParams } from "react-router-dom";
import useHasAccess from "../../../hooks/useHasAccess";
import useGetSpectrum from "./useGetSpectrum";

const useNameBlock = () => {
  const hasAccess = useHasAccess({ apiCall: "spectra.id.GET" });
  const { spectrumId } = useParams();
  const spectrum = useGetSpectrum(
    { id: spectrumId },
    { skip: spectrumId == null || !hasAccess }
  );
  const items = [
    {
      id: "name",
      type: "text",
      value: spectrum?.data?.name,
      label: null,
    },
  ];

  return items;
};

export default useNameBlock;
