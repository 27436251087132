import { useEffect } from "react";
import useRadioSelector from "./useRadioSelector";
import useLang from "../../../hooks/useLang";

const useFormSelector = ({
  id,
  initialValue,
  options,
  onChange: handleChange,
  idAsValue,
}) => {
  const lang = useLang();

  const getOptionWithIdAndLabel = (option, idx) => {
    let result;
    if (typeof option === "object" && idAsValue ? option.id : option.label) {
      result = {
        id:
          option.id ||
          `${option.slice(0, Math.min(3, option.label.length))}_${idx}`,
        label: option.label || option.id,
      };
    } else if (typeof option === "string") {
      result = {
        id: idAsValue
          ? option
          : `${option.slice(0, Math.min(3, option.length))}_${idx}`,
        label: option,
      };
    } else {
      result = { id: idAsValue ? option : idx, label: option };
    }
    return result;
  };

  const getSelectorOptions = (options) => {
    let selectorOptions = [];
    if (Array.isArray(options)) {
      selectorOptions = options.map((option, idx) => {
        return getOptionWithIdAndLabel(option, idx);
      });
    }
    return selectorOptions;
  };

  const selector = useRadioSelector({
    options: getSelectorOptions(options),
    defaultValue: initialValue,
  });

  useEffect(() => {
    if (options && options.length > 0) {
      selector.reset(getSelectorOptions(options), selector.value);
    }
    // eslint-disable-next-line
  }, [options && options.length]);

  useEffect(() => {
    if (initialValue != null && Array.isArray(options) && options?.length > 1) {
      const selectorOptions = getSelectorOptions(options);
      const idx = selectorOptions.findIndex((option) =>
        idAsValue ? option.id === initialValue : option.label === initialValue
      );
      if (idx > -1) {
        selector.setValue(getOptionWithIdAndLabel(initialValue, idx));
      }
    }
    // eslint-disable-next-line
  }, [initialValue, options && options.length]);

  useEffect(() => {
    if (typeof handleChange === "function" && selector?.value != null) {
      handleChange({
        id: id,
        value: idAsValue === true ? selector.value?.id : selector.value?.label,
      });
    }
    // eslint-disable-next-line
  }, [selector.value?.id]);

  return {
    key: id,
    label: lang(`captions.${id}`),
    type: "text",
    value: selector.value,
    options: selector.options,
    onChange: selector.onChange,
    ...selector.attributes,
  };
};

export default useFormSelector;
