import { useEffect } from "react";
import useInput from "./useInput";
import useLang from "../../../hooks/useLang";

const useFormInputText = ({ id, initialValue, onChange }) => {
  const lang = useLang();
  const input = useInput(initialValue || "");

  useEffect(() => {
    if (initialValue != null) input.setValue(initialValue);
    // eslint-disable-next-line
  }, [initialValue]);

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange({ id: id, value: input.value });
    }
    // eslint-disable-next-line
  }, [input.value]);

  return {
    key: id,
    label: lang(`captions.${id}`),
    type: "text",
    ...input.attributes,
  };
};

export default useFormInputText;
