import { useState } from "react";

const useTextFilter = ({ data, id, label, shortLabel = true }) => {
  const [filterText, setFilterText] = useState();

  const getFilteredData = (value) => {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (value == null) return data;
    return data.filter((item) => {
      let found = false;
      let idArray = [];
      if (!Array.isArray(id)) {
        idArray = [id];
      } else {
        idArray = id;
      }
      idArray.map((id) => {
        if (item[id].toLowerCase().includes(value.toLowerCase())) found = true;
        return false;
      });
      return found;
    });
  };

  const onChange = ({ value }) => {
    setFilterText((state) => (state !== value ? value : state));
  };

  return {
    filteredData: getFilteredData(filterText),
    onChange,
    id: Array.isArray(id) ? id[0] : id,
    label,
    shortLabel,
  };
};

export default useTextFilter;
