import { useState, useEffect } from "react";
import {
  xAxis,
  yAxis,
  xAxisValues,
  yAxisValues,
  getXAxisScaledToWidth,
  getYAxisScaledToHeight,
} from "../chartConfig";

const useChartSeriesData = ({
  dataPoints,
  labelsOnRight,
  labelsOnLeft,
  labelsOnTop,
  labelsOnBottom,
  size,
}) => {
  const [chartPoints, setChartPoints] = useState();

  const xAxisScaledToWidth = getXAxisScaledToWidth({
    labelsOnRight,
    labelsOnLeft,
    size,
  });
  const yAxisScaledToHeight = getYAxisScaledToHeight({
    labelsOnTop,
    labelsOnBottom,
    size,
  });

  useEffect(() => {
    if (dataPoints == null) return;
    const chartPoints = dataPoints
      .map((dataPoint) => {
        const xIdx = xAxisValues.indexOf(dataPoint.f);
        const yIdx = yAxisValues.indexOf(dataPoint.l);
        return {
          ...dataPoint,
          x: xAxisScaledToWidth[xIdx],
          y: yAxisScaledToHeight[yIdx],
          relativeX: xAxis[xIdx],
          relativeY: yAxis[yIdx],
          outOfBounds: yIdx === yAxisValues.length - 1,
        };
      })
      .sort((a, b) => {
        if (a.x > b.x) return 1;
        if (a.x < b.x) return -1;
        return 0;
      });
    setChartPoints(chartPoints);
    // eslint-disable-next-line
  }, [JSON.stringify(dataPoints)]);

  return { chartPoints, setChartPoints };
};

export default useChartSeriesData;
