import { Link } from "react-router-dom";
import classNames from "classnames";
import { useState } from "react";
import useHasAccess from "../hooks/useHasAccess";
import Icon from "./Icon";
import Loader from "./Loader";
import styles from "./button.module.css";

const Button = ({
  children,
  inline,
  navigation,
  large,
  rounded,
  emphasized,
  warning,
  important,
  alert,
  icon,
  iconColor,
  loader,
  disabled,
  unpadded,
  sideNav,
  pressed,
  deemphasized,
  mutation,
  organisation,
  caption,
  dropdownSelector,
  ...rest
}) => {
  const hasAccess = useHasAccess({
    link: rest?.to,
    apiCall: mutation,
    organisation,
  });

  const [hover, setHover] = useState(false);
  if (!hasAccess) return null;
  let props = {};
  if (icon) props[icon] = true;
  if (iconColor) props.color = iconColor;
  if (emphasized) props.emphasized = true;
  if (deemphasized) props.deemphasized = true;
  if (warning) props.warning = true;
  if (important) props.important = true;
  if (alert) props.alert = true;
  if (inline) props.inline = true;
  if (hover) props.hover = true;

  const className = classNames(styles.button, {
    [styles.base]: !inline && !rounded,
    [styles.inline]: inline,
    [styles.unpadded]: unpadded,
    [styles.navigation]: navigation,
    [styles.rounded]: rounded,
    [styles.emphasized]: emphasized,
    [styles.warning]: warning,
    [styles.important]: important,
    [styles.alert]: alert,
    [styles.sideNav]: sideNav,
    [styles.pressed]: pressed,
    [styles.deemphasized]: deemphasized,
    [styles.iconOnly]: children == null && caption == null,
    [styles.disabled]: disabled || loader,
    [styles.large]: large,
    [styles.dropdownSelector]: dropdownSelector,
  });

  const attribs = {
    onMouseEnter: () => {
      setHover(true);
    },
    onMouseLeave: () => {
      setHover(false);
    },
  };

  const content = (
    <>
      {loader ? (
        <Loader
          xs
          emphasized={emphasized}
          important={important && !inline}
          warning={warning}
          alert={alert}
        />
      ) : icon ? (
        <Icon {...props} />
      ) : null}
      {children || caption}
    </>
  );

  if (rest?.to != null) {
    return (
      <Link
        disabled={disabled || loader}
        {...attribs}
        className={className}
        {...rest}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled || loader}
      {...attribs}
      className={className}
      {...rest}
    >
      {content}
    </button>
  );
};

export const ImportantButton = ({ children, ...rest }) => (
  <Button important={true} {...rest}>
    {children}
  </Button>
);

export const InlineButton = ({ children, ...rest }) => (
  <Button inline={true} {...rest}>
    {children}
  </Button>
);

export default Button;
