import { useGetParticipantsQuery } from "../participantsSlice";
import useOrganisationId from "../../organisations/hooks/useOrganisationId";
import useHasAccess from "../../../hooks/useHasAccess";

const useParticipants = () => {
  const organisationId = useOrganisationId();
  const hasAccess = useHasAccess({
    apiCall: "organisations.id.participants.GET",
    organisation: organisationId,
  });

  const participants = useGetParticipantsQuery(
    { id: organisationId },
    { skip: organisationId == null || !hasAccess }
  );

  return { ...participants, hasAccess };
};

export default useParticipants;
