import { yAxis } from "../chartConfig";

const useDragMode = ({
  chartSeriesHandlers,
  toggleErrorPoint,
  getRelativePosition,
  selectedSeriesIdx,
}) => {
  const handleDragStart = (event) => {
    const relativeCoords = getRelativePosition(event);
    if (relativeCoords.relativeY < yAxis[0] / 2 && selectedSeriesIdx !== 1) {
      toggleErrorPoint({ ...relativeCoords, type: "Selectivity" });
      return false;
    }
    let smallestDistance;
    let closestIdx;
    let closestSeriesIdx;
    if (selectedSeriesIdx !== -1) {
      const closestPoint =
        chartSeriesHandlers[selectedSeriesIdx].findClosestPoint(relativeCoords);
      if (closestPoint.isInRange) {
        smallestDistance = closestPoint.distance;
        closestIdx = closestPoint.idx;
        closestSeriesIdx = selectedSeriesIdx;
      }
    } else {
      chartSeriesHandlers.map((handler, seriesIdx) => {
        const closestPoint = handler.findClosestPoint(relativeCoords);
        if (smallestDistance == null) {
          smallestDistance = closestPoint.distance;
        }
        if (
          closestPoint.isInRange &&
          closestPoint.distance <= smallestDistance
        ) {
          closestSeriesIdx = seriesIdx;
          closestIdx = closestPoint.idx;
        }
        return false;
      });
    }

    if (closestSeriesIdx != null && closestSeriesIdx > -1) {
      chartSeriesHandlers.map((handler, seriesIdx) => {
        if (seriesIdx === closestSeriesIdx) {
          handler.setSelectedPoint(closestIdx);
          handler.setDragging(true);
        } else {
          handler.setSelectedPoint(undefined);
          handler.setDragging(false);
        }
        return false;
      });
    } else if (
      relativeCoords.relativeY > 100 - (100 - yAxis[yAxis.length - 2]) / 2 &&
      selectedSeriesIdx !== 0
    ) {
      toggleErrorPoint({ ...relativeCoords, type: "BC" });
      return false;
    } else if (selectedSeriesIdx !== 1) {
      toggleErrorPoint({ ...relativeCoords, type: "AC" });
      return false;
    }
  };

  const handleDragFinish = () => {
    chartSeriesHandlers.map((handler) => {
      if (handler.dragging) handler.setDragging(false);
      handler.setSelectedPoint(undefined);
      return false;
    });
  };

  const handleDrag = (event) => {
    chartSeriesHandlers.map((handler) => {
      if (handler.dragging) handler.handleDrag(getRelativePosition(event));
      return false;
    });
  };

  return { handleDragStart, handleDragFinish, handleDrag };
};

export default useDragMode;
