import { useParams } from "react-router-dom";
import useConclusion from "./useConclusion";
import useLang from "../../../hooks/useLang";

const useConclusionBlock = () => {
  const { conclusionId } = useParams();
  const conclusion = useConclusion({ id: conclusionId });
  const lang = useLang();

  const name = [
    {
      id: "name",
      value: conclusion?.data?.name,
      formOnly: true,
      required: true,
    },
  ];

  const descriptions = [
    {
      id: "level1",
      value: conclusion?.data?.level1,
      type: "textarea",
      required: true,
    },
    {
      id: "level2",
      value: conclusion?.data?.level2,
      type: "textarea",
      rows: "double",
      required: true,
    },
    {
      id: "level3",
      value: conclusion?.data?.level3,
      type: "textarea",
      rows: "double",
      required: true,
    },
  ];

  const destination = [
    {
      id: "destination",
      value: conclusion?.data?.destination,
      type: "chip-radio",
      options: ["1", "2"],
      required: true,
    },
    {
      id: "aspect",
      value: conclusion?.data?.aspect,
      displayValue: conclusion?.data?.aspect
        ? lang(`conclusions.${conclusion?.data?.aspect}`)
        : "",
      type: "chip-radio",
      options: [
        { id: "GENERIC", label: lang("conclusions.generic") },
        { id: "NEUROLOGICAL", label: lang("conclusions.NEUROLOGICAL") },
        { id: "PHYSIOLOGICAL", label: lang("conclusions.PHYSIOLOGICAL") },
        { id: "PEDAGOGICAL", label: lang("conclusions.PEDAGOGICAL") },
        { id: "PSYCHOLOGICAL", label: lang("conclusions.PSYCHOLOGICAL") },
      ],
      idAsValue: true,
      required: true,
    },
  ];

  return {
    sections: [
      { items: name },
      {
        header: { title: lang("conclusions.descriptions") },
        items: descriptions,
      },
      {
        header: { title: lang("conclusions.destination") },
        items: destination,
        direction: "row",
      },
    ],
  };
};

export default useConclusionBlock;
