import useLang from "../../../hooks/useLang";

const useOrganisationBlock = () => {
  const lang = useLang();
  const sections = [
    {
      items: [
        {
          id: "name",
          link: (rowData) => rowData.id,
          width: 72,
          required: true,
        },
      ],
    },
    {
      header: { title: lang("captions.owner") },
      items: [
        {
          id: "owner_email",
          label: lang("captions.email"),
          required: true,
          formOnly: true,
        },
        {
          id: "owner_given_name",
          label: lang("captions.given_name"),
          required: true,
          formOnly: true,
        },
        {
          id: "owner_family_name",
          label: lang("captions.family_name"),
          required: true,
          formOnly: true,
        },
      ],
    },
  ];

  return { sections };
};

export default useOrganisationBlock;
