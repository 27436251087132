const useTableRowConfig = (config) => {
  if (!Array.isArray(config)) return [];
  let tableRowConfig = [...config];

  const hasElacticColumns = (config) => {
    const indexOfElasticColumn = config.findIndex(
      (column) => column.grow === true
    );
    return indexOfElasticColumn > -1;
  };

  if (!hasElacticColumns(config)) {
    tableRowConfig[tableRowConfig.length - 1].grow = true;
  }
  return { tableRowConfig };
};

export default useTableRowConfig;
