import { useEffect } from "react";
import useLang from "../../../hooks/useLang";
import { useParams } from "react-router-dom";
import useConclusion from "./useConclusion";
import useConclusionBlock from "./useConclusionBlock";
import useLinkedFeaturesBlock from "./useLinkedFeaturesBlock";
import useConclusionRulesBlock from "./useConclusionRulesBlock";
import usePatchLinkedFeatures from "./usePatchLinkedFeatures";
import useModifierModal from "../../forms/hooks/useModifierModal";
import useConfirmationModal from "../../forms/hooks/useConfirmationModal";
import {
  usePatchConclusionMutation,
  useDeleteConclusionMutation,
} from "../conclusionsSlice";
import usePatchRules from "./usePatchRules";
import useHasAccess from "../../../hooks/useHasAccess";

const useConclusionPage = () => {
  const hasAccess = useHasAccess({ apiCall: "conclusions.id.GET" });
  const lang = useLang();
  const { conclusionId } = useParams();
  const conclusion = useConclusion({ id: conclusionId });
  const conclusionBlock = useConclusionBlock();
  const featureBlockItems = useLinkedFeaturesBlock();
  const rulesBlockItems = useConclusionRulesBlock();

  const { formAttribs: editFormAttribs, formActions: editFormActions } =
    useModifierModal({
      mutationHook: usePatchConclusionMutation,
      formConfigHook: useConclusionBlock,
      params: { conclusionId },
    });
  const { formActions: deleteFormActions } = useConfirmationModal({
    mutationHook: useDeleteConclusionMutation,
    params: { id: conclusionId },
    navigateOnSuccess: () => "/conclusions/",
  });
  const { formAttribs: featuresFormAttribs, formActions: editFeatures } =
    useModifierModal({
      mutationHook: usePatchLinkedFeatures,
      formConfigHook: useLinkedFeaturesBlock,
    });
  const { formAttribs: rulesFormAttribs, formActions: editRules } =
    useModifierModal({
      mutationHook: usePatchRules,
      formConfigHook: useConclusionRulesBlock,
      params: { conclusionId },
    });

  useEffect(() => {
    conclusion?.refetch();
    // eslint-disable-next-line
  }, []);

  const page = {
    hasAccess,
    header: {
      title: conclusion?.data?.name || lang("conclusions.conclusion_loading"),
      lastEdit: conclusion?.data?.last_edit,
      buttons: [
        {
          caption: lang("common.delete"),
          type: "confirmation-modal",
          mutation: "conclusions.id.DELETE",
          deemphasize: true,
          confirmationText: `${lang("common.delete")} ${
            conclusion?.data?.name
              ? '"' + conclusion?.data?.name + '"'
              : lang("conclusion.title")
          }?`,
          ...deleteFormActions,
        },
        {
          caption: lang("common.edit"),
          title: lang("conclusions.edit_conclusion"),
          type: "modifier-modal",
          mutation: "conclusions.id.PATCH",
          formAttribs: editFormAttribs,
          ...editFormActions,
        },
      ],
    },
    articles: [
      conclusionBlock,
      {
        header: {
          title: lang("features.title"),
          buttons: {
            caption: lang("common.edit"),
            icon: "pencil",
            title: lang("conclusions.edit_features_list"),
            type: "modifier-modal",
            mutation: "conclusions.id.features.POST",
            formAttribs: featuresFormAttribs,
            ...editFeatures,
          },
        },
        items: featureBlockItems,
      },
      {
        header: {
          title: lang("conclusions.rules"),
          buttons: {
            caption: lang("common.edit"),
            icon: "pencil",
            title: lang("conclusions.edit_rules"),
            type: "modifier-modal",
            mutation: "conclusions.id.PATCH",
            formAttribs: rulesFormAttribs,
            ...editRules,
          },
        },
        items: rulesBlockItems,
      },
    ],
  };
  return page;
};

export default useConclusionPage;
