import React, { forwardRef } from "react";
import classNames from "classnames";
import styles from "./slider.module.css";

const Slider = forwardRef(({ vertical, options, color, ...rest }, ref) => {
  const min = Array.isArray(options) ? 0 : rest.min;
  const max = Array.isArray(options) ? options.length - 1 : rest.max;
  const step = Array.isArray(options) ? 1 : rest.step;

  return (
    <>
      <input
        ref={ref}
        className={classNames(styles.slider, styles[color], {
          [styles.vertical]: vertical,
        })}
        type="range"
        min={min}
        max={max}
        step={step}
        list={Array.isArray(options) ? "values" : undefined}
        {...rest}
      />
      {Array.isArray(options) ? (
        <datalist id="values">
          {options.map((option, idx) => (
            <option key={idx} value={idx} label={option} />
          ))}
        </datalist>
      ) : null}
    </>
  );
});

export default Slider;
