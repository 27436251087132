import useLang from "../../../hooks/useLang";
import { useParams } from "react-router-dom";
import useParticipant from "./useParticipant";
import useParticipantBlock from "./useParticipantBlock";
import useModifierModal from "../../forms/hooks/useModifierModal";

const useParticipantPage = () => {
  const lang = useLang();
  const { participantId } = useParams();
  const participant = useParticipant({ id: participantId });
  const participantItems = useParticipantBlock();

  const { formAttribs, formActions } = useModifierModal({
    mutationHook: () => {
      return [];
    },
    formConfigHook: useParticipantBlock,
  });

  const page = {
    hasAccess: participant.hasAccess,
    header: {
      title: participant?.data
        ? participant?.data?.id
        : lang("participants.participant_loading"),
      buttons: [
        {
          caption: lang("common.edit"),
          title: lang("participants.edit_participant"),
          type: "modifier-modal",
          mutation: "participants.id.PATCH",
          formAttribs: formAttribs,
          ...formActions,
        },
      ],
    },
    items: participantItems,
  };

  return page;
};

export default useParticipantPage;
