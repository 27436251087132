import classNames from "classnames";
import styles from "./label.module.css";

const Label = ({
  children,
  inputLabel,
  checkboxLabel,
  shortLabel,
  ...rest
}) => {
  return (
    <label
      className={classNames(styles.label, {
        [styles.shortLabel]: shortLabel,
        [styles.inputLabel]: inputLabel,
        [styles.checkboxLabel]: checkboxLabel,
      })}
      {...rest}
    >
      {children}
    </label>
  );
};

export default Label;
