import { useState, useEffect } from "react";
import useAuth from "../features/auth/hooks/useAuth";
import useLocalStorage from "./useLocalStorage";
import { useDispatch } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentUser,
  setCredentials,
} from "../features/auth/authSlice";
import { useSelector } from "react-redux";
import { useGetTokenMutation } from "../features/auth/authApiSlice";
import * as jose from "jose";

const usePersistLogin = () => {
  const [refreshCounter, setRefreshCounter] = useState(0);
  const { refreshToken, setRefreshToken, getCallbackUrl, clientId } = useAuth();
  const [origin, setOrigin] = useLocalStorage("origin");
  const [originSearch, setOriginSearch] = useLocalStorage("search");
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);
  const [getTokenMutation] = useGetTokenMutation();

  if (origin || originSearch) {
    setOrigin(null);
    setOriginSearch(null);
  }

  const getTokens = async (refreshToken, redirectUrl, clientId) => {
    try {
      const queryParams = {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        redirect_uri: redirectUrl,
        client_id: clientId,
      };
      const data = await getTokenMutation(queryParams).unwrap();
      if (data.id_token) {
        const decoded = jose.decodeJwt(data.id_token);
        dispatch(
          setCredentials({
            token: data.id_token,
            uid: decoded.uid,
            roles: decoded.rls.split("|"),
          })
        );
      } else setRefreshToken(null);
    } catch (err) {
      setRefreshToken(null);
      console.error(err);
    }
  };

  useEffect(() => {
    if (refreshToken) {
      if (!token) {
        setRefreshCounter((state) => state + 1);
        getTokens(refreshToken, getCallbackUrl(), clientId);
      }
    }
    // eslint-disable-next-line
  }, [token, refreshToken]);

  useEffect(() => {
    if (refreshCounter > 1) {
      setRefreshCounter(0);
    }
    // eslint-disable-next-line
  }, [refreshCounter]);

  return { token, user, refreshToken };
};

export default usePersistLogin;
