import usePaging from "../../../hooks/usePaging";
import { useGetConclusionsQuery } from "../conclusionsSlice";
import { useLocation } from "react-router-dom";
import useSortParam from "../../../hooks/useSortParam";

const useConclusions = () => {
  const { sortValue } = useSortParam();
  const { activePage } = usePaging({});
  const location = useLocation();
  const conclusions = useGetConclusionsQuery({
    page: location.pathname.startsWith("/conclusions") ? activePage : 1,
    sortBy: sortValue,
  });

  return {
    ...conclusions,
    data: conclusions?.data?.response,
    pagination: conclusions?.data?.pagination,
  };
};

export default useConclusions;
