import classNames from "classnames";
import Label from "../../components/Label";

import styles from "./input.module.css";

const BareInput = ({
  children,
  invalid,
  onChange = () => {},
  inputWidth,
  shortLabel,
  ...rest
}) => {
  return (
    <input
      className={classNames(styles.input, styles[inputWidth], {
        [styles.checkbox]: rest?.type === "checkbox",
        [styles.invalid]: invalid === true,
      })}
      {...rest}
      checked={rest?.value === true}
      onChange={onChange}
    >
      {children}
    </input>
  );
};

const InputWithLabel = ({
  children,
  label,
  labelWidth,
  shortLabel,
  ...rest
}) => {
  let labelStyle = {};
  if (labelWidth != null) {
    labelStyle.width = `var(--size-${labelWidth})`;
  }
  return (
    <div
      className={classNames(styles.inputWithLabel, {
        [styles.checkboxWithLabel]: rest?.type === "checkbox",
      })}
    >
      <Label
        htmlFor={rest?.name}
        style={labelStyle}
        inputLabel
        shortLabel={shortLabel}
        checkboxLabel={rest?.type === "checkbox"}
      >
        {label}
      </Label>
      <BareInput {...rest}>{children}</BareInput>
    </div>
  );
};

const Input = ({ children, label, labelWidth, noLabel, ...rest }) => {
  if (label && !noLabel)
    return (
      <InputWithLabel label={label} labelWidth={labelWidth} {...rest}>
        {children}
      </InputWithLabel>
    );
  return <BareInput {...rest}>{children}</BareInput>;
};

export default Input;
