import Label from "../../components/Label";
import classNames from "classnames";
import styles from "./textArea.module.css";

const BareTextArea = ({ children, rows, ...rest }) => {
  return (
    <textarea className={classNames(styles.textarea, styles[rows])} {...rest}>
      {children}
    </textarea>
  );
};

const TextAreaWithLabel = ({ children, label, labelWidth, ...rest }) => {
  let labelStyle = {};
  if (labelWidth != null) {
    labelStyle.width = `var(--width-${labelWidth})`;
  }
  return (
    <div className={styles.textareaWithLabel}>
      <Label htmlFor={rest?.name} style={labelStyle}>
        {label}
      </Label>
      <BareTextArea {...rest}>{children}</BareTextArea>
    </div>
  );
};

const TextArea = ({ children, label, labelWidth, ...rest }) => {
  if (label)
    return (
      <TextAreaWithLabel label={label} labelWidth={labelWidth} {...rest}>
        {children}
      </TextAreaWithLabel>
    );
  return <BareTextArea {...rest}>{children}</BareTextArea>;
};

export default TextArea;
