import Article from "./Article";

const PageContent = ({ items, articles }) => {
  return (
    <>
      {items && <Article items={items} />}
      {articles ? (
        Array.isArray(articles) ? (
          articles?.map((article, idx) => (
            <Article key={article?.id || idx} {...article} />
          ))
        ) : (
          <Article key={articles?.id} {...articles} />
        )
      ) : null}
    </>
  );
};

export default PageContent;
