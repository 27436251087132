const paramConfig = {
  SORT_BY: "sort_by",
  PAGE_NUMBER: "page_no",
  DEFAULT_PAGE_NUMBER: 1,
  PAGE_SIZE: "page_size",
  DEFAULT_PAGE_SIZE: 100,
  TAG: "tag",
};

export default paramConfig;
