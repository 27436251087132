import { useGetAssessmentQuery } from "../assessmentsSlice";
import useOrganisationId from "../../organisations/hooks/useOrganisationId";
import useHasAccess from "../../../hooks/useHasAccess";

const useAssessment = ({ id }) => {
  const organisationId = useOrganisationId();
  const hasAccess = useHasAccess({
    apiCall: "organisations.id.assessments.id.GET",
    organisation: organisationId,
  });
  const assessment = useGetAssessmentQuery({ organisationId, assessmentId: id }, { skip: id == null || organisationId == null || !hasAccess });

  return {...assessment, hasAccess}
}

export default useAssessment;