import { useState, useEffect } from "react";

const useFormspectrumEditor = ({ onChange: handleChange }) => {
  const [spectrum, setSpectrum] = useState({});

  const onChange = (data) => {
    setSpectrum((state) => {
      return { ...state, ...data };
    });
  };

  useEffect(() => {
    if (typeof handleChange === "function") {
      handleChange({ id: "spectrum", value: spectrum });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(spectrum)]);

  return { spectrum, onChange };
};

export default useFormspectrumEditor;
