import { useState } from "react";
import classNames from "classnames";
import styles from "./userRoundel.module.css";

const UserRoundel = ({ picture, initials, large }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      <div
        className={classNames(styles.userRoundel, { [styles.large]: large })}
      >
        {picture && (
          <img
            src={picture}
            className={classNames(styles.userRoundel, {
              [styles.large]: large,
            })}
            alt={"User's avatar"}
            onLoad={() => setLoaded(true)}
            style={
              loaded
                ? { opacity: "1" }
                : { opacity: "0", width: "0px", height: "0" }
            }
          />
        )}
        {(!picture || !loaded) && (
          <div
            className={classNames(styles.userRoundel, {
              [styles.empty]: initials == null,
              [styles.initials]: initials != null,
              [styles.large]: large,
            })}
          >
            {initials}
          </div>
        )}
      </div>
    </>
  );
};

export default UserRoundel;
