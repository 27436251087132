import { useParams } from "react-router-dom";
import useHasAccess from "../../../hooks/useHasAccess";
import useGetSpectrum from "./useGetSpectrum";

const useNotesBlock = () => {
  const hasAccess = useHasAccess({ apiCall: "spectra.id.GET" });
  const { spectrumId } = useParams();
  const spectrum = useGetSpectrum(
    { id: spectrumId },
    { skip: spectrumId == null || !hasAccess }
  );
  const items = [
    {
      id: "notes",
      type: "textarea",
      value: spectrum?.data?.notes,
      label: null,
    },
  ];

  return items;
};

export default useNotesBlock;
