import CellContent from "./CellContent";
import styles from "./gridLayout.module.css";

const GridCard = ({ data, config, ...rest }) => {
  return (
    <div className={styles.gridCard} {...rest}>
      {config?.map((rowConfig, idx) => (
        <div
          key={rowConfig?.id || idx}
          style={{
            width: `var(--size-${rowConfig.width})`,
            minWidth: `var(--size-${rowConfig.width})`,
            flexGrow: config?.grow === true ? 1 : 0,
          }}
        >
          <CellContent data={data} config={rowConfig} />
        </div>
      ))}
    </div>
  );
};

export default GridCard;
