import { useParams } from "react-router-dom";
import useGetSpectrum from "./useGetSpectrum";
import useFeatures from "../../features/hooks/useFeatures";
import useSpectrumFeatures from "./useSpectrumFeatures";

const useSpectrumFeaturesBlock = () => {
  const { spectrumId } = useParams();
  const spectrum = useGetSpectrum({ id: spectrumId });
  const features = useFeatures();
  const { options } = useSpectrumFeatures();

  let filteredFeatures = [];
  if (features?.data != null && spectrum?.data != null) {
    filteredFeatures = features?.data?.filter(
      (feature) =>
        spectrum?.data?.feature_ids.findIndex((id) => id === feature.id) > -1
    );
  }

  const items = [
    {
      id: "name",
      data: filteredFeatures,
      value: filteredFeatures.map((feature) => ({
        label: feature.name,
        id: feature.id,
      })),
      type: "list",
      idAsValue: true,
      options,
      link: (rowData) => `/features/${rowData.id}`,
    },
  ];

  return items;
};

export default useSpectrumFeaturesBlock;
