import { useState } from "react";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import Aside from "./Aside";
import TopBar from "../features/navigation/TopBar";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectIsOpen } from "../app/modalSlice";
import styles from "./layout.module.css";
import { resetError, selectError } from "../features/alerts/alertsSlice";
import { AlertModal } from "./Modal";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "./Paragraph";
import useLang from "../hooks/useLang";

const Layout = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);
  const modalOpen = useSelector(selectIsOpen);
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const lang = useLang();

  return (
    <>
      <Aside
        expanded={sideNavExpanded}
        onToggle={() => {
          setSideNavExpanded((state) => !state);
        }}
      />
      <div
        className={classNames(styles.app, {
          [styles.sideNavExpanded]: sideNavExpanded,
          [styles.sideNavCollapsed]: !sideNavExpanded,
          [styles.modalOpen]: modalOpen,
        })}
      >
        <TopBar sideNavExpanded={sideNavExpanded} />
        <ErrorBoundary fallback="Error in content">
          <Outlet />
        </ErrorBoundary>
        <AlertModal
          isOpen={error != null}
          onClose={() => {
            dispatch(resetError());
          }}
          title={lang("captions.error")}
        >
          <ErrorMessage>{error?.message}</ErrorMessage>
        </AlertModal>
      </div>
    </>
  );
};

export default Layout;
