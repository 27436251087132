import Button from "../../components/Button";
import useLevelContext from "./hooks/useLevelContext";

const LevelContext = ({ top, ...rest }) => {
  const { buttons } = useLevelContext(rest);
  return (
    <>
      {top != null && (
        <div
          style={{
            position: "absolute",
            top,
            left: "50px",
            display: "flex",
          }}
        >
          {buttons?.map((buttonAttribs) => (
            <Button {...buttonAttribs}>{buttonAttribs?.caption}</Button>
          ))}
        </div>
      )}
    </>
  );
};

export default LevelContext;
