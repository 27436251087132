import TableRow from "./TableRow";

const TableBody = ({ config, data, ...rest }) => {
  return (
    <tbody {...rest}>
      {data.map((dataRow, idx) => {
        return (
          <TableRow key={dataRow?.id || idx} config={config} data={dataRow} />
        );
      })}
    </tbody>
  );
};

export default TableBody;
