import styles from "./paragraph.module.css";

const P = ({ children, ...rest }) => <p {...rest}>{children}</p>;

export const P2 = ({ children, ...rest }) => (
  <p className={styles.p2} {...rest}>
    {children}
  </p>
);

export const ErrorMessage = ({ children, ...rest }) => (
  <p className={styles.errorMessage} {...rest}>
    {children}
  </p>
);

export default P;
