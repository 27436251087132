import SpectrumSide from "../spectrum/SpectrumSide";
import { PageHeader } from "../../components/Header";
import { PageFooter } from "../../components/Footer";
import PageContent from "../../components/PageContent";
import usePartialResults from "./hooks/usePartialResults";

const PartialResults = (props) => {
  const { data } = usePartialResults(props);
  return (
    <>
      <PageHeader {...props?.header} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SpectrumSide data={data} viewOnly />
      </div>
      <div>
        <PageContent
          items={[
            {
              id: "comment",
              formItem: true,
              onChange: props?.handleChange,
            },
          ]}
        />
      </div>
      <PageFooter {...props?.footer} />
    </>
  );
};

export default PartialResults;
