import useLang from "../../../hooks/useLang";
import useOrganisationId from "../../organisations/hooks/useOrganisationId";
import useParticipants from "./useParticipants";
import useModifierModal from "../../forms/hooks/useModifierModal";
import useParticipantBlock from "./useParticipantBlock";
import { usePostParticipantMutation } from "../participantsSlice";
import { useHasAccessFunctions } from "../../../hooks/useHasAccess";

const useParticipantsList = () => {
  const organisationId = useOrganisationId();
  const { hasAccessToLink } = useHasAccessFunctions();
  const participants = useParticipants();
  const lang = useLang();

  const { formAttribs, formActions } = useModifierModal({
    mutationHook: usePostParticipantMutation,
    formConfigHook: useParticipantBlock,
    params: { id: organisationId },
  });

  const canPerformTests = hasAccessToLink(`/${organisationId}/test`);

  const data = participants?.data?.map((participant) => {
    return {
      ...participant,
      code: participant.id,
      gender: lang(`captions.${participant.gender}_short`),
    };
  });

  const config = [
    {
      id: "iid",
      width: 56,
    },
    {
      id: "year_born",
      width: 32,
    },
    {
      id: "gender",
      width: 24,
    },
  ];

  const page = {
    hasAccess: participants?.hasAccess,
    header: {
      title: participants?.isLoading
        ? lang("participants.participants_loading")
        : lang("participants.title"),
      buttons: {
        caption: lang("common.create"),
        icon: "plus",
        title: lang("participants.create"),
        type: "modifier-modal",
        mutation: "organisations.id.participants.POST",
        organisation: organisationId,
        formAttribs,
        ...formActions,
      },
    },
    items: {
      id: "participantsTable",
      type: "table",
      data,
      config: canPerformTests
        ? [
            ...config,
            {
              id: "start_assessment",
              caption: "",
              icon: "headphones",
              header: {
                title: lang("assessments.start_assessment"),
              },
              link: (rowData) =>
                `/${organisationId}/test?participant=${rowData?.id}`,
            },
          ]
        : config,
    },
  };

  return page;
};

export default useParticipantsList;
