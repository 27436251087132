import Button from "./Button";
import classNames from "classnames";
import styles from "./footer.module.css";

export const DialogFooter = ({ children, ...rest }) => {
  return (
    <footer className={styles.dialogFooter} {...rest}>
      {children}
    </footer>
  );
};

export const PageFooter = ({ buttons, centered, ...rest }) => {
  if (buttons == null) return null;
  return (
    <footer
      className={classNames(styles.pageFooter, { [styles.centered]: centered })}
      {...rest}
    >
      {Array.isArray(buttons)
        ? buttons.map(({ caption, ...rest }, idx) => (
            <Button key={idx} {...rest}>
              {caption}
            </Button>
          ))
        : null}
    </footer>
  );
};
