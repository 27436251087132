import { useEffect } from "react";
import useTextFilter from "../../../hooks/useTextFilter";
import useLang from "../../../hooks/useLang";
import useFeatures from "./useFeatures";
import useModifierModal from "../../forms/hooks/useModifierModal";
import useFeatureBlock from "./useFeatureBlock";
import { usePostFeatureMutation } from "../featuresSlice";
import useHasAccess from "../../../hooks/useHasAccess";

const useFeaturesList = () => {
  const hasAccess = useHasAccess({ apiCall: "features.GET" });
  const lang = useLang();
  const features = useFeatures();
  const { filteredData, ...textFilterAttribs } = useTextFilter({
    data: features?.data,
    id: ["name", "description"],
    label: lang("common.search"),
  });
  const { formAttribs, formActions } = useModifierModal({
    mutationHook: usePostFeatureMutation,
    formConfigHook: useFeatureBlock,
    navigateOnSuccess: (response) => `/features/${response?.data?.feature_id}`,
  });

  useEffect(() => {
    features?.refetch();
    // eslint-disable-next-line
  }, []);

  const page = {
    hasAccess,
    header: {
      title: features.isLoading
        ? lang("features.features_loading")
        : lang("features.title"),
      buttons: {
        caption: lang("common.create"),
        icon: "plus",
        title: lang("features.create"),
        type: "modifier-modal",
        mutation: "features.POST",
        formAttribs,
        ...formActions,
      },
    },
    paging: { ...features?.pagination },
    items: [
      {
        id: "featuresTable",
        type: "table",
        textFilter: textFilterAttribs,
        data: filteredData,
        config: [
          {
            id: "name",
            width: 72,
            link: (rowData) => `/features/${rowData.id}`,
            warning: (rowData) =>
              !(
                Array.isArray(rowData.conclusions) &&
                rowData.conclusions.length > 0
              ),
            isLoading: (rowData) => rowData.id.includes("tmp."),
          },
          {
            id: "description",
            width: 192,
            grow: true,
          },
        ],
      },
    ],
  };

  return page;
};

export default useFeaturesList;
