import CellContent from "./CellContent";
import classNames from "classnames";
import styles from "./table.module.css";

const TableCell = ({ config, data, headerCell, ...rest }) => {
  return (
    <td
      key={config.id}
      className={classNames(styles.cell)}
      style={{
        width: `var(--size-${config.width})`,
        minWidth: `var(--size-${config.width})`,
        flexGrow: config?.grow === true ? 1 : 0,
      }}
      {...rest}
    >
      <CellContent config={config} data={data}/>
    </td>
  );
};

export default TableCell;
