import useACBC from "./hooks/useACBC";
import SpectrumSide from "../spectrum/SpectrumSide";
import LevelSlider from "./LevelSlider";
import { PageHeader } from "../../components/Header";
import { PageFooter } from "../../components/Footer";

const ACBC = (props) => {
  const { data } = useACBC(props);

  return (
    <>
      <PageHeader {...props?.header} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LevelSlider {...props} />
        <SpectrumSide data={data} viewOnly={true} />
      </div>
      <PageFooter {...props?.footer} />
    </>
  );
};

export default ACBC;
