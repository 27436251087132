import {
  usePostSpectrumMutation,
  useAssignSpectrumToFeatureMutation,
} from "../../spectrum/spectraSlice";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../api/apiSlice";

const usePostSpectrum = () => {
  const dispatch = useDispatch();

  const [postMutation] = usePostSpectrumMutation();
  const [assignMutation] = useAssignSpectrumToFeatureMutation();

  const mutation = async ({ params, body }) => {
    const newBody = { ...body };
    if (
      body?.spectrum?.selectivity?.right === "NT" ||
      body?.spectrum?.selectivity?.left === "NT"
    ) {
      newBody.spectrum.right.blue_slashes = null;
      newBody.spectrum.left.blue_slashes = null;
    }
    delete newBody.spectrum.selectivity;
    const lateralityFrequencies = Object.keys(body?.spectrum?.laterality);
    newBody.spectrum.laterality = lateralityFrequencies?.map((f) => ({
      f,
      v: body?.spectrum?.laterality[f],
    }));

    let result;
    try {
      result = await postMutation({
        body: { ...newBody?.spectrum, notes: body?.notes },
      });
      dispatch(
        apiSlice.util.invalidateTags([
          { type: "Spectrum" },
          { type: "Spectrum", id: result?.data?.spectrum_id },
        ])
      );
    } catch (err) {
      console.error(err);
    }
    if (result?.data?.spectrum_id != null && params?.feature_id != null) {
      try {
        await assignMutation({
          id: result?.data?.spectrum_id,
          body: { feature_id: params?.feature_id },
        });
        dispatch(
          apiSlice.util.invalidateTags([
            { type: "Feature" },
            { type: "Feature", id: params?.feature_id },
          ])
        );
      } catch (err) {
        console.error(err);
      }
    }
    return result?.data;
  };

  return [mutation];
};

export default usePostSpectrum;
