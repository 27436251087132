import { useState, useEffect, useRef } from "react";

const useFormMatrixSelector = ({ onChange, valueData, inputs, formData }) => {
  const [inputGroups, setInputGroups] = useState();
  const formDataRef = useRef();
  formDataRef.current = { ...formData };

  const handleRemove = ({ idx }) => {
    let oldLength;
    setInputGroups((state) => {
      oldLength = state.length;
      let newState = [];
      for (let i = 0; i < idx; i++) {
        newState[i] = {};
        inputs.map((input) => {
          newState[i][input.id] = formDataRef.current[`${i}___${input.id}`];
          return false;
        });
      }
      for (let i = idx; i < oldLength - 1; i++) {
        newState[i] = {};
        inputs.map((input) => {
          newState[i][input.id] = formDataRef.current[`${i + 1}___${input.id}`];
          return false;
        });
      }
      return newState;
    });
    for (let i = idx + 1; i < oldLength; i++) {
      inputs.map((input) => {
        onChange({
          id: `${i - 1}___${input.id}`,
          value: formDataRef.current[`${i}___${input.id}`],
        });
        return false;
      });
    }
    onChange({ id: `${oldLength - 1}__`, remove: true });
  };

  const handleAdd = () => {
    let newInputGroup = {};
    inputs.map((input) => {
      let newValue = "";
      if (Array.isArray(input.options)) {
        newValue = input.idAsValue
          ? input.options[0].id
          : input.options[0].label;
      }
      newInputGroup[input.id] = newValue;
      return false;
    });

    setInputGroups((state) => {
      let newState = Array.isArray(state) ? [...state] : [];
      newState.push(newInputGroup);
      return newState;
    });
  };

  useEffect(() => {
    if (valueData.length > 0) {
      setInputGroups(valueData);
    }
    // eslint-disable-next-line
  }, [valueData?.length]);

  return { handleAdd, handleRemove, inputGroups };
};

export default useFormMatrixSelector;
