const useLevelContext = ({ frequency, level, type, handleValueSubmit }) => {
  const getButtons = () => {
    let result = [];
    result.push({
      key: "point",
      onClick: () => {
        handleValueSubmit({ f: frequency, l: level, mistake: false });
      },
      icon: "levelPoint",
      iconColor: type === "ac" ? "blue" : type === "bc" ? "red" : undefined,
    });
    if (type === "ac") {
      result.push({
        key: "blue_cross",
        onClick: () => {
          handleValueSubmit({
            f: frequency,
            l: level,
            mistake: "blue_crosses",
          });
        },
        icon: "cross",
        iconColor: "blue",
      });
    } else {
      result.push({
        key: "red_slash",
        onClick: () => {
          handleValueSubmit({ f: frequency, l: level, mistake: "red_slashes" });
        },
        icon: "slash",
        iconColor: "red",
      });
      result.push({
        key: "red_dbl_slash",
        onClick: () => {
          handleValueSubmit({
            f: frequency,
            l: level,
            mistake: "red_dbl_slashes",
          });
        },
        icon: "dblSlash",
        iconColor: "red",
      });
    }
    return result;
  };

  return {
    buttons: getButtons(),
  };
};

export default useLevelContext;
