import { ConfirmationModalControlled } from "../../components/Modal";
import P from "../../components/Paragraph";
import useModal from "../../hooks/useModal";

const ConfirmationModal = ({
  renderOpen,
  onSubmit: _onSubmit,
  onCancel: _onCancel,
  onClose: _onClose,
  confirmationText,
  ...restFromProps
}) => {
  const { onOpen, ...rest } = useModal({
    onSubmit: _onSubmit,
    onCancel: _onCancel,
    onClose: _onClose,
    ...restFromProps,
  });
  return (
    <>
      {renderOpen(() => {
        onOpen();
      })}
      <ConfirmationModalControlled {...rest}>
        <P>
          {typeof confirmationText === "function"
            ? confirmationText(restFromProps?.data)
            : confirmationText}
        </P>
      </ConfirmationModalControlled>
    </>
  );
};

export default ConfirmationModal;
