import Input from "./Input";
import Label from "../../components/Label";
import TextFilter from "../../components/TextFilter";
import classNames from "classnames";
import styles from "./listSelector.module.css";

const ListSelector = ({
  listAttributes,
  filteredOptions,
  filterAttribs,
  ...rest
}) => {
  if (!Array.isArray(listAttributes?.options)) return null;

  let content;
  if (listAttributes.options.length < 11) {
    content = (
      <div className={classNames(styles.selectionList, styles.options)}>
        {listAttributes?.options?.map((option) => {
          const valueIdx = listAttributes?.value?.findIndex(
            (value) => value.id === option.id
          );
          return (
            <Input
              key={option.id}
              type="checkbox"
              label={option.label}
              value={valueIdx > -1}
              onClick={() => {
                listAttributes.onChange(option);
              }}
            />
          );
        })}
      </div>
    );
  } else {
    content = (
      <div
        {...rest}
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          gap: "var(--gap-list)",
        }}
      >
        {Array.isArray(listAttributes?.value) &&
          listAttributes?.value.length > 0 && (
            <div className={classNames(styles.selectionList, styles.selected)}>
              {listAttributes?.value.map((item) => (
                <Input
                  key={item.id}
                  type="checkbox"
                  label={item.label}
                  value={true}
                  onClick={() => {
                    listAttributes.onChange(item);
                  }}
                />
              ))}
            </div>
          )}
        {Array.isArray(listAttributes?.options) &&
          Array.isArray(listAttributes?.value) &&
          listAttributes?.options.length - listAttributes?.value.length >
            10 && <TextFilter {...filterAttribs} unpadded />}
        {Array.isArray(filteredOptions) && filteredOptions.length > 0 && (
          <div className={classNames(styles.selectionList, styles.options)}>
            {filteredOptions?.map((option) => (
              <Input
                key={option.id}
                type="checkbox"
                label={option.label}
                value={false}
                onClick={() => {
                  listAttributes.onChange(option);
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      style={{ display: "flex", flexGrow: "1", gap: "var(--gap-form-item)" }}
    >
      {listAttributes?.label && <Label>{listAttributes?.label}</Label>}
      {content}
    </div>
  );
};

export default ListSelector;
