import { useGetTagsQuery } from "../tagsSlice";
import useHasAccess from "../../../hooks/useHasAccess";

const useTags = () => {
  const hasAccess = useHasAccess({ apiCall: "tags.GET" });
  const tags = useGetTagsQuery(null, { skip: !hasAccess });

  return { ...tags, hasAccess };
};

export default useTags;
