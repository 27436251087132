import { FormModalControlled } from "../../components/Modal";
import DynamicForm from "../forms/DynamicForm";
import useModal from "../../hooks/useModal";

const ModifierModal = ({
  renderOpen,
  onSubmit: _onSubmit,
  onCancel: _onCancel,
  onClose: _onClose,
  formAttribs,
  data,
  ...rest
}) => {
  const { onOpen, ...restFromHook } = useModal({
    onSubmit: _onSubmit,
    onCancel: _onCancel,
    onClose: _onClose,
    data,
    ...rest,
  });
  return (
    <>
      {renderOpen(() => {
        onOpen();
      })}
      <FormModalControlled {...rest} {...restFromHook}>
        <DynamicForm {...formAttribs} data={data} />
      </FormModalControlled>
    </>
  );
};

export default ModifierModal;
