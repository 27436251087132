const sectionConfig = [
  {
    id: "training",
    destination: "ai",
    routes: ["features", "conclusions", "tags", "spectra", "diagnoses"],
  },
  {
    id: "testing",
    destination: "portal",
    scope: "organisation",
    routes: ["test", "assessments", "participants", "staff"],
  },
  {
    id: "administering",
    destination: "ai",
    routes: ["organisations", "users"],
  },
];

export default sectionConfig;
