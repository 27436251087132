import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { discardToken } from "../auth/authSlice";
import { getErrorMessage } from "../../utils/errorUtils";
const BASE_URL = window.urbanoTestApi
  ? window.urbanoTestApi
  : process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  /*
  Original code had the setting: "include", but it gives CORS error.

  Explanation:

  The reason you are getting a CORS error with credentials: "include" and not with credentials: "same-origin" is due to the CORS policy of the API you are requesting from.

  When making a cross-origin request with credentials: "include", the browser sends a preflight request to the API to check whether the request is allowed. This preflight request includes an Origin header with the domain of the requesting site, as well as other headers and options related to the request.

  The API should respond to the preflight request with appropriate headers, including the Access-Control-Allow-Credentials header with a value of true. This header indicates that the API allows requests with credentials from the requesting domain.

  However, in your case, it appears that the API is not sending the Access-Control-Allow-Credentials header with a value of true in response to the preflight request. This is causing the browser to block the request and return the CORS error you are seeing.

  On the other hand, when you set credentials: "same-origin", the request does not require a preflight request, and therefore the Access-Control-Allow-Credentials header is not required. This is why you are not getting a CORS error when using credentials: "same-origin".

  When you set the authorization header manually in the prepareHeaders function, it will be included in the request regardless of the credentials option.

  The credentials option controls whether the browser should include cookies or other credentials that are associated with the origin of the request, but it does not affect any headers that are manually added to the request.

  So in your case, if the authentication token is being included in the authorization header manually, it will be sent with the request regardless of the credentials option.
  */
  // Credentials setting is needed for JSON Web Token (JWT) authentication is used since JWT uses coockies that are not accessible for browser
  // credentials: "same-origin",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth?.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const parseReadableStream = async (stream) => {
  if (!stream) return null;
  const reader = stream.getReader();
  let result = "";
  try {
    const { value } = await reader.read();
    const decoder = new TextDecoder("utf-8");
    const decoded = decoder.decode(value);
    result = JSON.parse(decoded);
  } catch (error) {
    console.error("Error parsing ReadableStream:", error);
  } finally {
    reader.releaseLock();
    return result;
  }
};

const isBadGroup = (args) => {
  let url = "";
  if (typeof args === "string") {
    url = args;
  } else if (args?.url) {
    url = args.url;
  }
  const splitByGroupsArray = url.split("/");
  return splitByGroupsArray.length === 3 && splitByGroupsArray[1] === "groups";
};

const hasInsufficientRights = (response) => {
  return (
    getErrorMessage(response?.error) ===
    "Method is not allowed for the user with current role/offer ownership status combo"
  );
};

const roleCheckingNotPerformed = (response) => {
  const message = getErrorMessage(response?.error);
  const result = message.includes("role checking was not performed");
  return result;
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error) {
    const status = result?.error?.status || result?.error?.originalStatus;
    if (status === 401) {
      api.dispatch(discardToken());
    } else if (status === 403) {
      if (
        !(
          isBadGroup(args) ||
          hasInsufficientRights(result) ||
          roleCheckingNotPerformed(result)
        )
      ) {
        api.dispatch(discardToken());
      }
    }
    if (result?.meta?.request) {
      const stream = result.meta.request.body;
      const body = await parseReadableStream(stream);
      result.error.request = {
        url: result.meta.request.url,
        body,
      };
    }
  }
  return result;
};

export const baseUrl = BASE_URL;

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "apiAuthenticated",
  endpoints: (builder) => ({}),
});
