import {
  getTop,
  getLeft,
  getGridWidth,
  getGridHeight,
  xAxis,
  yAxis,
  getXAxisScaledToWidth,
  getYAxisScaledToHeight,
  scale,
} from "../chartConfig";

const smallestDistance = 3;
const borderLine = 3;
const gridLine = 1;

const useGrid = ({
  gridRef,
  labelsOnRight,
  labelsOnLeft,
  labelsOnTop,
  labelsOnBottom,
  size,
  ...rest
}) => {
  const gridWidth = getGridWidth({ labelsOnLeft, labelsOnRight, size });
  const gridHeight = getGridHeight({ labelsOnTop, labelsOnBottom, size });
  const xAxisScaledToWidth = getXAxisScaledToWidth({
    labelsOnLeft,
    labelsOnRight,
    size,
  });
  const yAxisScaledToHeight = getYAxisScaledToHeight({
    labelsOnTop,
    labelsOnBottom,
    size,
  });

  const getClientCoords = (event) => {
    return event.touches
      ? {
          clientX: event.touches[0].clientX,
          clientY: event.touches[0].clientY,
        }
      : event;
  };
  const getRelativePosition = (event) => {
    const { clientX, clientY } = getClientCoords(event);
    const canvasRect = gridRef?.current?.getBoundingClientRect();
    const relativeX = Math.round(((clientX - canvasRect?.x) / gridWidth) * 100);
    const relativeY = Math.round(
      ((clientY - canvasRect?.y) / gridHeight) * 100
    );
    return { relativeX, relativeY };
  };

  const getClosest = (relativeCoordArray, coordArray, relativeCoord) => {
    let smallestDistance;
    let closestPoint;
    let closestRelativePoint;
    relativeCoordArray.map((relativeCoordFromAray, idx) => {
      const distance = Math.abs(relativeCoordFromAray - relativeCoord);
      if (smallestDistance == null) {
        smallestDistance = distance;
        closestPoint = coordArray[idx];
        closestRelativePoint = relativeCoordFromAray;
      } else if (distance < smallestDistance) {
        smallestDistance = distance;
        closestPoint = coordArray[idx];
        closestRelativePoint = relativeCoordFromAray;
      }
      return false;
    });
    return [closestPoint, smallestDistance, closestRelativePoint];
  };

  const getClosestGridPoint = (event, ignoreSmallestDistance) => {
    const { relativeX, relativeY } = getRelativePosition(event);
    const [closestGridPointX, smallestDistanceToX, closestRelativeGridPointX] =
      getClosest(
        ignoreSmallestDistance ? xAxis : [0, ...xAxis],
        ignoreSmallestDistance
          ? xAxisScaledToWidth
          : getXAxisScaledToWidth({
              labelsOnLeft,
              labelsOnRight,
              includeZero: true,
            }),
        relativeX
      );
    const [closestGridPointY, smallestDistanceToY, closestRelativeGridPointY] =
      getClosest(
        ignoreSmallestDistance ? yAxis : [0, ...yAxis],
        ignoreSmallestDistance
          ? yAxisScaledToHeight
          : getYAxisScaledToHeight({
              labelsOnTop,
              labelsOnBottom,
              includeZero: true,
            }),
        relativeY
      );

    if (
      ignoreSmallestDistance ||
      (smallestDistanceToX < smallestDistance &&
        smallestDistanceToY < smallestDistance)
    ) {
      return {
        x: closestGridPointX,
        y: closestGridPointY,
        relativeX: closestRelativeGridPointX,
        relativeY: closestRelativeGridPointY,
      };
    }
    return null;
  };

  return {
    top: getTop({ labelsOnTop, size }),
    left: getLeft({ labelsOnLeft, size }),
    gridWidth,
    gridHeight,
    xAxisScaledToWidth,
    yAxisScaledToHeight,
    borderLine: `${borderLine / scale}px`,
    gridLine: `${gridLine / scale}px`,
    gridRef,
    getRelativePosition,
    getClosestGridPoint,
  };
};

export default useGrid;
