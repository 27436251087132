import useTags from "./useTags";
import useLang from "../../../hooks/useLang";
import useModifierModal from "../../forms/hooks/useModifierModal";
import { usePostTagMutation } from "../tagsSlice";
import useTagBlock from "./useTagBlock";

const useTagsList = () => {
  const tags = useTags();
  const lang = useLang();
  const { formAttribs, formActions } = useModifierModal({
    mutationHook: usePostTagMutation,
    formConfigHook: useTagBlock,
  });
  const tagConfig = useTagBlock();

  const page = {
    hasAccess: tags?.hasAccess,
    header: {
      title: tags?.isLoading ? "..." : lang("tags.title"),
      buttons: {
        caption: lang("common.create"),
        icon: "plus",
        title: lang("tags.create"),
        type: "modifier-modal",
        mutation: "tags.POST",
        formAttribs,
        ...formActions,
      },
    },
    items: [
      {
        id: "tagTable",
        type: "table",
        data: tags?.data,
        config: tagConfig,
      },
    ],
  };
  return page;
};

export default useTagsList;
