import { useGetOrganisationsQuery } from "../organisationsSlice";
import useHasAccess from "../../../hooks/useHasAccess";

const useOrganisations = () => {
  const hasAccess = useHasAccess({ apiCall: "organisations.GET" });
  const organisations = useGetOrganisationsQuery(null, { skip: !hasAccess });

  return { ...organisations, hasAccess };
};

export default useOrganisations;
