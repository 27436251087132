import useLang from "../../../hooks/useLang";

const useLaterality = ({ handleValueSubmit, frequency, type }) => {
  const lang = useLang();
  const handleButtonClick = ({ value }) => {
    handleValueSubmit({ f: frequency, type, value });
  };

  return {
    bButtonAttribs: {
      caption: "B",
      sub: lang("captions.back"),
      onClick: () => {
        handleButtonClick({ value: "B" });
      },
    },
    rButtonAttribs: {
      caption: "R",
      sub: lang("captions.right"),
      onClick: () => {
        handleButtonClick({ value: "R" });
      },
    },
    mButtonAttribs: {
      caption: "M",
      sub: lang("captions.could_not_tell"),
      onClick: () => {
        handleButtonClick({ value: "M" });
      },
    },
    lButtonAttribs: {
      caption: "L",
      sub: lang("captions.left"),
      onClick: () => {
        handleButtonClick({ value: "L" });
      },
    },
    fButtonAttribs: {
      caption: "F",
      sub: lang("captions.front"),
      onClick: () => {
        handleButtonClick({ value: "F" });
      },
    },
  };
};

export default useLaterality;
