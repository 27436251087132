import useLang from "../../../hooks/useLang";
import { useState, useEffect } from "react";
import { usePostParticipantMutation } from "../../participants/participantsSlice";
import useTestees from "../../participants/hooks/useParticipants";
import useModifierModal from "../../forms/hooks/useModifierModal";
import useTesteeBlock from "../../participants/hooks/useParticipantBlock";
import useOrganisationId from "../../organisations/hooks/useOrganisationId";
import useSearchParams from "../../../hooks/useSearchParams";

const useTestLanding = (props) => {
  const lang = useLang();
  const organisationId = useOrganisationId();
  const { getParam } = useSearchParams();
  const participantIdFromSearchParams = getParam("participant");
  const [testeeCode, setTesteeCode] = useState();
  const testees = useTestees();

  const testeeItems = useTesteeBlock(testeeCode);

  const handleParticipantCreation = (participant) => {
    setTesteeCode((state) =>
      participant?.data?.participant_id != null &&
      participant?.data?.participant_id !== state
        ? participant?.data?.participant_id
        : state
    );
  };

  const { formAttribs, formActions } = useModifierModal({
    mutationHook: usePostParticipantMutation,
    formConfigHook: useTesteeBlock,
    params: { id: organisationId },
    onSuccess: handleParticipantCreation,
  });
  const codes = testees?.data?.map((testee) => ({
    id: testee?.id,
    label: `${testee?.iid} - ${testee?.year_born} - ${lang(
      "captions." + testee?.gender + "_short"
    )}`,
  }));

  useEffect(() => {
    if (participantIdFromSearchParams != null) {
      setTesteeCode((state) =>
        participantIdFromSearchParams !== state
          ? participantIdFromSearchParams
          : state
      );
    }
  }, [participantIdFromSearchParams]);

  const articles = {
    sections: [
      {
        direction: "row",
        items: [
          {
            button: true,
            caption: lang("participants.create"),
            deemphasize: true,
            title: lang("participants.create"),
            type: "modifier-modal",
            mutation: "organisations.id.participants.POST",
            organisation: organisationId,
            formAttribs,
            ...formActions,
          },
          {
            id: "code",
            label: lang("captions.select_from_list"),
            type: "dropdown-selector",
            inputWidth: "threeQuarters",
            value: testeeCode,
            options: codes,
            idAsValue: true,
            formItem: true,
            onChange: (data) => {
              setTesteeCode(data.value);
              props?.handleChange(data);
            },
          },
        ],
        /*
        buttons: [
          {
            caption: lang("participants.create"),
            deemphasize: true,
            title: lang("participants.create"),
            type: "modifier-modal",
            mutation: "users.POST",
            formAttribs,
            ...formActions,
          },
        ],
        */
      },
      {
        direction: "row",
        items: testeeItems.slice(1, 3),
      },
      {
        items: [
          {
            id: "comment",
            type: "textarea",
            rows: "double",
            formItem: true,
            onChange: props?.handleChange,
          },
        ],
      },
    ],
  };

  return { articles };
};

export default useTestLanding;
