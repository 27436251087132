import useLang from "../../../hooks/useLang";
import { useParams } from "react-router-dom";
import useConclusion from "./useConclusion";

const operators = {
  gt: ">",
  gte: "≥",
  lt: "<",
  lte: "≤",
};

const useConclusionRulesBlock = () => {
  const lang = useLang();
  const { conclusionId } = useParams();
  const conclusion = useConclusion(
    { id: conclusionId },
    { skip: conclusionId == null }
  );

  const ruleDisplay = Array.isArray(conclusion?.data?.rules)
    ? conclusion?.data?.rules.map((rule) => {
        const operatorKey = Object.keys(rule?.operator)[0];
        return {
          ruleString: `${lang("common." + rule.parameter)} ${
            operators[operatorKey]
          } ${rule.operator[operatorKey]}`,
        };
      })
    : [];

  const ruleFormData = Array.isArray(conclusion?.data?.rules)
    ? conclusion?.data?.rules.map((rule) => ({
        operator: Object.keys(rule?.operator)[0],
        operatorValue: rule.operator[Object.keys(rule?.operator)],
      }))
    : [];

  const operatorOptions = Object.keys(operators).map((operatorKey) => ({
    id: operatorKey,
    label: operators[operatorKey],
  }));

  const items = [
    {
      id: "ruleString",
      data: ruleDisplay,
      type: "matrix",
      valueData: ruleFormData,
      inputs: [
        {
          type: "label",
          value: lang("common.age"),
          inputWidth: "quarter",
        },
        {
          id: "operator",
          type: "dropdown-selector",
          noEmpty: true,
          options: operatorOptions,
          idAsValue: true,
          inputWidth: "quarter",
        },
        {
          id: "operatorValue",
          inputWidth: "quarter",
        },
      ],
    },
  ];
  return items;
};

export default useConclusionRulesBlock;
