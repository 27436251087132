import { apiSlice } from "../api/apiSlice";

export const tagsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query({
      query: () => `/tags`,
      providesTags: [{ type: "Tags" }],
    }),
    getTagSpectra: builder.query({
      query: ({ tagId }) => `/tags/${tagId}/spectra`,
      providesTags: (result, error, arg) => [
        {
          type: "SpectraByTag",
          id: `${arg?.tagId}-${arg?.tagId}`,
        },
      ],
    }),
    postTag: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/tags`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Tags" }],
    }),
  }),
});

export const { useGetTagsQuery, useGetTagSpectraQuery, usePostTagMutation } = tagsSlice;
