import usePaging from "../../../hooks/usePaging";
import { useGetSpectraQuery } from "../spectraSlice";
import { useGetTagSpectraQuery } from "../../tags/tagsSlice";
import useHasAccess from "../../../hooks/useHasAccess";
import { useLocation } from "react-router-dom";
import useSortParam from "../../../hooks/useSortParam";

const useSpectra = ({ tagId }) => {
  const { sortValue } = useSortParam();
  const { activePage } = usePaging({});
  const location = useLocation();
  const hasAccess = useHasAccess({ apiCall: "spectra.GET" });
  const spectra = useGetSpectraQuery(
    {
      page: location.pathname.startsWith("/spectra") ? activePage : 1,
      sortBy: sortValue,
    },
    { skip: !hasAccess }
  );
  const spectraByTag = useGetTagSpectraQuery(
    { tagId },
    { skip: tagId == null }
  );
  if (tagId != null) {
    return {
      ...spectraByTag,
      data: spectraByTag?.data,
      hasAccess,
    };
  }

  return {
    ...spectra,
    data: spectra?.data?.response,
    pagination: spectra?.data?.pagination,
    hasAccess,
  };
};

export default useSpectra;
