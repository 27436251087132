import { useState } from "react";

const useMultiSelector = ({ defaultValue, options }) => {
  const initialValue =
    defaultValue == null
      ? []
      : Array.isArray(defaultValue)
      ? defaultValue
      : [defaultValue];
  const [availableOptions, setAvailableOptions] = useState(options);
  const [selected, setSelected] = useState(initialValue);

  const reset = (options, selected) => {
    if (options != null) setAvailableOptions(options);
    if (selected != null) {
      setSelected(selected);
    } else {
      setSelected(initialValue);
    }
  };

  const handleChange = (newValue) => {
    setSelected((state) => {
      let newState = [];
      let addValue = true;
      if (Array.isArray(state)) {
        newState = state.filter((itemFromState) => {
          if (itemFromState?.id !== newValue?.id) return true;
          else {
            addValue = false;
            return false;
          }
        });
      }
      if (addValue) newState.push(newValue);
      return newState;
    });
  };

  return {
    value: selected,
    setValue: setSelected,
    initialValue,
    options: availableOptions,
    onChange: handleChange,
    reset,
  };
};

export default useMultiSelector;
