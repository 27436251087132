// import useHasAccess from "../../../hooks/useHasAccess";

const useDiagnoses = () => {
  const hasAccess = true; // useHasAccess({ apiCall: "testees.GET" }); <- not sure what the api call will be
  const diagnoses = {
    data: [
      {
        id: "888888",
        name: "Galvos trauma"
      },
      {
        id: "999999",
        name: "Epilepsija"
      },

    ],
  };

  return { ...diagnoses, hasAccess };
};

export default useDiagnoses;