import { apiSlice } from "../api/apiSlice";

export const assessmentsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssessments: builder.query({
      query: ({ id }) => `/organisations/${id}/assessments`,
      providesTags: (result, error, arg) => [
        { type: "Assessment", id: arg?.id },
      ],
    }),
    getAssessment: builder.query({
      query: ({ organisationId, assessmentId }) =>
        `/organisations/${organisationId}/assessments/${assessmentId}`,
      providesTags: (result, error, arg) => [
        {
          type: "Assessment",
          id: `${arg?.organisationId}-${arg?.assessmentId}`,
        },
      ],
    }),
    postAssessment: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/organisations/${params?.id}/assessments`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Assessment" }],
    }),
  }),
});

export const {
  useGetAssessmentsQuery,
  useGetAssessmentQuery,
  usePostAssessmentMutation,
} = assessmentsSlice;
