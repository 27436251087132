import { useState, useEffect } from "react";
import {
  xAxis,
  yAxis,
  xAxisValues,
  yAxisValues,
  getTop,
  getGridHeight,
  getXAxisScaledToWidth,
  getYAxisScaledToHeight,
} from "../chartConfig";

const smallestDistance = 3;

const useErrorPoints = ({
  data,
  errorPoints: initialErrorPoints,
  labelsOnRight,
  labelsOnLeft,
  labelsOnTop,
  labelsOnBottom,
  viewOnly,
  size,
  ...rest
}) => {
  const [errorPoints, setErrorPoints] = useState();

  const top = getTop({ labelsOnTop, size });
  const gridHeight = getGridHeight({ labelsOnTop, labelsOnBottom, size });
  const xAxisScaledToWidth = getXAxisScaledToWidth({
    labelsOnRight,
    labelsOnLeft,
    size,
  });
  const yAxisScaledToHeight = getYAxisScaledToHeight({
    labelsOnTop,
    labelsOnBottom,
    size,
  });

  const getClosest = (
    relativeCoordArray,
    coordArray,
    relativeCoord,
    valueArray
  ) => {
    let smallestDistance;
    let closestPoint;
    let value;
    relativeCoordArray.map((relativeCoordFromAray, idx) => {
      const distance = Math.abs(relativeCoordFromAray - relativeCoord);
      if (smallestDistance == null) {
        smallestDistance = distance;
        closestPoint = coordArray[idx];
        value = valueArray[idx];
      } else if (distance < smallestDistance) {
        smallestDistance = distance;
        closestPoint = coordArray[idx];
        value = valueArray[idx];
      }
      return false;
    });
    return [closestPoint, smallestDistance, value];
  };

  const getClosestGridPoint = ({ relativeX, relativeY, type }) => {
    const [closestGridPointX, smallestDistanceToX, f] = getClosest(
      xAxis,
      xAxisScaledToWidth,
      relativeX,
      xAxisValues
    );
    let closestGridPointY, smallestDistanceToY, l;
    if (type === "AC") {
      [closestGridPointY, smallestDistanceToY, l] = getClosest(
        yAxis,
        yAxisScaledToHeight,
        relativeY,
        yAxisValues
      );
    } else if (type === "BC") {
      [closestGridPointY, smallestDistanceToY, l] = getClosest(
        [100],
        [top + gridHeight],
        relativeY,
        yAxisValues
      );
    } else if (type === "Selectivity") {
      [closestGridPointY, smallestDistanceToY, l] = getClosest(
        [0],
        [top],
        relativeY,
        yAxisValues
      );
    }
    if (
      smallestDistanceToX < smallestDistance &&
      smallestDistanceToY < smallestDistance
    ) {
      return { x: closestGridPointX, y: closestGridPointY, f, l };
    }
    return null;
  };

  const addPoint = ({ relativeX, relativeY, type: errorType }) => {
    setErrorPoints((state) => {
      let newState;
      if (state == null) {
        newState = [];
      } else {
        newState = [...state];
      }
      const newCoords = getClosestGridPoint({
        relativeX,
        relativeY,
        type: errorType,
      });
      let pointFound = false;
      let addBCx2 = false;
      newState = newState.filter((pointFromState) => {
        if (
          newCoords !== null &&
          pointFromState?.x === newCoords?.x &&
          pointFromState?.y === newCoords?.y
        ) {
          if (
            pointFromState?.errorType === errorType &&
            (errorType === "AC" || errorType === "Selectivity")
          ) {
            pointFound = true;
            return false;
          } else if (
            pointFromState?.errorType === errorType &&
            errorType === "BC"
          ) {
            pointFound = true;
            addBCx2 = true;
            return false;
          } else if (
            pointFromState?.errorType === "BCx2" &&
            errorType === "BC"
          ) {
            pointFound = true;
            return false;
          }
        }
        return true;
      });
      if (newCoords != null) {
        if (pointFound === false) {
          newState.push({ ...newCoords, errorType });
        } else if (addBCx2) {
          newState.push({ ...newCoords, errorType: "BCx2" });
        }
        return newState;
      }
      return state;
    });
  };

  const getX = (frequency) => {
    const xIdx = xAxisValues.indexOf(frequency);
    const x = xAxisScaledToWidth[xIdx];
    const relativeX = xAxis[xIdx];
    return { x, relativeX };
  };

  const getY = (level) => {
    const yIdx = yAxisValues.indexOf(level);
    let y = yAxisScaledToHeight[yIdx];
    let relativeY = yAxis[yIdx];
    if (yIdx < 0) {
      y = top;
      relativeY = 0;
    }
    return { y, relativeY };
  };

  useEffect(() => {
    if (data == null) return;
    const blueCrosses = data?.blue_crosses?.map((errorPoint) => {
      const { x, relativeX } = getX(errorPoint.f);
      const { y, relativeY } = getY(errorPoint.l);
      return {
        ...errorPoint,
        x,
        y,
        relativeX,
        relativeY,
        errorType: "AC",
      };
    });
    const blueSlashes = data?.blue_slashes?.map((errorPoint) => {
      const { x, relativeX } = getX(errorPoint);
      return {
        ...errorPoint,
        x,
        y: top,
        relativeX,
        relativeY: 0,
        errorType: "Selectivity",
      };
    });
    const redSlashes = data?.red_slashes?.map((errorPoint) => {
      const { x, relativeX } = getX(errorPoint);
      const y = gridHeight + top;
      const relativeY = 100;
      return {
        ...errorPoint,
        x,
        y,
        relativeX,
        relativeY,
        errorType: "BC",
      };
    });
    const redDblSlashes = data?.red_dbl_slashes?.map((errorPoint) => {
      const { x, relativeX } = getX(errorPoint);
      const y = gridHeight + top;
      const relativeY = 100;
      return {
        ...errorPoint,
        x,
        y,
        relativeX,
        relativeY,
        errorType: "BCx2",
      };
    });
    let newErrorPoints = [];
    if (blueCrosses != null) {
      newErrorPoints = [...blueCrosses];
    }
    if (blueSlashes != null) {
      newErrorPoints = [...newErrorPoints, ...blueSlashes];
    }
    if (redSlashes != null) {
      newErrorPoints = [...newErrorPoints, ...redSlashes];
    }
    if (redDblSlashes != null) {
      newErrorPoints = [...newErrorPoints, ...redDblSlashes];
    }

    setErrorPoints(newErrorPoints);
    // eslint-disable-next-line
  }, [JSON.stringify(data)]);

  return { errorPoints, addPoint: viewOnly ? () => {} : addPoint };
};

export default useErrorPoints;
