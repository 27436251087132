import { useRef, useEffect } from "react";
import useChartSeries from "./useChartSeries";
import useErrorPoints from "./useErrorPoints";
import useGrid from "./useGrid";
import useDragMode from "./useDragMode";
import useDrawMode from "./useDrawMode";
import {
  containerWidth,
  containerHeight,
  getScale,
  yAxisValuesSecondary,
} from "../chartConfig";

const useSpectrumSide = ({
  data,
  acDataPoints,
  bcDataPoints,
  errorPoints: initialErrorPoints,
  selectedSeries,
  deleteMode,
  onChange,
  side,
  ...rest
}) => {
  const gridRef = useRef();
  const draggingRef = useRef();
  const dragModeRef = useRef();
  const { getRelativePosition, getClosestGridPoint } = useGrid({
    gridRef,
    ...rest,
  });
  const { addPoint, errorPoints } = useErrorPoints({
    errorPoints: initialErrorPoints,
    data,
    ...rest,
  });
  const {
    chartSeriesHandlers: chartSeriesHandlersAC,
    chartSeries: chartSeriesAC,
  } = useChartSeries({
    id: `AC${side ? side : ""}`,
    color: "blue",
    type: "ac",
    dataPoints: data?.ac?.points || [],
    ...rest,
  });
  const {
    chartSeriesHandlers: chartSeriesHandlersBC,
    chartSeries: chartSeriesBC,
  } = useChartSeries({
    id: `BC${side ? side : ""}`,
    color: "red",
    type: "bc",
    dataPoints: data?.bc?.points || [],
    ...rest,
  });
  const handlerIds = ["AC", "BC"];
  const chartSeries = [chartSeriesAC, chartSeriesBC];
  const chartSeriesHandlers = [chartSeriesHandlersAC, chartSeriesHandlersBC];
  const { handleDragStart, handleDrag, handleDragFinish } = useDragMode({
    getRelativePosition,
    chartSeriesHandlers,
    toggleErrorPoint: addPoint,
    selectedSeriesIdx:
      selectedSeries === "AC" ? 0 : selectedSeries === "BC" ? 1 : -1,
  });

  const {
    handleDragStart: drawStart,
    handleDrag: draw,
    handleDragFinish: drawFinish,
  } = useDrawMode({
    getRelativePosition,
    getClosestGridPoint,
    chartSeriesHandlers,
    toggleErrorPoint: addPoint,
    deleteMode,
    selectedSeriesIdx:
      selectedSeries === "AC" ? 0 : selectedSeries === "BC" ? 1 : -1,
  });

  const preventDefault = (event) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    event.stopPropagation();
  };

  const onDragStart = (event) => {
    preventDefault(event);
    let success = false;
    const relativeCoords = getRelativePosition(event);
    if (!deleteMode) {
      chartSeriesHandlers.map((handler, idx) => {
        const closestPoint = handler.findClosestPoint(relativeCoords);
        if (
          closestPoint.isInRange &&
          (selectedSeries == null || selectedSeries === handlerIds[idx])
        ) {
          dragModeRef.current = true;
        }
        return false;
      });
    }

    draggingRef.current = true;
    if (!dragModeRef.current) {
      success = drawStart(event);
    }
    if (!success) {
      handleDragStart(event);
    }
  };
  const onDragFinish = (event) => {
    preventDefault(event);
    draggingRef.current = false;
    const success = drawFinish();
    if (!success || dragModeRef.current) {
      handleDragFinish();
      dragModeRef.current = false;
    }
  };
  const onDrag = (event) => {
    preventDefault(event);
    if (!draggingRef.current) return;
    if (dragModeRef.current) {
      handleDrag(event);
    } else {
      const success = draw(event);
      if (!success) {
        handleDrag(event);
      }
    }
  };

  const convertToApiFormat = (chartSeries, errorPoints) => {
    let result = {};
    if (side == null) return {};
    result = { [side]: { ac: { points: [] }, bc: { points: [] } } };
    result[side].ac.points = chartSeries[0]?.points?.map((point) => ({
      f: point.f,
      l: point.l,
    }));
    result[side].bc.points = chartSeries[1]?.points?.map((point) => ({
      f: point.f,
      l: point.l,
    }));
    errorPoints?.map((errorPoint) => {
      if (errorPoint?.errorType === "AC") {
        if (result[side].blue_crosses == null) result[side].blue_crosses = [];
        result[side].blue_crosses.push({ f: errorPoint.f, l: errorPoint.l });
      }
      if (errorPoint?.errorType === "Selectivity") {
        if (result[side].blue_slashes == null) result[side].blue_slashes = [];
        result[side].blue_slashes.push(errorPoint.f);
      }
      if (errorPoint?.errorType === "BC") {
        if (result[side].red_slashes == null) result[side].red_slashes = [];
        result[side].red_slashes.push(errorPoint.f);
      }
      if (errorPoint?.errorType === "BCx2") {
        if (result[side].red_dbl_slashes == null)
          result[side].red_dbl_slashes = [];
        result[side].red_dbl_slashes.push(errorPoint.f);
      }
      return false;
    });
    return result;
  };

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(convertToApiFormat(chartSeries, errorPoints));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(chartSeries), JSON.stringify(errorPoints)]);

  return {
    svgAttribs: {
      width: containerWidth * getScale({ size: rest?.size }),
      height: containerHeight * getScale({ size: rest?.size }),
      viewBox: `0 0 ${containerWidth} ${containerHeight}`,
    },
    gridAttribs: {
      gridRef,
    },
    dragActions: {
      onMouseDown: onDragStart,
      onTouchStart: onDragStart,
      onMouseUp: onDragFinish,
      onTouchEnd: onDragFinish,
      onMouseMove: onDrag,
      onTouchMove: onDrag,
      onMouseLeave: onDragFinish,
    },
    chartSeries,
    errorPoints,
    yAxisValuesSecondary,
  };
};

export default useSpectrumSide;
