import Button from "./Button";
import SpectrumPreview from "../features/spectrum/SpectrumPreview";
import styles from "./table.module.css";
import { CellButtons } from "./HeaderButtons";
import P, { P2 } from "./Paragraph";

const CellContent = ({ config, data }) => {
  const getValue = (obj, keys) => {
    return keys.reduce(
      (current, key) =>
        current && current[key] !== undefined ? current[key] : undefined,
      obj
    );
  };
  const caption =
    config.caption !== undefined
      ? config.caption
      : getValue(data, config?.id?.split("."));
  let isLoading;
  if (typeof config?.isLoading === "function") {
    isLoading = config.isLoading(data);
  }
  let hasWarning;
  if (typeof config?.warning === "function") {
    hasWarning = config.warning(data);
  }
  const captionWithWarning = `${caption}${hasWarning ? " ⚠" : ""}`;

  let content =
    caption != null ? (
      <>
        {config?.fontSize !== "small" ? (
          <P>{captionWithWarning}</P>
        ) : (
          <P2>{captionWithWarning}</P2>
        )}
      </>
    ) : (
      ""
    );
  if (typeof config.link === "function") {
    content = (
      <Button
        inline
        navigation={config?.fontSize !== "small"}
        unpadded
        loader={isLoading}
        to={config.link(data)}
        icon={config.icon}
      >
        {captionWithWarning}
      </Button>
    );
  }
  if (typeof config.linkList === "function") {
    const links = config.linkList(data);
    if (links && Array.isArray(links)) {
      let linksSliced = links;
      let overflow = false;
      if (config?.arrayLimit != null && links.length > config?.arrayLimit) {
        linksSliced = links.slice(0, config?.arrayLimit);
        overflow = true;
      }
      content = (
        <div className={styles.cellContainer}>
          {linksSliced.map((link) => {
            return (
              <Button
                key={link.id || link.to}
                inline
                navigation={config?.fontSize !== "small"}
                unpadded
                to={link.to}
              >
                {link.caption}
              </Button>
            );
          })}
          {overflow && (
            <p
              style={{
                fontSize: "var(--font-size-button)",
                lineHeight: "var(--line-height-button)",
              }}
            >{`+${links.length - config?.arrayLimit}`}</p>
          )}
        </div>
      );
    }
  }
  if (config.type === "spectrum") {
    const spectrumPreview = (
      <SpectrumPreview
        key={data?.id}
        data={data[config?.id]}
        viewOnly
        size="small"
      />
    );
    if (typeof config?.link === "function") {
      content = (
        <Button key={data?.id} to={config?.link(data)} inline>
          {spectrumPreview}
        </Button>
      );
    } else {
      content = spectrumPreview;
    }
  }
  if (Array.isArray(config?.buttons)) {
    content = (
      <CellButtons
        buttons={config?.buttons?.map((buttonConfig) => ({
          ...buttonConfig,
          data,
        }))}
      />
    );
  }
  return content;
};

export default CellContent;
