import { useGetUsersQuery } from "../usersSlice";
import useHasAccess from "../../../hooks/useHasAccess";

const useUser = (userId) => {
  const hasAccess = useHasAccess({ apiCall: "users.GET" });
  const users = useGetUsersQuery(null, { skip: !hasAccess });
  let user = { ...users };
  if (Array.isArray(user.data)) {
    user.data = { ...user.data.find((user) => user.id === userId) };
  }
  let initials = user?.data?.given_name ? user?.data?.given_name[0] : null;
  if (initials != null) user.data.initials = initials;
  return user;
};

export default useUser;
