import { PageHeader } from "../../components/Header";
import { PageFooter } from "../../components/Footer";
import SpectrumPreview from "../spectrum/SpectrumPreview";
import PageContent from "../../components/PageContent";

const TestResults = (props) => {
  return (
    <>
      <PageHeader {...props?.header} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SpectrumPreview {...props} id={null} viewOnly />
      </div>
      <div>
        <PageContent
          items={[
            {
              id: "comment",
              formItem: true,
              onChange: props?.handleChange,
            },
          ]}
        />
      </div>
      <PageFooter {...props?.footer} />
    </>
  );
};

export default TestResults;
