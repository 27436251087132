const useUserBlock = (staff) => {
  const items = [
    {
      id: "full_name",
      width: 56,
      pageOnly: true,
    },
    { id: "given_name", value: "", required: true, formOnly: true },
    { id: "family_name", value: "", required: true, formOnly: true },
    { id: "primary_email", value: "", required: true, width: 72 },
    {
      id: "roles",
      options: staff ? ["TESTER"] : ["ADMIN"],
      type: "chip-multiselector",
      required: true,
      formOnly: true,
      width: 72,
    },
    {
      id: staff ? "organisation_roles" : "roles",
      pageOnly: true,
      width: 72,
    },
  ];

  return items;
};

export default useUserBlock;
