import { useParams } from "react-router-dom";
import useTags from "./useTags";
import useLang from "../../../hooks/useLang";
import useTagSpectraBlock from "./useTagSpectraBlock";

const useTagsList = () => {
  const lang = useLang();
  const tags = useTags();
  const { tagId } = useParams();
  const spectraItems = useTagSpectraBlock();

  let tagData = {};
  tagData = tags?.data?.find((tag) => tag?.id === tagId);

  const page = {
    hasAccess: tags?.hasAccess,
    header: {
      title: tags?.isLoading ? "..." : tagData?.name,
    },
    articles: [
      {
        items: {
          id: "description",
          value: tagData?.description,
          width: 72,
        },
      },
      {
        header: {
          title: lang("spectra.examples"),
        },
        items: spectraItems,
      },
    ],
  };
  return page;
};

export default useTagsList;
