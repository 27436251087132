import useLang from "../../../hooks/useLang";

const useSelectivity = ({
  handleValueSubmit,
  handleNext,
  frequency,
  type,
  side,
}) => {
  const lang = useLang();
  lang("captions.correct");
  return {
    okButtonAttribs: {
      caption: "OK",
      sub: lang("captions.correct"),
      onClick: handleNext,
    },
    mistakeButtonAttribs: {
      caption: "/",
      sub: lang("captions.error"),
      onClick: () => {
        handleValueSubmit({
          type,
          side,
          f: frequency,
          mistake: "blue_slashes",
        });
      },
    },
  };
};

export default useSelectivity;
