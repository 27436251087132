import { DialogHeader } from "../../components/Header";
import LevelIndicators from "./LevelIndicators";
import LevelContext from "./LevelContext";
import Slider from "../../components/Slider";
import useLevelSlider from "./hooks/useLevelSlider";
import Button from "../../components/Button";
import useLang from "../../hooks/useLang";

const LevelSlider = (props) => {
  const lang = useLang();
  const { sliderAttribs, levelIndicators, top, level, unHeardButtonAttribs } =
    useLevelSlider(props);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <DialogHeader title={`${props?.frequency} Hz`} centered />
      <div style={{ display: "flex", marginBottom: "var(--gap-base)" }}>
        <LevelIndicators levelIndicators={levelIndicators} />
        <div
          style={{
            position: "relative",
            marginRight: "150px",
          }}
        >
          <Slider id="level" name="level" vertical {...sliderAttribs} />
          <LevelContext {...{ ...props, top, level }} />
        </div>
      </div>
      <Button {...unHeardButtonAttribs}>{lang("test.did_not_hear")}</Button>
    </div>
  );
};

export default LevelSlider;
