import { ToggleChip } from "./Chip";
import Label from "../../components/Label";

const ChipSelector = ({ options, value, defaultValue, onChange, label }) => {
  const safeDefault = {};
  safeDefault.id = defaultValue?.id || null;
  safeDefault.label = defaultValue?.label || "-";

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        gap: "var(--gap-form-item)",
      }}
    >
      {label ? <Label>{label}</Label> : null}
      {Array.isArray(options) ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "var(--spacing-2)",
            flexWrap: "wrap",
          }}
        >
          {options.map((option) => (
            <ToggleChip
              key={option?.id}
              onClick={() => {
                onChange(option);
              }}
              active={
                Array.isArray(value)
                  ? value.findIndex((item) => item?.id === option?.id) > -1
                  : value?.id === option?.id
              }
            >
              {option?.label}
            </ToggleChip>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ChipSelector;
