import { createSlice } from "@reduxjs/toolkit";

let destination;
if (process.env.REACT_APP_DESTINATION === "ai") {
  destination = "ai";
}
if (process.env.REACT_APP_DESTINATION === "portal") {
  destination = "portal";
}

const getRoles = (roles) => {
  if (destination === "ai") {
    return roles.filter((role) => !role.includes("@"));
  }
  if (destination === "portal") {
    return roles.filter((role) => role.includes("@"));
  }
  return roles;
};

const getOrganisations = (roles) => {
  if (destination === "ai") return [];
  const organisations = roles
    .flatMap((role) => {
      const roleArray = role.split("@");
      if (roleArray.length > 1) {
        return roleArray.slice(-1);
      }
      return null;
    })
    .filter((organisation) => organisation != null);
  return organisations;
};

const authSlice = createSlice({
  name: "auth",
  initialState: { token: null, uid: null },
  reducers: {
    setCredentials: (state, action) => {
      const { token, uid, roles } = action.payload;
      const filteredRoles = getRoles(roles || state.roles);
      state.token = token;
      state.uid = uid || state.uid;
      state.roles = filteredRoles;
      state.organisations = filteredRoles
        ? getOrganisations(filteredRoles)
        : [];
    },
    setCurrentOrganisation: (state, action) => {
      state.currentOrganisation = action.payload;
    },
    discardToken: (state) => {
      state.token = null;
    },
    ageToken: (state) => {
      state.token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI2MzdiNDQ5YjUzNTc0YWQ4YWYzOTJmMzUiLCJ0aWQiOiI2NDE0MzQwOWQ5YTI3NTY4ODg1OGE1NDgiLCJleHAiOjE2NzkxMDQ4MDB9.H6eJolZoioskaM_HXZCpi2Ggb_o4aj-6UmIbCIdPTAs";
    },
    logOut: (state) => {
      state.token = null;
      state.uid = null;
    },
  },
});

export const {
  setCredentials,
  discardToken,
  ageToken,
  logOut,
  setCurrentOrganisation,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.uid;
export const selectCurrentToken = (state) => state.auth.token;
export const selectRoles = (state) => state.auth.roles;
export const selectOrganisations = (state) => state.auth.organisations;
export const selectCurrentOrganisation = (state) =>
  state.auth.currentOrganisation;
