import { apiSlice } from "../api/apiSlice";

export const staffSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStaff: builder.query({
      query: ({ id }) => `/organisations/${id}/users`,
      providesTags: (result, error, arg) => [{ type: "staff", id: arg?.id }],
    }),
    postStaff: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/organisations/${params?.id}/users`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "staff" }],
    }),
    removeStaff: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/organisations/${params?.organisationId}/users/${params?.userId}`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: [{ type: "staff" }],
    }),
    patchStaff: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/organisations/${params?.organisationId}/users/${params?.userId}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [{ type: "staff" }],
    }),
  }),
});

export const { useGetStaffQuery, usePostStaffMutation, useRemoveStaffMutation, usePatchStaffMutation } =
  staffSlice;
