import useTableRowConfig from "../hooks/useTableRowConfig";
import TableHeaderCell from "./TableHeaderCell";
import styles from "./table.module.css";

const TableHeader = ({ config, ...rest }) => {
  const { tableRowConfig } = useTableRowConfig(config);

  return (
    <thead className={styles.tableHader} {...rest}>
      <tr className={styles.tableHaderRow}>
        {tableRowConfig?.map((cell, idx) => {
          if (cell?.formOnly === true) return null;
          return <TableHeaderCell key={cell.id} config={cell} />;
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
