import { useParams } from "react-router-dom";
import useGetSpectrum from "./useGetSpectrum";
import useFeatures from "../../features/hooks/useFeatures";
import getOptions from "../../../utils/getOptions";
import toRemove from "../../../utils/getItemsToRemove";
import toAdd from "../../../utils/getItemsToAdd";

const useSpectrumFeatures = () => {
  const { spectrumId } = useParams();
  const spectrum = useGetSpectrum({ id: spectrumId });
  const features = useFeatures();

  const options = getOptions(features?.data, "name");

  const getItemsToAdd = (newList) =>
    toAdd(spectrum?.data?.feature_ids, newList);
  const getItemsToRemove = (newList) =>
    toRemove(spectrum?.data?.feature_ids, newList);

  return {
    features: features?.data,
    options,
    getItemsToAdd,
    getItemsToRemove,
  };
};

export default useSpectrumFeatures;
