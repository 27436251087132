import { useEffect } from "react";
import useLang from "../../../hooks/useLang";
import { useLocation } from "react-router-dom";
import useOrganisationId from "../../organisations/hooks/useOrganisationId";
import { useHasAccessFunctions } from "../../../hooks/useHasAccess";
import { useSelector } from "react-redux";
import {
  setCurrentOrganisation,
  selectOrganisations,
  selectCurrentOrganisation,
} from "../../auth/authSlice";
import sectionConfig from "../sectionConfig";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetProfileQuery } from "../../profile/profileSlice";

const routeIcons = {
  features: "features",
  conclusions: "list",
  diagnoses: "stetoscope",
  spectra: "graph",
  tags: "tag",
  users: "users",
  staff: "staff",
  organisations: "organisation",
  assessments: "assessment",
  test: "headphones",
  participants: "participant",
};

const useNavigation = () => {
  let destination;
  if (process.env.REACT_APP_DESTINATION === "ai") {
    destination = "ai";
  }
  if (process.env.REACT_APP_DESTINATION === "portal") {
    destination = "portal";
  }

  const dispatch = useDispatch();
  const currentOrganisationId = useSelector(selectCurrentOrganisation);
  const organisationId = useOrganisationId();
  const navigate = useNavigate();
  const organisations = useSelector(selectOrganisations);
  const profile = useGetProfileQuery();
  const selectedOrganisation = profile?.data?.organisations?.data?.find(
    (organisation) => {
      return organisation.id === currentOrganisationId;
    }
  );

  const { hasAccessToLink } = useHasAccessFunctions();
  const location = useLocation();
  const lang = useLang();

  const sections = sectionConfig.map((section) => {
    if (destination != null && destination !== section.destination) return {};
    const filteredLinks = section?.routes?.filter((route) => {
      const routeWithScope =
        section.scope === "organisation" && currentOrganisationId
          ? `${currentOrganisationId}/${route}`
          : route;
      return hasAccessToLink(routeWithScope);
    });
    const links = filteredLinks.map((route) => {
      const routeWithScope =
        section.scope === "organisation" && currentOrganisationId
          ? `${currentOrganisationId}/${route}`
          : route;

      return {
        to: routeWithScope,
        caption: lang(`${route}.title`),
        icon: routeIcons[route],
        selected:
          location?.pathname === `/${routeWithScope}` ||
          location?.pathname?.startsWith(`/${routeWithScope}/`),
      };
    });
    const title =
      section?.scope === "organisation"
        ? selectedOrganisation?.name
        : undefined;
    return { ...section, links, title };
  });

  const filteredSections = sections.filter(
    (section) => Array.isArray(section.links) && section.links.length > 0
  );

  const handleOrganisationChange = (data) => {
    if (data?.id) {
      dispatch(setCurrentOrganisation(data?.id));
    }
  };

  const organisationOptions = profile?.data?.organisations?.map(
    (organisation) => ({
      key: organisation.id,
      id: organisation.id,
      caption: organisation.name,
      label: organisation.name,
      important: organisation.id === currentOrganisationId,
      onClick: () => {
        handleOrganisationChange(organisation.id);
      },
    })
  );

  useEffect(() => {
    if (Array.isArray(organisations)) {
      const idx = organisations.indexOf(organisationId);
      if (currentOrganisationId == null) {
        dispatch(setCurrentOrganisation(organisations[idx > -1 ? idx : 0]));
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(organisations), organisationId]);

  useEffect(() => {
    if (
      organisationId != null &&
      currentOrganisationId != null &&
      organisationId !== currentOrganisationId
    ) {
      const newPathname = location?.pathname?.replace(
        organisationId,
        currentOrganisationId
      );
      navigate(newPathname);
    }
    // eslint-disable-next-line
  }, [currentOrganisationId]);

  return {
    sections: filteredSections,
    organisationSelector: {
      show:
        (destination == null || destination === "portal") &&
        Array.isArray(organisationOptions) &&
        organisationOptions.length > 0,
      value: { id: currentOrganisationId },
      options: organisationOptions,
      onChange: handleOrganisationChange,
    },
  };
};

export default useNavigation;
