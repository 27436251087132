import Button from "../../components/Button";
import { P2 } from "../../components/Paragraph";
import { H3 } from "../../components/Heading";

const LateralityInput = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 var(--spacing-6)",
      }}
    >
      {props?.viewOnly ? (
        <>
          {props?.size !== "small" ? (
            <H3>{props?.value}</H3>
          ) : (
            <P2>{props?.value}</P2>
          )}
        </>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              key={"B"}
              inline
              caption={"B"}
              onClick={(e) => {
                e.preventDefault();
                props?.handleClick({ f: props?.f, value: "B" });
              }}
              pressed={props?.value === "B"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {["R", "M", "L"]?.map((option) => (
              <Button
                key={option}
                inline
                caption={option}
                onClick={(e) => {
                  e.preventDefault();
                  props?.handleClick({ f: props?.f, value: option });
                }}
                pressed={props?.value === option}
              />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              key={"F"}
              inline
              caption={"F"}
              onClick={(e) => {
                e.preventDefault();
                props?.handleClick({ f: props?.f, value: "F" });
              }}
              pressed={props?.value === "F"}
            />
          </div>
        </div>
      )}
      {props?.size !== "small" ? <P2>{props?.caption}</P2> : null}
    </div>
  );
};

export default LateralityInput;
