import { useState } from "react";
import useFormData from "../../forms/hooks/useFormData";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError } from "../../alerts/alertsSlice";

const useModifierModal = ({
  mutationHook,
  formConfigHook,
  navigateOnSuccess,
  onSuccess,
  params: paramsFromProps,
}) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const navigate = useNavigate();
  const formConfig = formConfigHook();
  const dispatch = useDispatch();

  let formFields = [];
  let inputs = [];
  if (Array.isArray(formConfig)) {
    inputs = formConfig;
  } else if (formConfig.sections) {
    if (Array.isArray(formConfig.sections)) {
      formConfig.sections.map((section) => {
        if (Array.isArray(section.items)) {
          inputs = [...inputs, ...section.items];
          formFields.push({ header: section.header, formRows: section.items });
        }
        return false;
      });
    } else {
      if (Array.isArray(formConfig.sections.items)) {
        inputs = formConfig.sections.items;
        formFields.push({
          header: formConfig.sections.header,
          formRows: formConfig.sections.items,
        });
      }
    }
  }

  const { onChange, formData, missingInput } = useFormData(inputs);
  const [mutation, mutationProps] = mutationHook();
  const isLoading = mutationProps?.isLoading || false;
  const formRows = inputs;

  const onSubmit = async ({ params: paramsFromData }) => {
    try {
      setMutationLoading(true);
      const response = await mutation({
        body: formData,
        params: paramsFromData || paramsFromProps,
      });
      if (response?.error) {
        throw response?.error;
      }
      setMutationLoading(false);
      if (typeof navigateOnSuccess === "function") {
        const destination = navigateOnSuccess(response);
        if (destination != null) {
          navigate(destination);
        }
      }
      if (typeof onSuccess === "function") {
        onSuccess(response);
      }
    } catch (err) {
      setMutationLoading(false);
      dispatch(setError(err));
      console.error(err);
    }
  };

  return {
    formAttribs: {
      formData,
      onChange,
      formRows,
      formFields,
    },
    formActions: {
      onSubmit,
      isLoading: isLoading || mutationLoading,
      disabled: missingInput != null || isLoading || mutationLoading,
    },
  };
};

export default useModifierModal;
