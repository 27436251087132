import { P2 } from "./Paragraph";
import UserRoundel from "./UserRoundel";
import useUser from "../features/users/hooks/useUser";
import { useGetProfileQuery } from "../features/profile/profileSlice";
import styles from "./userBadge.module.css";

const UserBadge = ({
  userId,
  family_name,
  given_name,
  picture,
  roundelOnly,
  large,
  onClick,
}) => {
  const userProfile = useUser(userId);
  const myProfile = useGetProfileQuery();
  const user = userId ? userProfile : myProfile;
  if (user?.data == null && given_name == null && family_name == null)
    return null;

  const content = (
    <div className={styles.userBadge}>
      <UserRoundel
        picture={picture || user?.data?.picture}
        initials={
          user?.data?.initials
            ? user?.data?.initials
            : given_name
            ? given_name.slice(0, 1)
            : user?.data?.given_name
            ? user?.data?.given_name.slice(0, 1)
            : null
        }
        large={large}
      />
      {!roundelOnly &&
        (given_name || user?.data?.given_name) &&
        (family_name || user?.data?.family_name) && (
          <P2>{`${given_name || user?.data?.given_name} ${
            family_name || user?.data?.family_name
          }`}</P2>
        )}
    </div>
  );

  if (typeof onClick === "function") {
    return (
      <button className={styles.userBadgeButton} onClick={onClick}>
        {content}
      </button>
    );
  }

  return content;
};

export default UserBadge;
