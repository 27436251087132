import { useParams } from "react-router-dom";
import useFeature from "./useFeature";
import useConclusions from "../../conclusions/hooks/useConclusions";
import getOptions from "../../../utils/getOptions";
import getIds from "../../../utils/getIds";
import toRemove from "../../../utils/getItemsToRemove";
import toAdd from "../../../utils/getItemsToAdd";

const useLinkedConclusions = () => {
  const { featureId } = useParams();
  const feature = useFeature({ id: featureId }, { skip: featureId == null });
  const conclusions = useConclusions();

  const options = getOptions(conclusions?.data, "name");
  const ids = getIds(feature?.data?.conclusions);
  const getItemsToAdd = (newList) => toAdd(ids, newList);
  const getItemsToRemove = (newList) => toRemove(ids, newList);

  return {
    conclusions: conclusions?.data,
    options,
    getItemsToAdd,
    getItemsToRemove,
  };
};

export default useLinkedConclusions;
