import {
  left,
  leftLabelWidth,
  getTop,
  topLabelHeight,
  getLeft,
  rightLabelWidth,
  getGridWidth,
} from "../chartConfig";

const useSideLabel = ({
  onLeft,
  onRight,
  labelsOnLeft,
  labelsOnRight,
  noPadding,
  side,
}) => {
  const top = getTop({ labelsOnTop: true });
  let leftPosition;
  let topPosition;
  if (onLeft) {
    leftPosition = `${leftLabelWidth / 2 + (noPadding ? 0 : left)}px`;
    topPosition = `${top - topLabelHeight}px`;
  }
  if (onRight) {
    leftPosition = `${
      getLeft({ labelsOnLeft }) +
      getGridWidth({ labelsOnRight, labelsOnLeft }) +
      rightLabelWidth / 2
    }px`;
    topPosition = `${top - topLabelHeight}px`;
  }

  return {
    left: leftPosition,
    top: topPosition,
    label: side === "right" ? "R" : side === "left" ? "L" : "",
  };
};

export default useSideLabel;
