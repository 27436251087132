import useAcOrBc from "./useAcOrBc";

const useACBC = ({ type, data }) => {
  const dataOfSameType = useAcOrBc(data, type);

  return {
    data: dataOfSameType,
  };
};

export default useACBC;
