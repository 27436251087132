import { useDispatch } from "react-redux";
import { apiSlice } from "../../api/apiSlice";
import { useParams } from "react-router-dom";
import {
  useAssignTagToSpectrumMutation,
  useRemoveTagFromSpectrumMutation,
} from "../spectraSlice";
import useSpectrumTags from "./useSpectrumTags";

const usePatchSpectrumTags = () => {
  const dispatch = useDispatch();
  const { spectrumId } = useParams();
  const { getItemsToAdd, getItemsToRemove } = useSpectrumTags();
  const [postMutation] = useAssignTagToSpectrumMutation();
  const [deleteMutation] = useRemoveTagFromSpectrumMutation();

  const mutation = async (data) => {
    const newList = data?.body?.name || [];
    const itemsToAdd = getItemsToAdd(newList);
    const itemsToRemove = getItemsToRemove(newList);

    const patchResult = dispatch(
      apiSlice.util.updateQueryData(
        "getSpectrum",
        { id: spectrumId },
        (draft) => {
          draft.tag_ids = data?.body?.name;
        }
      )
    );
    let i;
    for (i = 0; i < itemsToAdd.length; i++) {
      try {
        await postMutation({
          id: spectrumId,
          body: { tag_id: itemsToAdd[i] },
        });
      } catch (err) {
        patchResult.undo();
        console.error(err);
      }
    }
    for (i = 0; i < itemsToRemove.length; i++) {
      try {
        await deleteMutation({
          params: { spectrumId, tagId: itemsToRemove[i] },
        });
      } catch (err) {
        patchResult.undo();
        console.error(err);
      }
    }
    dispatch(
      apiSlice.util.invalidateTags([
        { type: "Spectrum" },
        { type: "Spectrum", id: spectrumId },
      ])
    );
  };

  return [mutation];
};

export default usePatchSpectrumTags;
