import {
  xAxisValues,
  yAxisValues,
  left,
  leftLabelWidth,
  getTop,
  topLabelHeight,
  getLeft,
  rightLabelWidth,
  getGridWidth,
  getXAxisScaledToWidth,
  getYAxisScaledToHeight,
} from "../chartConfig";

const useAxisLabels = ({
  onTop,
  onBottom,
  onLeft,
  onRight,
  oddOnly,
  labelsOnRight,
  labelsOnLeft,
  labelsOnTop,
  labelsOnBottom,
  axisValues: axisValuesFromProps,
  noPadding,
}) => {
  const top = getTop({ labelsOnTop });

  let axisValues = [];
  let axisScaled = [];
  let height;
  let width;
  if (onTop || onBottom) {
    axisScaled = getXAxisScaledToWidth({ labelsOnRight, labelsOnLeft });
    axisValues = xAxisValues;
    height = topLabelHeight;
  }
  if (onLeft || onRight) {
    axisScaled = getYAxisScaledToHeight({ labelsOnTop, labelsOnBottom });
    axisValues = yAxisValues;
    width = leftLabelWidth;
  }
  if (axisValuesFromProps) axisValues = axisValuesFromProps;

  let axisLabels = axisValues.map((value, idx) => {
    let leftPosition;
    let topPosition;
    if (onTop) {
      leftPosition = `${axisScaled[idx]}px`;
      topPosition = `${top - topLabelHeight / 2}px`;
    }
    if (onLeft) {
      leftPosition = `${leftLabelWidth / 2 + (noPadding ? 0 : left)}px`;
      topPosition = `${axisScaled[idx]}px`;
    }
    if (onRight) {
      leftPosition = `${
        getLeft({ labelsOnLeft }) +
        getGridWidth({ labelsOnRight, labelsOnLeft }) +
        rightLabelWidth / 2
      }px`;
      topPosition = `${axisScaled[idx]}px`;
    }
    return {
      id: `${value}-${idx}`,
      value,
      left: leftPosition,
      top: topPosition,
      width,
      height,
    };
  });
  if (oddOnly) {
    axisLabels = axisLabels.filter((item, idx) => idx % 2 !== 0);
  }

  return axisLabels;
};

export default useAxisLabels;
