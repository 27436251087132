import { PageHeader } from "../../components/Header";
import { PageFooter } from "../../components/Footer";
import { DialogHeader } from "../../components/Header";
import LateralityButton from "./LateralityButton";
import useSelectivity from "./hooks/useSelectivity";

const Selectivity = (props) => {
  const { okButtonAttribs, mistakeButtonAttribs } = useSelectivity(props);
  return (
    <>
      <PageHeader {...props?.header} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogHeader title={`${props?.frequency} Hz`} centered />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "var(--gap-base)",
        }}
      >
        <LateralityButton {...okButtonAttribs} />
        <LateralityButton {...mistakeButtonAttribs} />
      </div>
      <PageFooter {...props?.footer} />
    </>
  );
};

export default Selectivity;
