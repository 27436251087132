import { useParams } from "react-router-dom";
import { useGetTagSpectraQuery } from "../tagsSlice";

const useTagSpectraBlock = () => {
  const { tagId } = useParams();
  const spectra = useGetTagSpectraQuery({ tagId });

  let spectraIds = [];
  spectraIds = spectra?.data?.map((spectrum) => spectrum?.id);

  const items = [
    {
      id: "spectraArray",
      type: "spectraArray",
      data: spectraIds,
      link: (rowData) => `/spectra/${rowData}`,
    },
    { id: "notes", formOnly: true },
  ];

  return items;
};

export default useTagSpectraBlock;
