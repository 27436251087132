import classNames from "classnames";
import styles from "./heading.module.css";

export const H1 = ({ children, ...rest }) => <h1 {...rest}>{children}</h1>;
export const H2 = ({ children, ...rest }) => <h2 {...rest}>{children}</h2>;
export const H3 = ({ children, ...rest }) => <h3 {...rest}>{children}</h3>;
export const H4 = ({ children, ...rest }) => <h4 {...rest}>{children}</h4>;

export const PageHeading = ({ children, centered, ...rest }) => (
  <H1 className={classNames({ [styles.centered]: centered })} {...rest}>
    {children}
  </H1>
);
export const ArticleHeading = ({ children, centered, ...rest }) => (
  <H4 className={classNames({ [styles.centered]: centered })} {...rest}>
    {children}
  </H4>
);
export const DialogHeading = ({ children, centered, ...rest }) => (
  <H2 className={classNames({ [styles.centered]: centered })} {...rest}>
    {children}
  </H2>
);
export const SectionHeading = ({ children, centered, ...rest }) => (
  <H4 className={classNames({ [styles.centered]: centered })} {...rest}>
    {children}
  </H4>
);
