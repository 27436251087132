import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError } from "../../alerts/alertsSlice";

const useConfirmationModal = ({
  mutationHook,
  navigateOnSuccess,
  params: paramsFromProps,
}) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const navigate = useNavigate();
  const [mutation, mutationProps] = mutationHook();
  const dispatch = useDispatch();

  const onSubmit = async ({ params: paramsFromData }) => {
    try {
      setMutationLoading(true);
      const response = await mutation({
        params: paramsFromData || paramsFromProps,
      });
      if (response?.error) {
        throw response?.error;
      }
      setMutationLoading(false);
      if (typeof navigateOnSuccess === "function") {
        const destination = navigateOnSuccess();
        if (destination != null) {
          navigate(destination);
        }
      }
    } catch (err) {
      setMutationLoading(false);
      dispatch(setError(err));
      console.error(err);
    }
  };

  return {
    formActions: {
      onSubmit,
      isLoading: mutationProps?.isLoading || mutationLoading,
      disabled: mutationProps?.isLoading || mutationLoading,
    },
  };
};

export default useConfirmationModal;
