import { useRef, useState } from "react";
import getDistance from "../../../utils/getDistance";
import useChartSeriesData from "./useChartSeriesData";
import {
  xAxis,
  yAxis,
  xAxisValues,
  yAxisValues,
  getYAxisScaledToHeight,
} from "../chartConfig";

const clickRadius = 2.5;

const useChartSeries = ({
  id,
  color,
  dataPoints,
  labelsOnRight,
  labelsOnLeft,
  labelsOnTop,
  labelsOnBottom,
  viewOnly,
  type,
  size,
}) => {
  const [selectedIdx, setSelectedIdx] = useState();
  const [dragging, setDragging] = useState();
  const { chartPoints, setChartPoints } = useChartSeriesData({
    dataPoints,
    labelsOnRight,
    labelsOnLeft,
    labelsOnTop,
    labelsOnBottom,
    size,
  });
  const timeStampRef = useRef();

  const yAxisScaledToHeight = getYAxisScaledToHeight({
    labelsOnTop,
    labelsOnBottom,
    size,
  });

  const findClosestPoint = ({ relativeX, relativeY }) => {
    let smallestDistance;
    let closestPointIdx = -1;
    if (chartPoints == null)
      return {
        idx: closestPointIdx,
        distance: smallestDistance,
        isInRange: false,
      };
    chartPoints.map((point, idx) => {
      const distance = getDistance(
        point.relativeX,
        point.relativeY,
        relativeX,
        relativeY
      );
      if (smallestDistance != null && distance >= smallestDistance)
        return false;
      smallestDistance = distance;
      closestPointIdx = idx;
      return false;
    });
    return {
      idx: closestPointIdx,
      distance: smallestDistance,
      isInRange: smallestDistance <= clickRadius,
    };
  };

  const handleDrag = ({ relativeY }) => {
    if (selectedIdx == null || selectedIdx < 0) return;
    let smallestDistance;
    let closestYAxisIdx;
    yAxis.map((yAxisValue, yAxisIdx) => {
      if (
        smallestDistance == null ||
        Math.abs(relativeY - yAxisValue) < smallestDistance
      ) {
        smallestDistance = Math.abs(relativeY - yAxisValue);
        closestYAxisIdx = yAxisIdx;
      }
      return false;
    });
    setChartPoints((state) => {
      let newState = [...state];
      newState[selectedIdx] = {
        ...newState[selectedIdx],
        y: yAxisScaledToHeight[closestYAxisIdx],
        relativeY: yAxis[closestYAxisIdx],
        l: yAxisValues[closestYAxisIdx],
        outOfBounds: closestYAxisIdx === yAxisValues.length - 1,
      };
      return newState;
    });
  };

  const setSelectedPoint = (idx) => {
    setSelectedIdx((state) => (state !== idx ? idx : state));
  };

  const addPoint = (newPoint) => {
    const currentTimeStamp = Date.now();
    if (timeStampRef.current && currentTimeStamp - timeStampRef.current < 200) {
      return true;
    }
    if (newPoint == null) return false;
    const f = xAxisValues[xAxis.indexOf(newPoint.relativeX)];
    const l = yAxisValues[yAxis.indexOf(newPoint.relativeY)];
    if (type === "bc" && (f < 250 || f > 4000)) return false;
    if (chartPoints == null) {
      setChartPoints([
        {
          ...newPoint,
          f,
          l,
          outOfBounds: newPoint.relativeY === 100,
        },
      ]);
      return true;
    }
    const idxOnSameX = chartPoints.findIndex(
      (currentPoint) => currentPoint.relativeX === newPoint.relativeX
    );
    if (idxOnSameX > -1) return false;
    setChartPoints((state) => {
      let dataPoints = [...state];
      const idxOnSameX = dataPoints.findIndex(
        (currentPoint) => currentPoint.relativeX === newPoint.relativeX
      );
      if (idxOnSameX < 0) {
        dataPoints.push({
          ...newPoint,
          f,
          l,
          outOfBounds: newPoint.relativeY === 100,
        });
        dataPoints = dataPoints.sort((a, b) => (a.x < b.x ? 1 : -1));
      }
      return dataPoints;
    });
    return true;
  };

  const removePoint = (point) => {
    timeStampRef.current = Date.now();
    setChartPoints((state) => {
      const dataPoints = state.filter(
        (oldPoint) =>
          !(
            point.relativeX === oldPoint.relativeX &&
            point.relativeY === oldPoint.relativeY
          )
      );
      return dataPoints;
    });
    return true;
  };

  const clearAll = () => {
    setChartPoints([]);
  };

  return {
    chartSeries: {
      id,
      color,
      points: chartPoints,
      selectedPoint: selectedIdx,
    },
    chartSeriesHandlers: {
      findClosestPoint,
      setSelectedPoint: viewOnly ? () => {} : setSelectedPoint,
      handleDrag: viewOnly ? () => {} : handleDrag,
      dragging,
      setDragging: viewOnly ? () => {} : setDragging,
      addPoint: viewOnly ? () => {} : addPoint,
      removePoint: viewOnly ? () => {} : removePoint,
      clearAll: viewOnly ? () => {} : clearAll,
    },
  };
};

export default useChartSeries;
