import useSideLabel from "./hooks/useSideLabel";
import styles from "./axisLabel.module.css";

const SideLabel = ({ ...rest }) => {
  const sideLabel = useSideLabel({
    ...rest,
  });

  return (
    <div
      className={styles.axisLabel}
      style={{
        left: sideLabel.left,
        top: sideLabel.top,
      }}
    >
      <h2>{sideLabel.label}</h2>
    </div>
  );
};

export default SideLabel;
