import useNavigation from "./hooks/useNavigation";
import Button from "../../components/Button";
import { P2 } from "../../components/Paragraph";
import classNames from "classnames";
import styles from "./sideNav.module.css";

const SideNav = ({ expanded }) => {
  const { sections } = useNavigation();
  return (
    <div className={styles.sideNav}>
      {expanded ? (
        <img
          key="logotype"
          src={"/logotype.png"}
          alt={"logo"}
          className={styles.logo}
        />
      ) : (
        <img
          key="logo"
          src={"/logo.png"}
          alt={"logo"}
          className={classNames(styles.logo, { [styles.collapsed]: !expanded })}
        />
      )}

      <nav>
        {sections.map((section, idx) => (
          <div key={`${section.id}${section.title ? section.title : ""}`}>
            <hr />
            {section?.title ? (
              <div style={{ marginBottom: "var(--gap-base)" }}>
                <P2>{section?.title}</P2>
              </div>
            ) : null}
            {section?.links?.map((link) => (
              <Button
                sideNav
                pressed={link.selected}
                key={link.to}
                icon={link.icon}
                to={link.to}
              >
                {expanded ? link.caption : null}
              </Button>
            ))}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default SideNav;
