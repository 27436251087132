import { useEffect } from "react";
import useMultiSelector from "../../../hooks/useMultiSelector";
import useLang from "../../../hooks/useLang";

const useFormMultiselector = ({
  id,
  initialValue,
  options,
  onChange: handleChange,
  idAsValue,
}) => {
  const lang = useLang();

  const getOptionWithIdAndLabel = (option, idx) => {
    if (typeof option === "object" && idAsValue ? option.id : option.label) {
      const result = {
        id:
          option.id ||
          `${option.slice(0, Math.min(3, option.label.length))}_${idx}`,
        label: option.label || option.id,
      };
      return result;
    } else if (typeof option === "string") {
      return {
        id: idAsValue
          ? option
          : `${option.slice(0, Math.min(3, option.length))}_${idx}`,
        label: option,
      };
    } else {
      return { id: idAsValue ? option : idx, label: option };
    }
  };

  const getSelectorOptions = (options) => {
    let selectorOptions = [];
    if (Array.isArray(options)) {
      selectorOptions = options.map((option, idx) => {
        return getOptionWithIdAndLabel(option, idx);
      });
    }
    return selectorOptions;
  };

  const getValueWithId = (value, options) => {
    const valueArray = Array.isArray(value) ? [...value] : [value];
    let i = 0;
    const result = valueArray?.map((value) => {
      if (typeof value === "string") {
        const correspondingOption = options?.find((option) =>
          idAsValue ? option.id === value : option.label === value
        );
        if (correspondingOption != null) return correspondingOption;
        const valueWithId = getOptionWithIdAndLabel(value, options.length + i);
        i++;
        return valueWithId;
      }
      return value;
    });
    return result;
  };

  const selector = useMultiSelector({
    options: getSelectorOptions(options),
    defaultValue:
      initialValue != null
        ? getValueWithId(initialValue, getSelectorOptions(options))
        : undefined,
  });

  useEffect(() => {
    if (options && options?.length > 0) {
      selector.reset(getSelectorOptions(options), selector.value);
    }
    // eslint-disable-next-line
  }, [options && options?.length]);

  useEffect(() => {
    if (initialValue != null && options?.length > 0 /* was 1 */) {
      selector.setValue(() => {
        const initialValueArray = getValueWithId(
          initialValue,
          getSelectorOptions(options)
        );
        const newState = initialValueArray;
        return newState;
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(initialValue), options && options?.length]);

  useEffect(() => {
    if (typeof handleChange === "function") {
      const newValue = {
        id: id,
        value: selector.value
          ?.map((value) => (idAsValue ? value?.id : value?.label))
          .filter((value) => value != null),
      };
      handleChange(newValue);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(selector.value)]);

  return {
    key: id,
    label: lang(`captions.${id}`),
    type: "text",
    value: selector.value,
    options: selector.options,
    onChange: selector.onChange,
    ...selector.attributes,
  };
};

export default useFormMultiselector;
