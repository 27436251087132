import useOrganisationId from "../../organisations/hooks/useOrganisationId";
import useStaff from "./useStaff";
import useLang from "../../../hooks/useLang";
import useStaffBlock from "./useStaffBlock";
import useOwnerBlock from "./useOwnerBlock";
import useModifierModal from "../../forms/hooks/useModifierModal";
import useConfirmationModal from "../../forms/hooks/useConfirmationModal";
import {
  usePostStaffMutation,
  useRemoveStaffMutation,
  usePatchStaffMutation,
} from "../staffSlice";

const useStaffPage = () => {
  const organisationId = useOrganisationId();
  const lang = useLang();
  const staff = useStaff();

  const { formAttribs, formActions } = useModifierModal({
    mutationHook: usePostStaffMutation,
    formConfigHook: useStaffBlock,
    params: { id: organisationId },
  });
  const { formAttribs: patchAttribs, formActions: patchActions } =
    useModifierModal({
      mutationHook: usePatchStaffMutation,
      formConfigHook: useOwnerBlock,
      params: { id: organisationId },
    });
  const { formActions: removeFormActions } = useConfirmationModal({
    mutationHook: useRemoveStaffMutation,
  });

  const data = staff?.data?.map((user) => ({
    ...user,
    full_name: `${user?.given_name} ${user?.family_name}`,
  }));

  const config = useStaffBlock();

  const removeStaff = {
    id: "change",
    buttons: [
      {
        icon: "trashIcon",
        type: "confirmation-modal",
        mutation: "organisations.id.users.id.DELETE",
        deemphasize: true,
        organisation: organisationId,
        hide: (data) =>
          data?.organisation_roles?.indexOf("ORGANISATION_OWNER") > -1,
        confirmationText: (data) =>
          `${lang("common.delete")} ${data?.full_name}?`,
        params: (data) => ({ organisationId, userId: data?.id }),
        ...removeFormActions,
      },
      {
        icon: "pencil",
        type: "modifier-modal",
        mutation: "organisations.id.users.id.PATCH",
        deemphasize: true,
        organisation: organisationId,
        hide: (data) =>
          data?.organisation_roles?.indexOf("ORGANISATION_OWNER") < 0,
        params: (data) => ({ organisationId, userId: data?.id }),
        formAttribs: patchAttribs,
        ...patchActions,
      },
    ],
  };

  const page = {
    hasAccess: staff?.hasAccess,
    header: {
      title: staff.isLoading
        ? lang("staff.staff_loading")
        : lang("staff.title"),
      buttons: {
        caption: lang("staff.create"),
        icon: "plus",
        title: lang("staff.create"),
        type: "modifier-modal",
        mutation: "organisations.id.users.POST",
        organisation: organisationId,
        formAttribs,
        ...formActions,
      },
    },
    items: {
      id: "staffTable",
      type: "table",
      data,
      config: [...config, removeStaff],
    },
  };

  return page;
};

export default useStaffPage;

/*
        {
          caption: lang("common.delete"),
          type: "confirmation-modal",
          mutation: "conclusions.id.DELETE",
          deemphasize: true,
          confirmationText: `${lang("common.delete")} ${
            conclusion?.data?.name
              ? '"' + conclusion?.data?.name + '"'
              : lang("conclusion.title")
          }?`,
          ...deleteFormActions,
        },
*/
