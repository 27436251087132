import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import styles from "./popup.module.css";

const PopupContent = ({
  isOpen,
  closePopup,
  children,
  renderOpen,
  expandRight,
  expandBottomRight,
  expandLeft,
  expandBottomLeft,
  keepOpen,
  dropdown,
  fitContent,
  topBar,
  popupRef,
  containerRef,
}) => {
  const recalculatePosition = () => {
    if (isOpen && containerRef.current && popupRef.current) {
      const fullWidth = document.documentElement.scrollWidth;
      const anchorRect = containerRef.current.getBoundingClientRect();
      if (expandLeft || expandRight) {
        popupRef.current.style.top = `${anchorRect.top}px`;
        if (expandLeft) {
          popupRef.current.style.right = `calc(${
            fullWidth - anchorRect.right
          }px - calc(var(--spacing-1))`;
          popupRef.current.style.left = "unset";
        } else {
          popupRef.current.style.left = `calc(${anchorRect.left}px + calc(var(--spacing-1))`;
          popupRef.current.style.right = "unset";
        }
      } else {
        popupRef.current.style.top = `calc(var(--spacing-1) + ${anchorRect.bottom}px)`;
        popupRef.current.style.right = `${fullWidth - anchorRect.right}px`;
        if (expandBottomLeft) {
          popupRef.current.style.left = "unset";
        } else {
          popupRef.current.style.left = `${anchorRect.left}px`;
        }
      }
    }
  };

  const handleWindowChanges = () => {
    closePopup();
  };

  useEffect(() => {
    recalculatePosition();
    // eslint-disable-next-line
  }, [isOpen, containerRef]);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowChanges);
    window.addEventListener("resize", handleWindowChanges);
    return () => {
      window.removeEventListener("scroll", handleWindowChanges);
      window.addEventListener("resize", handleWindowChanges);
    };
    // eslint-disable-next-line
  }, []);

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.popupContainer, {
        [styles.expandRight]: expandRight,
        [styles.expandLeft]: expandLeft,
        [styles.expandBottomRight]: expandBottomRight,
        [styles.expandBottomLeft]: expandBottomLeft,
        [styles.dropdownContainer]: dropdown,
        [styles.fitContent]: fitContent,
        [styles.topBar]: topBar,
      })}
      ref={popupRef}
    >
      {children}
    </div>,
    document.getElementById("popup")
  );
};

const Popup = ({
  children,
  renderOpen,
  expandRight,
  expandBottomRight,
  expandLeft,
  expandBottomLeft,
  keepOpen,
  dropdown,
  fitContent,
  topBar,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const popupRef = useRef(null);
  const location = useLocation();

  const togglePopup = (event) => {
    if (event) {
      if (typeof event.preventDefault === "function") event.preventDefault();
    }
    setIsOpen((state) => !state);
  };

  const closePopup = () => {
    setIsOpen((state) => (state === false ? state : false));
  };

  const handleClickOutside = (event) => {
    if (containerRef.current) {
      if (containerRef.current.contains(event.target)) {
        event.preventDefault();
        if (
          popupRef.current &&
          popupRef.current.contains(event.target) &&
          dropdown
        ) {
          if (!keepOpen) closePopup();
        }
      } else {
        closePopup();
      }
    } else if (popupRef.current) {
      if (popupRef.current.contains(event.target)) {
        event.preventDefault();
      } else {
        closePopup();
      }
    }
  };

  useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={classNames(styles.popup, {
        [styles.dropdown]: dropdown,
        [styles.topBar]: topBar,
      })}
      style={{ width: "100%" }}
      ref={containerRef}
      {...rest}
    >
      {typeof renderOpen === "function"
        ? renderOpen((event) => {
            togglePopup(event);
          })
        : null}
      {isOpen ? (
        <PopupContent
          {...{
            expandRight,
            expandLeft,
            expandBottomRight,
            expandBottomLeft,
            dropdown,
            fitContent,
            topBar,
          }}
          containerRef={containerRef}
          popupRef={popupRef}
          isOpen={isOpen}
          closePopup={closePopup}
        >
          {children}
        </PopupContent>
      ) : null}
    </div>
  );
};

export default Popup;
