import { apiSlice } from "../api/apiSlice";

export const participantsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getParticipants: builder.query({
      query: ({ id }) => `/organisations/${id}/participants`,
      providesTags: (result, error, arg) => [
        { type: "Participant", id: arg?.id },
      ],
    }),
    postParticipant: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/organisations/${params?.id}/participants`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Participant" }],
    }),
  }),
});

export const { useGetParticipantsQuery, usePostParticipantMutation } =
  participantsSlice;
