import {
  PageHeading,
  ArticleHeading,
  SectionHeading,
  DialogHeading,
} from "./Heading";
import { PageHeaderButtons, ArticleHeaderButtons } from "./HeaderButtons";
import LastEdit from "./LastEdit";
import styles from "./header.module.css";

export const PageHeader = ({
  title,
  left_buttons,
  buttons,
  lastEdit,
  carriedOutBy,
  centered,
  ...rest
}) => {
  return (
    <header className={styles.pageHeader} {...rest}>
      <div className={styles.titleContainer}>
        {left_buttons && <PageHeaderButtons buttons={left_buttons} />}
        <PageHeading centered={centered}>{title}</PageHeading>
        <PageHeaderButtons buttons={buttons} />
      </div>
      {lastEdit != null ? <LastEdit {...lastEdit} /> : null}
      {carriedOutBy != null ? <LastEdit {...carriedOutBy} carriedOutBy/> : null}
    </header>
  );
};

export const ArticleHeader = ({ title, buttons, centered, ...rest }) => {
  return (
    <header className={styles.articleHeader} {...rest}>
      <div className={styles.titleContainer}>
        <ArticleHeading centered={centered}>{title}</ArticleHeading>
        <ArticleHeaderButtons buttons={buttons} />
      </div>
    </header>
  );
};

export const SectionHeader = ({ title, centered, ...rest }) => {
  return (
    <header className={styles.sectionHeader} {...rest}>
      <div className={styles.titleContainer}>
        <SectionHeading centered={centered}>{title}</SectionHeading>
      </div>
    </header>
  );
};

export const DialogHeader = ({ title, centered, ...rest }) => {
  return (
    <header className={styles.dialogHeader} {...rest}>
      <DialogHeading centered={centered}>{title}</DialogHeading>
    </header>
  );
};

export const AlertDialogHeader = ({ title, centered, ...rest }) => {
  return (
    <header className={styles.alertDialogHeader} {...rest}>
      <DialogHeading centered={centered}>{title}</DialogHeading>
    </header>
  );
};
