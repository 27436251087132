import React from "react";
import styles from "./fieldset.module.css";

const FieldSet = ({ children, header, formRows, ...rest }) => {
  return (
    <fieldset className={styles.fieldset} {...rest}>
      {header?.title ? (
        <span>
          <legend className={styles.legend}>{header.title}</legend>
        </span>
      ) : null}
      {children}
    </fieldset>
  );
};
export default FieldSet;
