import UserBadge from "../../components/UserBadge";
import Button from "../../components/Button";
import Popup from "../../components/Popup";
import useLang from "../../hooks/useLang";
import useAuth from "../auth/hooks/useAuth";

const UserMenu = () => {
  const lang = useLang();
  const { getLogoutUrl } = useAuth();
  return (
    <div>
      <Popup
        dropdown
        fitContent
        expandBottomLeft
        renderOpen={(onClick) => (
          <UserBadge roundelOnly large onClick={onClick} />
        )}
      >
        <Button
          dropdownSelector
          icon={"exit"}
          onClick={() => {
            window.location.href = getLogoutUrl();
          }}
        >
          {lang("auth.logout")}
        </Button>
      </Popup>
    </div>
  );
};

export default UserMenu;
