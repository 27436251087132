import { acFrequencies, bcFrequencies } from "../../spectrum/chartConfig";
import useLang from "../../../hooks/useLang";

const useTestWizzardConfig = () => {
  const lang = useLang();

  const getDynamicSteps = ({ testType, side, frequencyArray }) => {
    if (Array.isArray(frequencyArray)) {
      const reversedArray = [...frequencyArray];
      reversedArray.reverse();
      return reversedArray.map((frequency) => ({
        id: `${testType}${side != null ? "_" + side : ""}_${frequency}`,
        title: lang(`test.${testType}_${side}`),
        type: testType,
        testType: testType,
        side,
        frequency,
      }));
    } else return undefined;
  };

  const getLateralitySteps = ({ frequencyArray }) => {
    if (Array.isArray(frequencyArray)) {
      return frequencyArray.map((frequency) => ({
        id: `laterality${frequency}`,
        title: lang("test.laterality"),
        type: "laterality",
        frequency,
      }));
    } else return undefined;
  };

  return {
    steps: [
      {
        id: "new_test",
        type: "test_landing",
      },
      {
        id: "testing_sequence",
        steps: [
          /*
          {
            id: "ac_left_instructions",
            type: "instructions",
          },
          */
          {
            id: "testing",
            steps: getDynamicSteps({
              testType: "ac",
              side: "left",
              frequencyArray: acFrequencies,
            }),
          },
          {
            id: "ac_left",
            type: "partial_overview",
            side: "left",
            testType: "ac",
          },
        ],
      },
      {
        id: "testing_sequence",
        steps: [
          /*
          {
            id: "ac_right_instructions",
            type: "instructions",
          },
          */
          {
            id: "testing",
            steps: getDynamicSteps({
              testType: "ac",
              side: "right",
              frequencyArray: acFrequencies,
            }),
          },
          {
            id: "ac_right",
            type: "partial_overview",
            side: "right",
            testType: "ac",
          },
        ],
      },
      {
        id: "testing_sequence",
        steps: [
          /*
          {
            id: "bc_left_instructions",
            type: "instructions",
          },
          */
          {
            id: "testing",
            steps: getDynamicSteps({
              testType: "bc",
              side: "left",
              frequencyArray: bcFrequencies,
            }),
          },
          {
            id: "bc_left",
            type: "partial_overview",
            side: "left",
            testType: "bc",
          },
        ],
      },
      {
        id: "testing_sequence",
        steps: [
          /*
          {
            id: "bc_right_instructions",
            type: "instructions",
          },
          */
          {
            id: "testing",
            steps: getDynamicSteps({
              testType: "bc",
              side: "right",
              frequencyArray: bcFrequencies,
            }),
          },
          {
            id: "bc_right",
            type: "partial_overview",
            side: "right",
            testType: "bc",
          },
        ],
      },
      {
        id: "testing_sequence",
        steps: getLateralitySteps({
          frequencyArray: [4000, 2000, 1000, 500, 250],
        }),
      },
      {
        id: "testing_sequence",
        steps: getDynamicSteps({
          testType: "selectivity",
          side: "left",
          frequencyArray: acFrequencies,
        }),
      },
      {
        id: "testing_sequence",
        steps: getDynamicSteps({
          testType: "selectivity",
          side: "right",
          frequencyArray: acFrequencies,
        }),
      },
      {
        id: "results",
        type: "overview",
      },
    ],
  };
};

export default useTestWizzardConfig;
