import { usePatchConclusionMutation } from "../conclusionsSlice";

const usePatchRules = () => {
  const [mutation, mutationProps] = usePatchConclusionMutation();

  const modifiedMutation = async ({ body, params }) => {
    const keys = Object.keys(body);
    let rules = [];
    let i;
    for (i = 0; i < keys.length / 2; i++) {
      rules.push({
        parameter: "AGE",
        operator: { [body[`${i}___operator`]]: body[`${i}___operatorValue`] },
      });
    }
    return mutation({ body: { rules }, params });
  };

  return [modifiedMutation, mutationProps];
};

export default usePatchRules;
