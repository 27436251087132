import { useEffect } from "react";
import FormInput from "./FormInput";

const FormRow = ({
  onChange,
  formData,
  pageOnly,
  value: valueFromProps,
  ...rest
}) => {
  let value;
  if (typeof valueFromProps === "function") {
    value = valueFromProps(rest?.data);
  } else {
    value = valueFromProps;
  }

  useEffect(() => {
    if (typeof valueFromProps === "function") {
      const value = valueFromProps(rest?.data);
      onChange({ id: rest?.id, value });
    }
    // eslint-disable-next-line
  }, []);

  if (pageOnly === true) return null;
  return (
    <FormInput
      onChange={onChange}
      formData={formData}
      value={value}
      {...rest}
    />
  );
};

export default FormRow;
