import useAssessments from "./useAssessments";
import useLang from "../../../hooks/useLang";
import useOrganisationId from "../../organisations/hooks/useOrganisationId";

const useAssessmentsList = () => {
  const lang = useLang();
  const organisationId = useOrganisationId();
  const assessments = useAssessments();

  const data = assessments?.data?.map((assessment) => ({
    ...assessment,
    user: {
      ...assessment?.user,
      full_name: `${assessment?.user?.given_name} ${assessment?.user?.family_name}`,
    },
    participant: {
      ...assessment?.participant,
      gender: lang(`captions.${assessment?.participant?.gender}_short`),
    },
  }));

  const page = {
    hasAccess: assessments?.hasAccess,
    header: {
      title: assessments?.isLoading
        ? lang("assessments.assessments_loading")
        : lang("assessments.title"),
      /*
      buttons: [
        {
          caption: lang("assessments.new"),
          to: "/assessments/new",
        },
      ],
      */
    },
    items: [
      {
        id: "assessmentTable",
        type: "table",
        data: data,
        config: [
          {
            id: "date_created",
            header: { title: lang("assessments.assessment_date") },
            link: (data) => `/${organisationId}/assessments/${data?.id}`,
            width: 56,
          },
          {
            id: "participant.iid",
            width: 32,
          },
          {
            id: "participant.year_born",
            width: 32,
          },
          {
            id: "participant.gender",
            width: 16,
          },
          {
            id: "user.full_name",
            header: { title: lang("common.carried_out_by") },
            width: 72,
          },
        ],
      },
    ],
  };
  return page;
};

export default useAssessmentsList;
