import { useParams } from "react-router-dom";
import useGetSpectrum from "./useGetSpectrum";
import useTags from "../../tags/hooks/useTags";
import useSpectrumTags from "./useSpectrumTags";

const useSpectrumTagsBlock = () => {
  const { spectrumId } = useParams();
  const spectrum = useGetSpectrum({ id: spectrumId });
  const tags = useTags();
  const { options } = useSpectrumTags();

  let filteredTags = [];
  if (tags?.data && spectrum?.data) {
    filteredTags = tags.data.filter((tag) =>
      Array.isArray(spectrum?.data?.tag_ids)
        ? spectrum?.data?.tag_ids.indexOf(tag.id) > -1
        : false
    );
  }

  const items = [
    {
      id: "name",
      data: filteredTags,
      value: filteredTags.map((feature) => ({
        label: feature.name,
        id: feature.id,
      })),
      type: "list",
      idAsValue: true,
      options,
    },
  ];

  return items;
};

export default useSpectrumTagsBlock;
