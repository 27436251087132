import { useParams } from "react-router-dom";
import useGetSpectrum from "./useGetSpectrum";
import useTags from "../../tags/hooks/useTags";
import getOptions from "../../../utils/getOptions";
import toRemove from "../../../utils/getItemsToRemove";
import toAdd from "../../../utils/getItemsToAdd";

const useSpectrumTags = () => {
  const { spectrumId } = useParams();
  const spectrum = useGetSpectrum({ id: spectrumId });
  const tags = useTags();

  const options = getOptions(tags?.data, "name");

  const getItemsToAdd = (newList) => toAdd(spectrum?.data?.tag_ids, newList);
  const getItemsToRemove = (newList) =>
    toRemove(spectrum?.data?.tag_ids, newList);

  return {
    tags: tags?.data,
    options,
    getItemsToAdd,
    getItemsToRemove,
  };
};

export default useSpectrumTags;
