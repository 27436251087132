import useLang from "../../../hooks/useLang";
import useDiagnoses from "./useDiagnoses";

const useDiagnosesList = () => {
  const lang = useLang();
  const diagnoses = useDiagnoses();

  const page = {
    hasAccess: diagnoses?.hasAccess,
    header: {
      title: lang("diagnoses.title"),
    },
    items: {
      id: "diagnosesTable",
      type: "table",
      data: diagnoses?.data,
      config: [
        {
          id: "name",
        },
      ],
    },
  };

  return page;
};

export default useDiagnosesList;
