import SpectrumPreview from "./SpectrumPreview";
import Button from "../../components/Button";
import styles from "./spectraArray.module.css";

const SpectraArray = ({ data, ...rest }) => {
  const content = data?.map((spectrum_id) => {
    const spectrumPreview = (
      <SpectrumPreview
        key={spectrum_id}
        id={spectrum_id}
        viewOnly
        size={"small"}
      />
    );
    if (typeof rest?.link === "function") {
      return (
        <Button key={spectrum_id} to={rest.link(spectrum_id)} inline>
          {spectrumPreview}
        </Button>
      );
    }
    return spectrumPreview;
  });

  return <div className={styles.spectraArray}>{content}</div>;
};

export default SpectraArray;
