import Button from "../../components/Button";
import { P2 } from "../../components/Paragraph";

const LateralityButton = ({ sub, ...rest }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "var(--gap-base)",
        gap: "var(--gap-base)",
      }}
    >
      <Button large {...rest} />
      <P2>{sub}</P2>
    </div>
  );
};

export default LateralityButton;
