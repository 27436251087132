import { Outlet, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import usePersistLogin from "../hooks/usePersistLogin";
import Loader from "./Loader";

const PersistLogin = () => {
  const { token, user, refreshToken } = usePersistLogin();
  const location = useLocation();

  return (
    <>
      {refreshToken ? (
        <>{user && token ? <Outlet /> : <Loader />}</>
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
};

export default PersistLogin;
