import useLang from "../../../hooks/useLang";
import { useParams } from "react-router-dom";
import useParticipant from "./useParticipant";
import useParticipants from "./useParticipants";
//import useDiagnoses from "../../diagnoses/hooks/useDiagnoses";

const useParticipantBlock = (participantIdFromProps) => {
  const lang = useLang();
  const { participantId } = useParams();
  const participant = useParticipant({
    id: participantIdFromProps || participantId,
  });
  const participants = useParticipants();
  const iids = participants?.data?.map((participant) => participant.iid);
  
  /*
  const diagnoses = useDiagnoses();

  const participantDiagnoses = participant?.data?.known_conditions?.map(
    (condition) => {
      return diagnoses?.data?.find((diagnosis) => diagnosis.id === condition);
    }
  );

  const diagnosesOptions = diagnoses?.data?.map((diagnosis) => ({
    id: diagnosis.id,
    label: diagnosis.name,
  }));
*/

  const items = [
    {
      id: "iid",
      value: participant?.data?.iid,
      required: true,
      inputWidth: "half",
      isValid: (data) => {
        if (data === "") return true;
        const idx = iids.indexOf(data);
        return idx < 0;
      },
    },
    {
      id: "year_born",
      value: participant?.data?.year_born,
      required: true,
      inputWidth: "quarter",
      isValid: (data) => {
        if (data === "") return true;
        const yearBorn = parseInt(data) || 0;
        const currentYear = parseInt(new Date().getFullYear());
        return yearBorn > 1900 && yearBorn <= currentYear;
      },
    },
    {
      id: "gender",
      options: [
        { id: "M", label: lang("captions.m") },
        { id: "F", label: lang("captions.f") },
      ],
      value: {
        id: participant?.data?.gender,
        label: lang(`captions.${participant?.data?.gender}`),
      },
      displayValue: lang(`captions.${participant?.data?.gender}`),
      type: "chip-radio",
      required: true,
      idAsValue: true,
    },
    /*
    {
      id: "diagnoses",
      type: "multiselector",
      value: participantDiagnoses?.map((diagnosis) => ({
        id: diagnosis.id,
        label: diagnosis.name,
      })),
      displayValue: participantDiagnoses
        ?.map((diagnosis) => diagnosis.name)
        .join(", "),
      options: diagnosesOptions,
    },
    */
  ];

  return items;
};

export default useParticipantBlock;
