const lang = {
  LT: {
    common: {
      ok: "Gerai",
      cancel: "Atšaukti",
      yes: "Taip",
      no: "Ne",
      submit: "Pateikti",
      close: "Uždaryti",
      dismiss: "Atmesti",
      error: "Klaida",
      warning: "Įspėjimas",
      caution: "Dėmesio",
      add: "Pridėti",
      create: "Sukurti",
      delete: "Ištrinti",
      edit: "Redaguoti",
      loading: "Kraunama...",
      searching: "Ieškoma...",
      preparing: "Ruošiama...",
      uploading: "Įkeliama...",
      processing: "Apdorojama...",
      being_replaced: "Perrašoma...",
      no_matches_found: "Nerasta atitikmenų",
      created_by: "Sukūrė",
      carried_out_by: "Atliko",
      search: "Ieškoti",
      no_access: "Vartotojas neturi priėjimo prie šių duomenų.",
      last_edit: "Paskutinį kartą keitė",
      today: "Šiandien",
      yesterday: "Vakar",
      age: "Amžius",
      clear: "Išvalyti",
      ac: "AC",
      bc: "BC (Ctrl)",
      delete_point: "Ištrinti tašką (Alt)",
    },
    captions: {
      id: "ID",
      iid: "Dalyvio kodas",
      code: "Kodas",
      comment: "Komentaras",
      description: "Aprašymas",
      diagnoses: "Diagnozės",
      given_name: "Vardas",
      family_name: "Pavardė",
      full_name: "Vardas Pavardė",
      email: "El. paštas",
      primary_email: "El. paštas",
      name: "Pavadinimas",
      roles: "Rolės",
      organisation_roles: "Rolės",
      level1: "Lygis 1",
      level2: "Lygis 2",
      level3: "Lygis 3",
      destination: "Paskirtis",
      aspect: "Aspektas",
      feature: "Ypatybė",
      age: "Amžius",
      gender: "Lytis",
      m: "V (vyras)",
      f: "M (moteris)",
      m_short: "V",
      f_short: "M",
      male_under_18: "V (vyras)",
      female_under_18: "M (moteris)",
      left: "Kairė",
      right: "Dešinė",
      back: "Galas",
      front: "Priekis",
      could_not_tell: "Nežino",
      correct: "Teisingai",
      error: "Klaida",
      owner: "Savininkas",
      year_born: "Gimimo metai",
      change: "Keisti",
      delete: "Ištrinti",
      select_from_list: "Pasirinkti",
      notes: "Pastabos",
      rename: "Pervadinti",
    },
    conclusions: {
      title: "Išvados",
      conclusions_loading: "Išvados kraunasi...",
      conclusion_loading: "Išvada kraunasi...",
      create: "Sukurti išvadą",
      edit_features_list: "Keisti ypatybių sąrašą",
      edit_conclusion: "Redaguoti išvadą",
      edit_rules: "Redaguoti taisykles",
      descriptions: "Išvados tekstas",
      rules: "Taisyklės",
      destination: "Išvados paskirtis",
      generic: "Bendras",
      neurological: "Neurologinis",
      physiological: "Fiziologinis",
      pedagogical: "Pedagoginis",
      psychological: "Psichologinis",
    },
    spectra: {
      title: "Pavyzdžiai",
      examples: "Pavyzdžiai",
      example: "Pavyzdys",
      example_loading: "Pavyzdys kraunasi...",
      spectra_loading: "Pavyzdžiai kraunasi...",
      edit_features_list: "Keisti ypatybių sąrašą",
      edit_tags_list: "Keisti žymių sąrašą",
      edit_notes: "Keisti pastabas",
      edit_name: "Keisti pavadinimą",
    },
    features: {
      title: "Ypatybės",
      features_loading: "Ypatybės kraunasi...",
      feature_loading: "Ypatybė kraunasi...",
      create: "Sukurti ypatybę",
      edit_feature: "Redaguoti ypatybę",
      edit_conclusions_list: "Keisti išvadų sąrašą",
    },
    test: {
      title: "Testavimas",
      new_test: "Naujas testas",
      ac_left_instructions: "Kairės ausies testavimas ausinėmis",
      ac_right_instructions: "Dešinės ausies testavimas ausinėmis",
      ac_left: "Kairės ausies testavimas ausinėmis",
      ac_right: "Dešinės ausies testavimas ausinėmis",
      bc_left_instructions: "Kairės ausies testavimas kaulo detektoriumi",
      bc_right_instructions: "Dešinės ausies testavimas kaulo detektoriumi",
      bc_left: "Kairės ausies testavimas kaulo detektoriumi",
      bc_right: "Dešinės ausies testavimas kaulo detektoriumi",
      selectivity: "Selectivity testas",
      selectivity_left: "Kairės ausies selectivity testas",
      selectivity_right: "Dešinės ausies selectivity testas",
      laterality: "Laterality testas",
      results: "Rezultatai",
      start: "Pradėti",
      quit: "Išeiti",
      skip: "Praleisti testą",
      next: "Toliau",
      next_test: "Sekantis testas",
      submit: "Siųsti rezultatus",
      did_not_hear: "Neišgirdo",
    },
    assessments: {
      title: "Testai",
      assessment_loading: "Testas kraunasi...",
      assessments_loading: "Testai kraunasi...",
      new: "Naujas testas",
      assessment_id: "Testo ID",
      assessment_date: "Atlikimo data",
      start_assessment: "Testuoti",
    },
    participants: {
      title: "Testuojamieji",
      participant_loading: "Testuojamasis kraunasi...",
      participants_loading: "Testuojamieji kraunasi...",
      create: "Sukurti testuojamąjį",
      edit_participant: "Keisti testuojamojo informaciją",
    },
    diagnoses: {
      title: "Diagnozės",
    },
    users: {
      title: "Vartotojai",
      users_loading: "Vartotojai kraunasi...",
      create: "Sukurti vartotoją",
    },
    organisations: {
      title: "Organizacijos",
      organisations_loading: "Organizacijos kraunasi...",
      organisation_loading: "Organizacija kraunasi...",
      create: "Sukurti Organizaciją",
    },
    staff: {
      title: "Darbuotojai",
      staff_loading: "Darbuotojai kraunasi...",
      create: "Sukurti darbuotoją",
    },
    tags: {
      title: "Žymės",
      create: "Sukurti žymę",
    },
    auth: {
      login: "Prisijungti",
      logout: "Atsijungti",
      username: "Vartotojo vardas",
      password: "Slaptažodis",
    },
  },
};

const getTranslation = ({ translations, stringArray }) => {
  if (translations == null) return null;
  if (Array.isArray(stringArray)) {
    if (stringArray.length > 1) {
      return getTranslation({
        translations: translations[stringArray[0].toLocaleLowerCase()],
        stringArray: stringArray.slice(1),
      });
    } else if (stringArray.length === 1) {
      return translations[stringArray[0].toLocaleLowerCase()];
    } else {
      return null;
    }
  }
  return null;
};

const useLang = () => {
  const onTranslate = (textToTranslate) => {
    const textArray = textToTranslate.split(".");
    const idxOfUndefined = textArray.findIndex(
      (string) => string === "undefined"
    );
    if (idxOfUndefined > -1) return "-";
    if (typeof textToTranslate !== "string") return "";
    const result = getTranslation({
      translations: lang.LT,
      stringArray: textToTranslate.split("."),
    });
    if (result == null) return textToTranslate;
    return result;
  };
  return onTranslate;
};

export default useLang;
