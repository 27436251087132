import { SectionHeader } from "./Header";
import SectionItem from "./SectionItem";
import FormInput from "../features/forms/FormInput";
import { SectionButtons } from "./HeaderButtons";
import classNames from "classnames";
import styles from "./section.module.css";

export const SectionContainer = ({
  items,
  formItems,
  buttons,
  direction,
  ...rest
}) => {
  return (
    <div
      className={classNames(styles.sectionContainer, styles[direction])}
      {...rest}
    >
      {Array.isArray(formItems)
        ? formItems.map((formItem) => (
            <FormInput key={formItem.id} {...formItem} />
          ))
        : null}
      {items ? (
        Array.isArray(items) ? (
          items?.map((sectionItem, idx) => {
            if (sectionItem.formItem === true) {
              return <FormInput key={sectionItem.id} {...sectionItem} />;
            } else if (sectionItem.button === true) {
              return (
                <SectionButtons
                  key={sectionItem.id || sectionItem.caption || `btn-${idx}`}
                  buttons={sectionItem}
                  withLabel={idx === 0}
                />
              );
            }
            return <SectionItem key={sectionItem?.id} {...sectionItem} />;
          })
        ) : (
          <>
            {items.formItem === true ? (
              <FormInput key={items.id} {...items} />
            ) : (
              <SectionItem key={items?.id} {...items} />
            )}
          </>
        )
      ) : null}
      {buttons ? <SectionButtons buttons={buttons} /> : null}
    </div>
  );
};

const Section = ({ header, items, buttons, formItems, direction, ...rest }) => {
  let itemArray = items;
  if (!Array.isArray(itemArray)) {
    if (items?.id) {
      itemArray = [items];
    } else {
      itemArray = [];
    }
  }
  const itemsToDisplay = itemArray?.filter((item) => !item.formOnly);
  if (
    (itemsToDisplay == null || itemsToDisplay.length < 1) &&
    !Array.isArray(buttons) &&
    !Array.isArray(formItems)
  )
    return null;
  return (
    <section {...rest}>
      {header ? <SectionHeader {...header} /> : null}
      <SectionContainer
        items={itemsToDisplay}
        formItems={formItems}
        buttons={buttons}
        direction={direction}
      />
    </section>
  );
};

export default Section;
