import { useParams } from "react-router-dom";
import useFeature from "./useFeature";

const useFeatureBlock = () => {
  const { featureId } = useParams();
  const feature = useFeature({ id: featureId });

  const items = [
    { id: "name", value: feature?.data?.name, formOnly: true, required: true },
    {
      id: "description",
      value: feature?.data?.description,
      type: "textarea",
      rows: "triple",
      required: true,
    },
  ];

  return items;
};

export default useFeatureBlock;
