import { forwardRef } from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TextFilter from "./TextFilter";
import styles from "./table.module.css";

const Table = forwardRef(({ config, data, textFilter, ...rest }, ref) => {
  if (!Array.isArray(data)) return null;
  if (!Array.isArray(config)) return null;

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      {textFilter && <TextFilter {...textFilter} />}
      <table ref={ref} className={styles.table} {...rest}>
        <TableHeader config={config} />
        <TableBody config={config} data={data} />
      </table>
    </div>
  );
});

export default Table;
