import { useParams } from "react-router-dom";
import useFeature from "./useFeature";
import useLinkedConclusions from "./useLinkedConclusions";

const useLinkedConclusionsBlock = () => {
  const { featureId } = useParams();
  const feature = useFeature({ id: featureId }, { skip: featureId == null });
  const { options } = useLinkedConclusions();

  const items = [
    {
      id: "name",
      data: feature?.data?.conclusions,
      value:
        Array.isArray(feature?.data?.conclusions) &&
        feature?.data?.conclusions.map((conclusion) => ({
          label: conclusion.name,
          id: conclusion.id,
        })),
      options,
      type: "list",
      idAsValue: true,
      link: (rowData) => `/conclusions/${rowData.id}`,
    },
  ];

  return items;
};

export default useLinkedConclusionsBlock;
