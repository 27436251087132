import classNames from "classnames";
import styles from "./chartSeries.module.css";

const ChartSeries = ({ points, selectedPoint, color }) => {
  return (
    <>
      <g
        className={classNames(
          styles.chartLine,
          styles[color],
          styles.highlight
        )}
      >
        {points?.map((point, idx) =>
          point.highlight &&
          points[idx + 1].highlight &&
          points[idx + 1] &&
          !point.outOfBounds &&
          !points[idx + 1]?.outOfBounds ? (
            <line
              key={`l-${point.x}-${point.y}-${idx}`}
              x1={point.x}
              x2={points[idx + 1].x}
              y1={point.y}
              y2={points[idx + 1].y}
            />
          ) : null
        )}
      </g>
      {points?.map((point, idx) =>
        /*
        point.visible &&
        points[idx + 1]?.visible &&
        */
        points[idx + 1] &&
        !point.outOfBounds &&
        !points[idx + 1]?.outOfBounds ? (
          <line
            key={`l-${point.x}-${point.y}-${idx}`}
            x1={point.x}
            x2={points[idx + 1].x}
            y1={point.y}
            y2={points[idx + 1].y}
            className={classNames(styles.chartLine, styles[color])}
          />
        ) : points[idx + 1] ? (
          <line
            key={`l-${point.x}-${point.y}-${idx}`}
            x1={point.x}
            x2={points[idx + 1].x}
            y1={point.y}
            y2={points[idx + 1].y}
            className={classNames(
              styles.chartLine,
              styles.dashed,
              styles[color]
            )}
          />
        ) : null
      )}
      {points?.map((point, idx) =>
        /*point.visible && */ !point.outOfBounds ? (
          <circle
            key={`c-${point.x}-${point.y}-${idx}`}
            cx={point.x}
            cy={point.y}
            r={idx === selectedPoint ? 7 : 3}
            className={classNames(styles.chartPoint, styles[color], {
              [styles.highlight]: point.highlight,
            })}
          />
        ) : /*point.visible && */ point.outOfBounds ? (
          <circle
            key={`c-${point.x}-${point.y}-${idx}`}
            cx={point.x}
            cy={point.y}
            r={idx === selectedPoint ? 7 : 5}
            className={classNames(
              styles.chartPoint,
              styles.outOfBounds,
              styles[color]
            )}
          />
        ) : null
      )}
    </>
  );
};

export default ChartSeries;
