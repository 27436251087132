import useAxisLabels from "./hooks/useAxisLabels";
import styles from "./axisLabel.module.css";

const AxisLabels = ({
  axisValues,
  axis,
  onTop,
  onBottom,
  onLeft,
  onRight,
  oddOnly,
  labelsOnRight,
  labelsOnLeft,
  labelsOnTop,
  labelsOnBottom,
  ...rest
}) => {
  const axisLabels = useAxisLabels({
    axisValues,
    onTop,
    onBottom,
    onLeft,
    onRight,
    oddOnly,
    labelsOnRight,
    labelsOnLeft,
    labelsOnTop,
    labelsOnBottom,
    ...rest,
  });

  return (
    <>
      {axisLabels.map((label) => (
        <div
          key={label.id}
          className={styles.axisLabel}
          style={{
            left: label.left,
            top: label.top,
            width: label?.width ? `${label?.width}px` : "unset",
            height: label?.height ? `${label?.height}px` : "unset",
          }}
        >
          {label.value}
        </div>
      ))}
    </>
  );
};

export default AxisLabels;
