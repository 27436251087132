import { apiSlice } from "../api/apiSlice";

export const profileSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => `my/profile`,
      providesTags: [
        { type: "Profile" },
      ],
    }),
  }),
});

export const { useGetProfileQuery } = profileSlice;