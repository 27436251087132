import { useParams } from "react-router-dom";
import useConclusion from "./useConclusion";
import useFeatures from "../../features/hooks/useFeatures";
import getOptions from "../../../utils/getOptions";
import getIds from "../../../utils/getIds";
import toRemove from "../../../utils/getItemsToRemove";
import toAdd from "../../../utils/getItemsToAdd";

const useLinkedFeatures = () => {
  const { conclusionId } = useParams();
  const conclusion = useConclusion(
    { id: conclusionId },
    { skip: conclusionId == null }
  );
  const features = useFeatures();

  const options = getOptions(features?.data, "name");
  const ids = getIds(conclusion?.data?.features);
  const getItemsToAdd = (newList) => toAdd(ids, newList);
  const getItemsToRemove = (newList) => toRemove(ids, newList);

  return {
    features: features?.data,
    options,
    getItemsToAdd,
    getItemsToRemove,
  };
};

export default useLinkedFeatures;
