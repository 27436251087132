import { useGetStaffQuery } from "../staffSlice";
import useOrganisationId from "../../organisations/hooks/useOrganisationId";
import useHasAccess from "../../../hooks/useHasAccess";

const useStaff = () => {
  const organisationId = useOrganisationId();
  const hasAccess = useHasAccess({
    apiCall: "organisations.id.users.GET",
    organisation: organisationId,
  });

  const staff = useGetStaffQuery(
    { id: organisationId },
    { skip: organisationId == null || !hasAccess }
  );

  return { ...staff, hasAccess };
};

export default useStaff;
