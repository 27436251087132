import { useState } from "react";

const useFormData = (inputsFromConfig) => {
  const [formData, setFormData] = useState();
  const inputs = inputsFromConfig.filter((input) => !input.pageOnly);

  let requiredInputs = [];
  if (inputs && Array.isArray(inputs)) {
    requiredInputs = inputs
      .filter((input) => {
        if (typeof input?.isValid === "function") {
          return true;
        }
        return input.required === true;
      })
      .map((input) => input.id);
  }

  const onChange = ({ id, value, remove }) => {
    setFormData((state) => {
      let newState = state != null ? { ...state } : {};
      if (remove) {
        const keysToRemove = Object.keys(state).filter((key) =>
          key.includes(id)
        );
        keysToRemove.map((keyToRemove) => {
          delete newState[keyToRemove];
          return false;
        });
        return newState;
      }
      newState[id] = value;
      return newState;
    });
  };

  let missingInputs = [];
  if (formData != null && Array.isArray(requiredInputs)) {
    missingInputs = requiredInputs.filter((inputId) => {
      const inputFromConfig = inputsFromConfig.find(
        (input) => input.id === inputId
      );
      let invalid = false;
      if (typeof inputFromConfig?.isValid === "function") {
        invalid = !inputFromConfig?.isValid(formData[inputId]);
      }
      if (inputFromConfig.required) {
        return (
          invalid ||
          formData[inputId] == null ||
          formData[inputId] === "" ||
          (Array.isArray(formData[inputId]) && formData[inputId].length < 1)
        );
      }
      return invalid;
    });
  }

  return { formData, onChange, missingInput: missingInputs[0] };
};

export default useFormData;
