import Button from "../../components/Button";
import P from "../../components/Paragraph";

const SelectivityInput = (props) => {
  let content;
  const showOKinTheCentre =
    props?.spectrum.left?.blue_slashes == null &&
    props?.spectrum.right?.blue_slashes == null;
  if (props?.side !== "both") {
    content =
      props?.spectrum[props?.side]?.blue_slashes == null &&
      props?.value !== "NT" &&
      !showOKinTheCentre ? (
        <P style={{ marginTop: "5px" }}>OK</P>
      ) : null;
  } else {
    content = (
      <>
        {showOKinTheCentre ? (
          <>
            <Button
              inline
              pressed={props?.value !== "NT"}
              caption={"OK"}
              onClick={(e) => {
                e.preventDefault();
                props?.handleClick({ side: props?.side, value: "OK" });
              }}
            />
            <Button
              inline
              pressed={props?.value === "NT"}
              caption={"NT"}
              onClick={(e) => {
                e.preventDefault();
                props?.handleClick({ side: props?.side, value: "NT" });
              }}
            />
          </>
        ) : null}
      </>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        width: "25%",
        justifyContent: "center",
      }}
    >
      {content}
    </div>
  );
};

export default SelectivityInput;
