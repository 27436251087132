import accessConfig from "../config/accessConfig";
import { useSelector } from "react-redux";
import { selectRoles } from "../features/auth/authSlice";

const USER_ROLES = [];

const hasRequiredRoles = (
  requiredRoles,
  userRoles = USER_ROLES,
  organisation,
) => {
  let result = false;
  if (requiredRoles == null) return true;
  if (Array.isArray(userRoles) && Array.isArray(requiredRoles)) {
    const rolesWithAccess = userRoles.filter((userRole) => {
      const [roleInOrganisation, usersOrganisation] = userRole.split("@");
      const hasRole = requiredRoles.indexOf(roleInOrganisation) > -1;
      if (usersOrganisation == null) {
        return hasRole;
      } else {
        return hasRole && organisation === usersOrganisation;
      }
    });
    if (rolesWithAccess.length > 0) result = true;
  }
  return result;
};

const hasAccessToLink = (link, userRoles = USER_ROLES) => {
  if (link.includes("://")) return true;
  if (link.startsWith("?")) return true;
  let result = false;
  const userOrganisations = [];
  userRoles.map((userRole) => {
    const userOrganisation = userRole.split("@")[1];
    if (userOrganisation != null) userOrganisations.push(userOrganisation);
    return false;
  });
  const splitLink = link.split("/");
  let organisation;
  const linkWithoutOrganisationId = splitLink
    .filter((linkItem) => {
      if (linkItem === "") return false;
      if (userOrganisations.indexOf(linkItem) < 0) return true;
      organisation = linkItem;
      return false;
    })
    .join("/");
  if (accessConfig.routes[linkWithoutOrganisationId] != null) {
    result = hasRequiredRoles(
      accessConfig.routes[linkWithoutOrganisationId],
      userRoles,
      organisation,
      link.includes("assessment")
    );
  } else {
    const linkPathArray = linkWithoutOrganisationId.split("/");
    if (link.split("/")[0] === "") {
      result = hasRequiredRoles(
        accessConfig.routes[linkPathArray[1]],
        userRoles,
        organisation,
      );
    }
  }
  return result;
};

const hasAccessToApi = (apiCall, userRoles, organisation) => {
  let result = false;
  if (accessConfig.apiCalls[apiCall] != null) {
    result = hasRequiredRoles(
      accessConfig.apiCalls[apiCall],
      userRoles,
      organisation
    );
  } else {
    console.error(apiCall, "not found in accessConfig");
    result = false;
  }
  return result;
};

const useHasAccess = ({ link, apiCall, organisation }) => {
  const roles = useSelector(selectRoles);
  if (roles == null) return null;
  if (link != null) return hasAccessToLink(link, roles);
  if (apiCall != null) return hasAccessToApi(apiCall, roles, organisation);
  return true;
};

const useHasAccessFunctions = () => {
  const roles = useSelector(selectRoles);
  return {
    hasAccessToLink: (link) => {
      return hasAccessToLink(link, roles);
    },
  };
};

export default useHasAccess;
export { useHasAccessFunctions };
